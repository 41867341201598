import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { AiFillQuestionCircle } from 'react-icons/ai'
import { Tooltip as ReactToolTip } from 'react-tooltip'
import { mapData } from '../../../../data/map'

export default function NodesHostingGraph({ chain, validatorsData }) {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        BarElement,
        Title,
        Tooltip,
        Legend,
        Filler
    );

    const [total, setTotal] = useState(0);

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                titleFont: {
                    size: 20,
                    family: 'Monaco'
                },
                bodyFont: {
                    size: 20,
                    family: 'Monaco'
                },
                callbacks: {
                    title: function () {
                        return ''
                    },
                    label: function (context) {
                        let label = context.label || '';
                        let value = context.dataset.data[context.dataIndex];
                        return label + ' ('+ ((value / total) * 100).toFixed(2) + '%): ' + value;
                    },
                },
            }
        },
        responsive: true,
        interaction: {
            intersect: false,
        },
        scales: {
            x: {
                display: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'Monaco'
                    }
                }
            },
            y: {
                display: true,
                ticks: {
                    precision: 0,
                    font: {
                        size: 15,
                        family: 'Monaco'
                    }
                }
            }
        }
    };

    const [data, setData] = useState(null)

    useEffect(() => {
        if (!mapData) {
            return;
        }

        var __array = []

        mapData.forEach(node => {
            if(node.isp) {
                node.isp = node.isp.split(' ')[0]
                if(!__array.length) {
                    __array.push([node.isp, 1]);
                }
                else {
                    var flag = false;
                    for(var x = 0; x < __array.length; x++) {
                        if(__array[x][0] === node.isp) {
                            __array[x][1] = __array[x][1] + 1;
                            flag = true;
                        }
                    }
                    if(!flag) {
                        __array.push([node.isp, 1]);
                    }
                }
            }
            else {
                if(!__array.length) {
                    __array.push(["Others", 1]);
                }
                else {
                    var flag2 = false;
                    for(var y = 0; y < __array.length; y++) {
                        if(__array[y][0] === "Others") {
                            __array[y][1] = __array[y][1] + 1;
                            flag2 = true;
                        }
                    }
                    if(!flag2) {
                        __array.push(["Others", 1]);
                    }
                }
            }
        })

        __array = __array.sort((a,b) => a[1] - b[1]).reverse()

        var total = 0;
        for (var j = 0; j < __array.length; j++) {
            total = total + __array[j][1];
        }
        setTotal(total);



        var __array2 = [];

        for(var i = 0; i < __array.length; i++) {
            if(i < 4) {
                __array2.push([__array[i][0] , __array[i][1]]);
            }
            else if(i === 4){
                __array2.push(["Others", __array[i][1]])
            }
            else {
                __array2[4][1] = __array2[4][1] + __array[i][1]
            }
        }

        let labels = __array2.map((res) => res[0]);
        let data = __array2.map((res) => res[1]);

        setData({
            labels: labels,
            datasets: [
                {
                    data: data,
                    backgroundColor: [
                        '#89D4F5', '#BCD759', '#FFBF00', '#9961A7',
                        '#4891EA', '#EE965B', '#F284D1', '#6FDBCB',
                        '#2D71C4', '#EF5A5A', '#609C29', '#C69B06',
                        '#8A2299', '#996D6C', '#2F2F6C', '#1C6C61',
                    ]
                }
            ]
        })

    }, [validatorsData])

    const styles = {
        wrapper: 'my-[2rem] bg-[#fff] p-4 rounded-md border-[1px] border-borderColor',
        container: 'w-[100%] h-[100%]',
        title: 'text-center text-[1.5rem] font-[500] text-[#353535] pb-4 flex gap-[1rem] justify-center place-items-center',
        icon: 'text-[1.5rem] text-[#353535] cursor-pointer',
    }

    return (
        <div className={data ? styles.wrapper : 'hidden'}>
            <div className={styles.container}>
                <h2 className={styles.title}>Nodes Per Hosting</h2>
                {data && <Bar options={options} data={data} />}
            </div>
        </div>
    )
}

