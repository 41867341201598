import React, { useEffect, useState } from 'react'
import Prism from "prismjs";
import "prismjs/plugins/toolbar/prism-toolbar.min.css";
import "prismjs/plugins/toolbar/prism-toolbar.min";
import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.min";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import axios from 'axios';
import { humanReadableTimeDifferenceString, isIP6Address } from '../../../../scripts/utils';

export default function Sync({ chain }) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const [snapshotData, setSnapshotData] = useState('')

    useEffect(() => {
        const id = chain.id;
        const salt = new Date().getTime();
        const snapshotStateFileLocation = `${chain?.snapshotServer}/${id}/current_state.json?${salt}`;
        axios.get(snapshotStateFileLocation).then((res) => {
            if (res.status === 200) {
                const data = res.data;
                const snapshotHeight = data.snapshotHeight;
                const snapshotSize = data.snapshotSize + 'B';
                const snapshotBlockTime = humanReadableTimeDifferenceString(data.snapshotBlockTime);
                setSnapshotData({
                    snapshotHeight: snapshotHeight,
                    snapshotSize: snapshotSize,
                    snapshotBlockTime: snapshotBlockTime,
                });
            }
        }).catch((err) => {});
    }, [])

    const getChainBinaryName = (chain) => {
        return chain.binaryName || chain.serviceName;
    }

    const getUnsafeResetAllString = (chain) => {
        if (chain.newWayUnsafeResetAll) {
            return (
                getChainBinaryName(chain) +
                ' tendermint unsafe-reset-all --home $HOME/' +
                chain.homeDirectoryName +
                ' --keep-addr-book'
            );
        }
        return getChainBinaryName(chain) + ' unsafe-reset-all';
    }

    const MAX_LIVE_PEERS = 100;

    const [livePeersArray, setLivePeersArray] = useState([])

    useEffect(() => {
        if (!chain.isArchive) {
            var livePeers = [];
            const updateLivePeersView = () => {
                if (!livePeers.length) {
                    return;
                }
                const livePeersString = livePeers.join(',');
                const peersRow = document.querySelector('#peers-row code');
                if (peersRow) {
                    peersRow.innerHTML = livePeersString;
                }
                const updatePeersRow = document.querySelector('#update-peers-row code');
                if (updatePeersRow) {
                    updatePeersRow.innerHTML = `peers="${livePeersString}"
sed -i 's|^persistent_peers *=.*|persistent_peers = "'$peers'"|' $HOME/${chain.homeDirectoryName}/config/config.toml`;
                }
            }

            axios.get(`${chain.rpcServer}/net_info`).then((res) => {
                if (res.status === 200) {
                    const data = res.data;
                    livePeers.push(chain?.rpcPeer || '');
                    let peersArray = data.result.peers;
                    for (let i = 0; i < peersArray.length && livePeers.length < MAX_LIVE_PEERS; i++) {
                        const peerId = peersArray[i].node_info.id;
                        const listenAddr = peersArray[i].node_info.listen_addr;
                        const listenPort = listenAddr.slice(listenAddr.lastIndexOf(':') + 1);
                        const remoteIp = peersArray[i].remote_ip;
                        if (isIP6Address(remoteIp)) {
                            livePeers.push(`${peerId}@[${remoteIp}]:${listenPort}`);
                        } else {
                            livePeers.push(`${peerId}@${remoteIp}:${listenPort}`);
                        }
                    }
                    updateLivePeersView();
                    setLivePeersArray(livePeers)
                }
            }).catch((err) => {});
        }
    }, [chain.isArchive, chain.rpcServer])

    const styles = {
        wrapper: '',
        container: '',
        title: 'text-[1.8rem] font-[600]',
        details: 'text-[1rem] text-[#353535] flex',
        section: 'mb-[2rem]',
        copyButton: '',
        monikerContainer: '',
        label: 'pt-[15px] pb-[10px] text-[1rem]',
        monikerInput: 'rounded-full outline-none px-[1rem] py-[0.6rem] w-[300px] bg-[#fff] border-[1px] border-borderColor hover:border-[#000] focus:border-[#000] duration-300 text-[1rem] pb-[1rem]'
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                {
                    !chain.snapshotDisabled ? (
                        <>
                            <div className={styles.section}>
                                <h2 className={styles.title}>Snapshot</h2>
                                <p className={styles.details}>height: <strong>{snapshotData?.snapshotHeight || '' || '2'} {snapshotData?.snapshotBlockTime ? ('(' + snapshotData?.snapshotBlockTime + ' ago)') : ''}</strong>; size: <strong>{snapshotData?.snapshotSize || ''}</strong>; pruning: <strong>custom/100/0/10</strong>; indexer: <strong>null</strong></p>
                                <SyntaxHighlighter language="bash" style={okaidia}>
                                    {`# install dependencies, if needed
sudo apt update
sudo apt install lz4 -y`}
                                </SyntaxHighlighter>
                            </div>
                            <div className={styles.section}>
                                <SyntaxHighlighter language="bash" style={okaidia}>
                                    {`sudo systemctl stop ${chain.serviceName}
${getUnsafeResetAllString(chain)}

cd $HOME/${chain.homeDirectoryName}
rm -rf data ${chain.hasWasm ? '\nrm -rf wasm' : ''}

SNAP_NAME=$(curl -s ${chain.snapshotServer}/${chain.id}/ | egrep -o ">${chain.chainId}.*\\.tar.lz4" | tr -d ">")
curl ${chain.snapshotServer}/${chain.id}/${'${'}SNAP_NAME${'}'} | lz4 -dc - | tar -xf -

sudo systemctl restart ${chain.serviceName}
sudo journalctl -u ${chain.serviceName} -f --no-hostname -o cat`}
                                </SyntaxHighlighter>
                            </div>
                        </>
                    ) : <></>
                }
                {
                    !chain.stateSyncDisabled ? (
                        <>
                            <div className={styles.section}>
                                <h2 className={styles.title}>Snapshot</h2>
                                <SyntaxHighlighter language="bash" style={okaidia}>
                                    {`sudo systemctl stop ${chain.serviceName}
${getUnsafeResetAllString(chain)}

SNAP_RPC="${chain.rpcServer}:443"

LATEST_HEIGHT=$(curl -s $SNAP_RPC/block | jq -r .result.block.header.height); \\
BLOCK_HEIGHT=$((LATEST_HEIGHT - 2000)); \\
TRUST_HASH=$(curl -s "$SNAP_RPC/block?height=$BLOCK_HEIGHT" | jq -r .result.block_id.hash)

echo $LATEST_HEIGHT $BLOCK_HEIGHT $TRUST_HASH

peers="${chain.rpcPeer}"
sed -i 's|^persistent_peers *=.*|persistent_peers = "'$peers'"|' $HOME/${chain.homeDirectoryName}/config/config.toml

sed -i -E "s|^(enable[[:space:]]+=[[:space:]]+).*$|\\1true| ; \\
s|^(rpc_servers[[:space:]]+=[[:space:]]+).*$|\\1\\"$SNAP_RPC,$SNAP_RPC\\"| ; \\
s|^(trust_height[[:space:]]+=[[:space:]]+).*$|\\1$BLOCK_HEIGHT| ; \\
s|^(trust_hash[[:space:]]+=[[:space:]]+).*$|\\1\\"$TRUST_HASH\\"|" $HOME/${chain.homeDirectoryName}/config/config.toml

sudo systemctl restart ${chain.serviceName}
sudo journalctl -u ${chain.serviceName} -f --no-hostname -o cat${chain.stateSyncExtraStep || ''}`}
                                </SyntaxHighlighter>
                            </div>
                        </>
                    ) : <></>
                }
                <div className={styles.section}>
                    <h2 className={styles.title}>Live Peers</h2>
                    <p className={styles.details}>number of active peers:<strong>{livePeersArray.length || 1}</strong></p>
                    <div className={styles.section}>
                        <SyntaxHighlighter language="bash" style={okaidia} id="peers-row">
                            {chain.rpcPeer}
                        </SyntaxHighlighter>
                    </div>
                    <SyntaxHighlighter language="bash" style={okaidia} id="update-peers-row">
                        {`peers="${chain.rpcPeer}"
sed -i 's|^persistent_peers *=.*|persistent_peers = "'$peers'"|' $HOME/${chain.homeDirectoryName}/config/config.toml`}
                    </SyntaxHighlighter>
                </div>
                <div className={styles.section}>
                    <h2 className={styles.title}>Address Book</h2>
                    <SyntaxHighlighter language="bash" style={okaidia}>
                        {`curl -s ${chain.snapshotServer}/${chain.id}/addrbook.json > $HOME/${chain.homeDirectoryName}/config/addrbook.json`}
                    </SyntaxHighlighter>
                </div>
            </div>
        </div>
    )
}