import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { compactNumber } from '../../../../scripts/utils';
import { AiFillQuestionCircle } from 'react-icons/ai'
import { Tooltip as ReactToolTip } from 'react-tooltip'

export default function CommissionChart({ validatorsData }) {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        BarElement,
        Title,
        Tooltip,
        Legend,
        Filler
    );

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                titleFont: {
                    size: 20,
                    family: 'Monaco'
                },
                bodyFont: {
                    size: 20,
                    family: 'Monaco'
                },
                callbacks: {
                    title: function () {
                        return ''
                    },
                    label: function (context) {
                        let label = context.label || '';
                        let value = context.dataset.data[context.dataIndex];
                        return label + ': ' + value;
                    },
                },
            }
        },
        responsive: true,
        interaction: {
            intersect: false,
        },
        scales: {
            x: {
                display: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'Monaco'
                    }
                }
            },
            y: {
                display: true,
                ticks: {
                    precision: 0,
                    font: {
                        size: 15,
                        family: 'Monaco'
                    }
                }
            }
        }
    };

    const [data, setData] = useState(null)

    useEffect(() => {
        if (!validatorsData.validators) {
            return;
        }

        const displayPercent = (val) => {
            return Intl.NumberFormat('en-US', {
                maximumFractionDigits: 2,
                style: 'percent'
            }).format(val);
        }

        let commissionDistribution = {};
        validatorsData.validators.forEach((validator) => {
            if (!commissionDistribution[validator.commission.commission_rates.rate]) {
                commissionDistribution[validator.commission.commission_rates.rate] = 0;
            }
            commissionDistribution[validator.commission.commission_rates.rate]++;
        });

        let sortableArray = [];

        for (let commission in commissionDistribution) {
            sortableArray.push([commission, commissionDistribution[commission]]);
        }

        sortableArray = sortableArray.sort((a, b) => b[1] - a[1]);
        sortableArray = sortableArray.slice(0, 5);

        let labels = sortableArray.map((res) => displayPercent(res[0]));
        let data = sortableArray.map((res) => res[1]);

        setData({
            labels: labels,
            datasets: [
                {
                    data: data,
                    backgroundColor: [
                        '#89D4F5', '#BCD759', '#FFBF00', '#9961A7',
                        '#4891EA', '#EE965B', '#F284D1', '#6FDBCB',
                        '#2D71C4', '#EF5A5A', '#609C29', '#C69B06',
                        '#8A2299', '#996D6C', '#2F2F6C', '#1C6C61',
                    ]
                }
            ]
        })

    }, [validatorsData])

    const styles = {
        wrapper: 'my-[2rem] bg-[#fff] p-4 rounded-md border-[1px] border-borderColor',
        container: 'w-[100%] h-[100%]',
        title: 'text-center text-[1.5rem] font-[500] text-[#353535] pb-4 flex gap-[1rem] justify-center place-items-center',
        icon: 'text-[1.5rem] text-[#353535] cursor-pointer',
    }

    return (
        <div className={data ? styles.wrapper : 'hidden'}>
            <div className={styles.container}>
                <h2 className={styles.title}>
                    Commission Distribution (top 5)
                    <AiFillQuestionCircle
                        className={styles.icon}
                        id="commissionDistribution"
                        data-tooltip-content="This chart shows top 5 the most frequently used commission rates among active validators."
                    />
                </h2>
                {data && <Bar options={options} data={data} />}
            </div>
            <ReactToolTip anchorId="commissionDistribution" />
        </div>
    )
}

