import React from 'react'
import { AiOutlineSwapRight } from 'react-icons/ai'
import { Link } from 'react-router-dom'

export default function Tiles() {

    const tilesData = [
        {
            name: 'Nym',
            percentage: '3.00',
            features: [
                {
                    data: 'Pioneer in Privacy',
                },
                {
                    data: 'Team with Proven Track Record',
                },
                {
                    data: '300 Million Ecosystem Grants',
                },
                {
                    data: 'Available in All Leading Exchanges',
                },
            ],
            links: [
                {
                    title: 'India Node-Delegate NYM(23.45% Saturation)',
                    ref: 'https://mixnet.explorers.guru/mixnode/GtMmB3fbjXfwGLdJiLXLD9TDp6sGAMtjtorV5kPuk57m'
                },
                {
                    title: 'NKB Blocks Node 1-(100% Saturated)- ',
                    ref: 'https://mixnet.explorers.guru/mixnode/2Uf4EENWbp98DAfEsCfRjcGB6jCa3ZaZkm1AkN8j8ocx'
                },
               
                {
                    title: 'Performance Dashboard',
                    ref: 'https://nym.nkbblocks.com/d/l71MWkX7k/nkb-blocks-mixnode?orgId=1'
                },
            ],
        },
        {
            name: 'QuickSilver',
            percentage: '5.00',
            features: [
                {
                    data: 'Cosmos Liquid Staking Zone',
                },
                {
                    data: 'Limitless Number of Zones',
                },
                {
                    data: 'Controlled by Community',
                },
                {
                    data: 'Permissionless',
                },
            ],
            links: [
                {
                    title: 'Explorer',
                    ref: 'https://quicksilver.explorers.guru/validator/quickvaloper1z0zq456ecuqq8qvkp4uysnu9tqdje9yt7f0rms'
                },
            ],
        },
        {
            name: 'Forta',
            percentage: '10.00',
            features: [
                {
                    data: 'Earliest Forta Node Runner',
                },
                {
                    data: 'Running Own Ethereum Node for Best Performance',
                },
                {
                    data: '24/7 Security of your Assets',
                },
                {
                    data: 'Join Telegram Group for Queries'
                }
            ],
            links: [
                {
                    title: 'Stake With Us',
                    ref: 'https://app.forta.network/nodePool/23/'
                },
                {
                    title: 'Telegram Group',
                    ref: 'https://t.me/+R4Dgp7RCtmA2ZTA1'
                }
            ],
        },
    ]

    const styles = {
        wrapper: 'py-[2rem] mt-[4rem]',
        container: 'flex justify-center place-items-center flex-col',
        titleContainer: 'text-center flex flex-col justify-center place-items-center gap-[1rem]',
        title: 'text-[2rem] font-[500] text-[#333]',
        subtitle: 'px-[1rem] text-[#888]',
        tilesContainer: 'pt-[2rem] grid grid-cols-1 lg:grid-cols-3 px-[2rem] md:px-[4rem] gap-[2rem]',
        tile: 'min-w-[80vw] lg:min-w-[25vw] duration-300 shadow-md text-center border-[1px] border-borderColor2 rounded-xl bg-[#fff] hover:shadow-xl',
        tileHeader: 'p-4 my-[1rem] flex flex-col justify-center place-items-center gap-[0.8rem]',
        tileTitle: 'text-[1.5rem] uppercase',
        tileSubtitle: 'text-[2.5rem]',
        tileBody: 'flex flex-col justify-center place-items-start',
        featureContainer: 'flex flex-col p-4 text-start gap-[1rem]',
        feature: 'text-[1rem] flex place-items-center gap-[1rem]',
        featureIcon: 'text-[#3956de]',
        linksContainer: 'flex flex-col w-full justify-center place-items-center gap-[2rem] my-[2rem] pb-[2rem] text-center',
        link: 'primaryButton duration-300 cursor-pointer rounded-xl justify-center place-items-center text-[1rem] uppercase border-[1px] border-[#000] bg-[rgba(0,0,0,0.1)] text-[#000] hover:bg-[#333] hover:text-[#fff] flex w-[250px] h-[50px] text-center hover:bg-[#222] shadow-md',
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.titleContainer}>
                    <h2 className={styles.title}>Project Commission</h2>
                    <p className={styles.subtitle}>Charging lowest commission thus giving you maximum APR when you delegate your Crypto with us. </p>
                </div>
                <div className={styles.tilesContainer}>
                    {
                        tilesData.map((item, index) => {
                            return (
                                <div className={styles.tile} key={index}>
                                    <div className={styles.tileHeader}>
                                        <h3 className={styles.tileTitle}>{item.name}</h3>
                                        <p className={styles.tileSubtitle}>{item.percentage}%</p>
                                    </div>
                                    <div className={styles.tileBody}>
                                        <div className={styles.featureContainer}>
                                            {
                                                item.features.map((feature, i) => {
                                                    return (
                                                        <p className={styles.feature} key={i}>
                                                        <AiOutlineSwapRight className={styles.featureIcon}/>
                                                            {feature.data}
                                                        </p>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className={styles.linksContainer}>
                                            {
                                                item.links.map((link, i) => {
                                                    return (
                                                        <a href={link.ref} className={styles.link} key={i} target='_blank' rel='noreferrer'>{link.title}</a>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
