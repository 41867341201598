import React, { useEffect, useState } from 'react'
import About from '../../components/chains/sections/about/About';
import CheatSheet from '../../components/chains/sections/cheatsheet/CheatSheet';
import Install from '../../components/chains/sections/install/Install';
import News from '../../components/chains/sections/news/News';
import PublicApi from '../../components/chains/sections/publicapi/PublicApi';
import Summary from '../../components/chains/sections/summary/Summary';
import Sync from '../../components/chains/sections/sync/Sync';
import Upgrade from '../../components/chains/sections/upgrade/Upgrade';

function SectionHandler({ chainName, sectionName, chain }) {

    const [sectionActive, setSectionActive] = useState(null)

    useEffect(() => {
        if(!chainName) {
            return;
        }
        const renderSection = () => {
            switch (sectionName) {
                case 'summary':
                    return <Summary chain={chain}/>
                case 'install':
                    return <Install chain={chain}/>
                case 'upgrade':
                    return <Upgrade chain={chain}/>
                case 'sync':
                    return <Sync chain={chain}/>
                case 'public-api':
                    return <PublicApi chain={chain}/>
                case 'cheat-sheet':
                    return <CheatSheet chain={chain}/>
                case 'news':
                    return <News chain={chain}/>
                case 'about':
                    return <About chain={chain}/>
                default:
                    return <Summary chain={chain}/>
            }
        }
        setSectionActive(renderSection())
    }, [chain, sectionName])

    const styles = {
        wrapper: 'min-w-[50vw]',
        container: '',
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                {sectionActive}
            </div>
        </div>
    )
}

export default SectionHandler