import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { humanReadableTimeDifferenceSeconds, secondsToHumanReadableFormat } from '../../scripts/utils'
import { Tooltip } from 'react-tooltip'
import { useNavigate } from 'react-router-dom'
import { MdArrowRight } from 'react-icons/md'
import {IoMdArrowDropdownCircle, IoMdArrowDropupCircle} from 'react-icons/io'

function ChainSidebar({ chainName, sectionName, rpcServer, logo, chainId, archiveReason, isArchive, summaryDisabled }) {


    const chainSidebarList = [
        {
            name: 'Summary',
            ref: 'summary',
        },
        {
            name: 'Install',
            ref: 'install',
        },
        {
            name: 'Upgrade',
            ref: 'upgrade',
        },
        {
            name: 'Sync',
            ref: 'sync',
        },
        {
            name: 'Public API',
            ref: 'public-api',
        },
        {
            name: 'Cheat Sheet',
            ref: 'cheat-sheet',
        },
        {
            name: 'News',
            ref: 'news',
        },
        {
            name: 'About',
            ref: 'about',
        },
    ]

    const [chainStatus, setChainStatus] = useState('');
    const [chainStatusMessage, setChainStatusMessage] = useState('');

    useEffect(() => {
        // get status of the displayed chain
        axios.get(`${rpcServer}/status`).then((res) => {
            if (res.status === 200) {
                const data = res.data;
                const latestBlockHeight = data.result.sync_info.latest_block_height;
                const latestBlockTime = data.result.sync_info.latest_block_time;
                const timeDifferenceInSeconds = humanReadableTimeDifferenceSeconds(latestBlockTime);
                if (timeDifferenceInSeconds > 600) {
                    setChainStatus("halted");
                    setChainStatusMessage(`Chain is halted ${secondsToHumanReadableFormat(timeDifferenceInSeconds)} ago, latest block height: ${latestBlockHeight}`)
                    return;
                }
                setChainStatus("synced")
                setChainStatusMessage(summaryDisabled ? `Chain is up and running, latest block height: ${latestBlockHeight}` : 'Chain is up and running')
            }
            else {
                setChainStatus("inactive");
                setChainStatusMessage('Our RPC node is down, apologies for inconvenience.');
            }
        }).catch((err) => { })
    }, [rpcServer])

    const navigate = useNavigate();

    const handleListClick = (e) => {
        navigate(`?${e}`)
    }

    const styles = {
        wrapper: 'max-w-[250px] min-w-[250px] md:block shadow-md bg-[#fff] border-[1px] border-borderColor rounded-xl absolute md:fixed md:left-[2rem] top-[4rem] md:top-[6rem] z-[100] right-[-25rem] left-[initial] md:right-[initial] duration-300',
        container: '',
        top: 'flex flex-row gap-[0.5rem] md:gap-0 md:flex-col justify-center place-items-center p-4 relative',
        logo: 'w-[50px] h-[50px] md:w-[85px] md:h-[85px] md:mb-[1rem]',
        title: 'text-[1.5rem] font-[500] text-[#353535] pb-2',
        subtitle: 'hidden md:block text-[1rem] text-[#656565]',
        middle: '',
        middleContainer: 'text-start pb-4',
        listItem: 'duration-300 p-2 px-[1rem] cursor-pointer hover:bg-[rgba(0,0,0,0.09)]',
        activeItem: 'duration-300 bg-[#3956de] text-[#fff] p-2 px-[1rem] cursor-pointer',
        listItemText: 'flex justify-between place-items-center',
        listIcon: 'text-[1.5rem]',
    }

    return (
        <>
            <div className={styles.wrapper} id='chainExtra'>
                <div className={styles.container}>
                    <div className={styles.top}>
                        <div className={styles.topIcon}>
                            <img className={styles.logo} alt={`${chainName}`} src={`/images/chain/${logo}`} />
                        </div>
                        <div className={styles.titleContainer}>
                            <h2 className={styles.title}>{chainName}</h2>
                        </div>
                        <div className={styles.subtitleContainer}>
                            <h5 className={styles.subtitle}>{chainId}</h5>
                        </div>
                        <div id='chainTooltip' className={`shadow-pulse-circle ${chainStatus} text-[rgba(0,0,0,0)]`} data-tooltip-content={isArchive ? archiveReason.toString() : chainStatusMessage.toString()} />
                        <Tooltip anchorId="chainTooltip" />
                    </div>
                    <div className={styles.middle}>
                        <div className={styles.middleContainer}>
                            {
                                chainSidebarList.map((item, index) => {
                                    if (!sectionName || sectionName === "") {
                                        sectionName = "summary"
                                    }
                                    return (
                                        <div onClick={() => handleListClick(item.ref)} className={sectionName === item.ref ? styles.activeItem : styles.listItem} key={index} id="sidebarItemLinks">
                                            <p className={styles.listItemText}>{item.name} <MdArrowRight className={styles.listIcon} /></p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChainSidebar