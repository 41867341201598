import React from "react";

export default function Footer() {
  const styles = {
    wrapper: "",
    container:
      "py-[1rem] text-center flex justify-center place-items-center gap-[1rem]",
    ref: "border-b-[1px] border-[blue] hover:border-[#14213D]",
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <p>&copy; 2022 NKB Blocks</p>
        {/* <p>-</p>
                <p> Friends of <a className={styles.ref} href='https://www.badgerbite.info/' rel='noreferrer' target='_blank'>Badgerbite DAO</a></p> */}
      </div>
    </div>
  );
}
