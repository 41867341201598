import React, { useEffect, useState } from 'react'
import Prism from "prismjs";
import "prismjs/plugins/toolbar/prism-toolbar.min.css";
import "prismjs/plugins/toolbar/prism-toolbar.min";
import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.min";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import axios from 'axios';

function Install({ chain }) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const styles = {
        wrapper: '',
        container: '',
        title: 'text-[1.8rem] font-[600]',
        details: 'text-[1rem] text-[#353535]',
        section: 'mb-[2rem]',
        copyButton: '',
        monikerContainer: '',
        label: 'pt-[15px] pb-[10px] text-[1rem]',
        monikerInput: 'rounded-full outline-none px-[1rem] py-[0.6rem] w-[300px] bg-[#fff] border-[1px] border-borderColor hover:border-[#000] focus:border-[#000] duration-300 text-[1rem] mb-[1rem]'
    }

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const [monikerValue, setMonikerValue] = useState('');

    const [manualScript, setManualScript] = useState('');

    const chainName = chain.id.toLowerCase();
    const chainId = chain.chainId;
    const Directory = chain.homeDirectoryName;
    const binaryName = chain.binaryName || chain.serviceName;
    const Denom = chain.denomName;
    const snapRPC = chain.statesyncServer;
    const Github = chain.github;
    const GithubRepo = chain.githubRepoName;
    const version = chain.version;
    const Seeds = chain.seeds;
    const Peers = chain.peers;
    const Genesis = chain.genesis;
    const Make = chain.make;

    const [automaticScript, setAutomaticScript] = useState(`https://raw.githubusercontent.com/BadgerbiteInfo/scripts/main/${chainName}/manual.sh` || '')

    useEffect(() => {
        axios.get(automaticScript).then((res) => {
            if (res.status === 200) {
                const data = res.data;
                const trimmedAutomationScriptContent = data
                    .split("# Manual Installation Script")[1]
                    .split("printLine")[0]
                    .split("\n")
                    .filter(line => !line.includes("print"))
                    .filter(line => !line.includes("bash"))
                    .join('\n')
                    .replace(new RegExp('<BINARY>', 'g'), binaryName)
                    .replace(new RegExp('<DIRECTORY>', 'g'), "~/" + Directory)
                    .replace(new RegExp('<CHAINID>', 'g'), chainId)
                    .replace(new RegExp('<DENOM>', 'g'), Denom)
                    .replace(new RegExp('<SNAP_RPC>', 'g'), snapRPC)
                    .replace(new RegExp('<GITHUB>', 'g'), Github)
                    .replace(new RegExp('<GITHUB REPO>', 'g'), GithubRepo)
                    .replace(new RegExp('<VERSION>', 'g'), version)
                    .replace(new RegExp('<SEEDS>', 'g'), Seeds)
                    .replace(new RegExp('<PEERS>', 'g'), Peers)
                    .replace(new RegExp('<GENESIS>', 'g'), Genesis)
                    .replace(new RegExp('SEEDS=""', 'g'), "")
                    .replace(new RegExp('PEERS=""', 'g'), "")
                    .replace(new RegExp('<MAKE>', 'g'), Make)
                    .trim()
                    .replace("\n\n\n", "\n\n")
                    .replace("\n\n\n", "\n\n");

                setManualScript("#!/bin/bash\n\n"
                    + trimmedAutomationScriptContent
                    + `\n\nsudo journalctl -u ${binaryName} -f --no-hostname -o cat`);
            }
        }).catch((err) => {
        })

    }, [automaticScript])

    const [testnetInstructionsContent, setTestnetInstructionsContent] = useState('');

    useEffect(() => {
        if (chain.isTestnet) {
            const testnetInstructionsUrl = `https://raw.githubusercontent.com/BadgerbiteInfo/scripts/main/${chainName}/testnet-instructions.sh`
            axios.get(testnetInstructionsUrl).then((res) => {
                if(res.status === 200) {
                    const data = res.data;
                    setTestnetInstructionsContent(data?.trim() || 'TBD')
                }
            }).catch((err) => {});
        }
    }, [chain.isTestnet, chainName])

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                {
                    chain.hardwareRequirements ? (
                        <>
                            <div className={styles.section}>
                                <h2 className={styles.title}>Hardware Requirements</h2>
                                <SyntaxHighlighter language="bash" style={okaidia}>
                                    {chain.hardwareRequirements}
                                </SyntaxHighlighter>
                            </div>
                        </>
                    ) : <></>
                }
                {
                    chain.testnetTasksLink ? (
                        <>
                            <div className={styles.section}>
                                <h2 className={styles.title}>Tasks</h2>
                                <SyntaxHighlighter language="bash" style={okaidia}>
                                    {chain?.testnetTasksLink}
                                </SyntaxHighlighter>
                            </div>
                        </>
                    ) : <></>
                }
                <div className={styles.section}>
                    <h2 className={styles.title}>Automatic Installation</h2>
                    <p className={styles.details}>pruning: <strong>custom/100/0/10</strong>; indexer: <strong>kv</strong></p>
                    <SyntaxHighlighter language="bash" style={okaidia}>
                        {`source <(curl -s ${automaticScript})`}
                    </SyntaxHighlighter>
                </div>
                <div className={styles.section}>
                    <h2 className={styles.title}>Manual Installation</h2>
                    <div className={styles.monikerContainer}>
                        <p className={styles.label}>Moniker</p>
                        <input className={styles.monikerInput} value={monikerValue} onChange={(e) => { setMonikerValue(e.target.value) }} placeholder="ex. NKB Blocks" />
                        <pre className="language-bash customPre">
                            <code className="language-bash">
                                {monikerValue ? `NODE_MONIKER="` + monikerValue + `"` : 'NODE_MONIKER="<YOUR_MONIKER>"'}
                            </code>
                        </pre>
                    </div>
                </div>
                <div className={styles.section}>
                    <SyntaxHighlighter language="bash" style={okaidia}>
                        {`# install dependencies, if needed
sudo apt update
sudo apt install -y curl git jq lz4 build-essential

if [ ! -f "/usr/local/go/bin/go" ]; then
    bash <(curl -s "https://raw.githubusercontent.com/BadgerbiteInfo/scripts/main/utils/go_install.sh")
    source .bash_profile
fi`}
                    </SyntaxHighlighter>
                </div>
                {
                    manualScript ? (
                        <div className={styles.section}>
                            <SyntaxHighlighter language="bash" style={okaidia}>
                                {manualScript}
                            </SyntaxHighlighter>
                        </div>
                    ) : <></>
                }
                {
                    testnetInstructionsContent ? (
                        <div className={styles.section}>
                            <h2 className={styles.title}>Create Validator</h2>
                            <SyntaxHighlighter language="bash" style={okaidia}>
                                {testnetInstructionsContent}
                            </SyntaxHighlighter>
                        </div>
                    ) : <></>
                }
            </div>
        </div>
    )
}
export default Install