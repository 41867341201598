import React, { useEffect, useState } from 'react'
import { mapData } from '../../../../data/map'
import ReactPaginate from 'react-paginate';

export default function NodesHostingGraph({ chain, validatorsData }) {

    const [total, setTotal] = useState(0);

    const [data, setData] = useState(null)

    useEffect(() => {
        if (!mapData) {
            return;
        }

        var __array = []

        mapData.forEach(node => {
            if (node.isp) {
                
                if (!__array.length) {
                    __array.push([node.isp, 1]);
                }
                else {
                    var flag = false;
                    for (var x = 0; x < __array.length; x++) {
                        if (__array[x][0] === node.isp) {
                            __array[x][1] = __array[x][1] + 1;
                            flag = true;
                        }
                    }
                    if (!flag) {
                        __array.push([node.isp, 1]);
                    }
                }
            }
            else {
                if (!__array.length) {
                    __array.push(["Others", 1]);
                }
                else {
                    var flag2 = false;
                    for (var y = 0; y < __array.length; y++) {
                        if (__array[y][0] === "Others") {
                            __array[y][1] = __array[y][1] + 1;
                            flag2 = true;
                        }
                    }
                    if (!flag2) {
                        __array.push(["Others", 1]);
                    }
                }
            }
        })

        __array = __array.sort((a, b) => a[1] - b[1]).reverse()

        var total = 0;
        for (var j = 0; j < __array.length; j++) {
            total = total + __array[j][1];
        }
        setTotal(total);

        setData(__array)

    }, [validatorsData])

    const styles = {
        wrapper: 'my-[2rem] bg-[#fff] p-4 rounded-md border-[1px] border-borderColor',
        container: 'w-[100%] h-[100%]',
        title: 'text-center text-[1.5rem] font-[500] text-[#353535] pb-4 flex gap-[1rem] justify-center place-items-center',
        icon: 'text-[1.5rem] text-[#353535] cursor-pointer',
        boardContainer: 'flex flex-col',
        header: 'font-[700] text-center',
        content: 'grid grid-cols-2 border-b-[1px] border-b-borderColor',
        contentText: 'text-[1rem] p-4',
        body: 'flex flex-col'
    }

    return (
        <div className={data ? styles.wrapper : 'hidden'}>
            <div className={styles.container}>
                <h2 className={styles.title}>Nodes Per Hosting</h2>
                <div className={styles.boardContainer}>
                    <div className={styles.header}>
                        <div className={styles.content}>
                            <h2 className={styles.contentText}>Organization</h2>
                            <h2 className={styles.contentText}>Number of Nodes</h2>
                        </div>
                    </div>
                    <div className={styles.body}>
                        <div className='w-full pagination'>
                            {data && <Pagination items={data} total={total} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const Pagination = ({ items, total }) => {
    function Items({ currentItems }) {
        return (
            <>
                {currentItems &&
                    currentItems.map((item) => (
                        <div className='grid grid-cols-2 place-items-center w-full gap-[1rem] border-b-[1px] border-b-borderColor text-center '>
                            <h3 className='p-4'>{item[0]}</h3>
                            <h3 className='p-4'>{item[1]} ({((item[1] / total) * 100).toFixed(2)}%)</h3>
                        </div>
                    ))}
            </>
        );
    }

    function PaginatedItems({ itemsPerPage }) {
        const [itemOffset, setItemOffset] = useState(0);
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = items.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(items.length / itemsPerPage);
        const handlePageClick = (event) => {
            const newOffset = (event.selected * itemsPerPage) % items.length;
            setItemOffset(newOffset);
        };

        return (
            <>
                <Items currentItems={currentItems} />
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                />
            </>
        );
    }

    return (
        <PaginatedItems itemsPerPage={5} />
    )
}

