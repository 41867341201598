import axios from 'axios'
import React, { useEffect, useState } from 'react'

function About({ chain }) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const [coinId, setCoinId] = useState(chain.coingekoCoinId || chain.id)

    const [aboutData, setAboutData] = useState(chain.projectOverview);

    useEffect(() => {
        axios.get(`https://api.coingecko.com/api/v3/coins/${coinId}`).then((res) => {
            if(res.status === 200) {
                setAboutData(res.data?.description?.en);
            }
        }).catch((err) => {})
    }, [coinId])

    const styles = {
        wrapper: '',
        container: '',
        title: 'text-[1.8rem] font-[600]',
        details: 'text-[1rem] text-[#353535] leading-8',
        contentContainer: 'bg-[#fff] flex flex-col gap-[1.5rem] rounded-md p-4 my-4 border-[1px] border-borderColor shadow-md',
    }

    return (
        <div className={styles.wrapper}>
            {
                aboutData ? (
                    <>
                        <div className={styles.container}>
                            <h2 className={styles.title}>About</h2>
                            <div className={styles.contentContainer}>
                                <p className={styles.details}>
                                    {aboutData}
                                </p>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                    <p className={styles.details}>Details are not available!</p>
                    </>
                )
            }
        </div>
    )
}

export default About