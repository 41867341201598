import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { mapData } from '../../../../data/map'


mapboxgl.accessToken = 'pk.eyJ1IjoibmtiYmxvY2tzIiwiYSI6ImNsYzhoZXFqMzFncWMzb3Q4YTR4bHNtcW4ifQ.WVeY5ZBT04KV0nfR2lXVIA';


export default function DecentralizationMap({ chain }) {

    const styles = {
        wrapper: 'my-[2rem] bg-[#fff] p-4 rounded-md border-[1px] border-borderColor',
        container: 'w-[100%] h-[100%]',
        title: 'text-center text-[1.5rem] font-[500] text-[#353535] pb-4',
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <h2 className={styles.title}>Decentralization Map</h2>
                <Map />
            </div>
        </div>
    )
}

const Map = () => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-70.9);
    const [lat, setLat] = useState(42.35);
    const [zoom, setZoom] = useState(5);

    useEffect(() => {
        if (map.current) return; // initialize map only once        

        const __array = []

        mapData.forEach(node => {
            __array.push({
                moniker: node.moniker,
                id: node.id,
                ip: node.ip,
                provider: node.isp,
                country: node.countryName,
                coordinates: [node.longitude, node.latitude],
            })
        })

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/dark-v11',
            center: __array[Math.floor(__array.length / 2)].coordinates,
            zoom: zoom,
            cluster: true,
            clusterRadius: 80,
            projection: 'naturalEarth',
        });

		__array.forEach((location) => {
			var marker = new mapboxgl.Marker()
							.setLngLat(location.coordinates)
							.setPopup(new mapboxgl.Popup({ offset: 30 })
							.setHTML('<h4>Moniker : ' + location.moniker + '</h4><h4>ID : ' + location.id + '</h4><h4>IP : ' + location.ip + '</h4><h4>Provider : ' + location.provider + '</h4><h4>Country : ' + location.country + '</h4>'))
							.addTo(map.current);

		})
    });

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    });

    return (
        <div>
            <div ref={mapContainer} className="map-container" />
        </div>
    );
}