import axios from 'axios'
import React, { useEffect, useState } from 'react'

function News({ chain }) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const styles = {
        wrapper: '',
        container: '',
        title: 'text-[1.8rem] font-[600] mb-[1rem]',
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <h2 className={styles.title}>{chain.id} News</h2>
                <TwitterContainer twitterUrl={chain.twitter} name={chain.id} />
            </div>
        </div>
    )
}

const TwitterContainer = ({ twitterUrl, name }) => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        document.getElementsByClassName("twitter-embed")[0].appendChild(script);
    }, []);

    return (
        <section className="twitterContainer">
            <div className="twitter-embed">
                <a
                    className="twitter-timeline"
                    data-theme="dark"
                    data-tweet-limit="5"
                    data-chrome="noheader nofooter noborders"
                    href={twitterUrl}
                >
                    Loading Tweets by {name}
                </a>
            </div>
        </section>
    );
};

export default News