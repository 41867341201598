import React, { useEffect } from 'react'
import Footer from '../../components/common/Footer'
import AboutUs from '../../components/home/AboutUs'
import Banner from '../../components/home/Banner'
import SearchChain from '../../components/home/SearchChain'
import Tiles from '../../components/home/Tiles'

function Home() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className=''>
                <Banner />
                <AboutUs />
                <Tiles />
                <SearchChain />
            </div>
            <Footer />
        </>
    )
}

export default Home
