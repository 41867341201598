export const compactNumber = (num, maximumFractionDigits = 0) => {
    return Intl.NumberFormat('en-US', {
        maximumFractionDigits: maximumFractionDigits
    }).format(num);
}

export const humanReadableTimeDifferenceSeconds = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const now = new Date();
    return (now.getTime() - dateTime.getTime()) / 1000;
}

export const humanReadableTimeDifferenceString = (dateTimeString) => {
    const timeDifferenceInSeconds = humanReadableTimeDifferenceSeconds(dateTimeString);
    return secondsToHumanReadableFormat(timeDifferenceInSeconds);
}

export const secondsToHumanReadableFormat = (seconds) => {
    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
        return interval + " year" + (interval > 1 ? 's' : '');
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
        return interval + " month" + (interval > 1 ? 's' : '');
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
        return interval + " day" + (interval > 1 ? 's' : '');
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
        return interval + " hour" + (interval > 1 ? 's' : '');
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
        return interval + " minute" + (interval > 1 ? 's' : '');
    }
    return Math.floor(seconds) + " second" + (interval > 1 ? 's' : '');
}

export const isIP6Address = (ip) => {
    const match = ip.match(/:/g);
    return match && match.length > 1;
}