export const chainsData = [
  {
    id: "Uptick-mainnet",
    chainName: "uptick",
    chainId: "uptick_117-1",
    logo: "uptick.png",
    snapshotServer: "https://snapshots1-testnet.nodejumper.io/uptick-testnet/",
    SnapshotProvider: "NodeJumper",
    rpcServer: "https://uptick-testnet.nodejumper.io:443",
    statesyncServer: "https://uptick-testnet.nodejumper.io:443",
    restServer: "",
    rpcPeer:
      "9ffdc3cd450758f09e1c31f2548c812a5c86f141@uptick-testnet.nodejumper.io:29656",
    binaryName: "uptickd",
    serviceName: "uptickd",
    homeDirectoryName: ".uptickd",
    twitter: "https://twitter.com/uptickproject",
    github: "https://github.com/UptickNetwork",
    globe: "https://uptick.network/",
    discord: "https://discord.gg/5JENWnHEG5",
    blockExplorer: "https://uptick.explorers.guru/",
    validatorUrl:
      "https://uptick.explorers.guru/validator/uptickvaloper1crn8nymeuwuu2q2h7n84xvwvfgtv680gqglwaw",
    Valoper: "uptickvaloper1crn8nymeuwuu2q2h7n84xvwvfgtv680gqglwaw",
    denomName: "auptick",
    denomPow: 18,
    summaryDisabled: true,
    isTestnet: false,
    hardwareRequirements: "4CPU 8RAM 200GB",
    projectOverview:
      "The Business Grade Multi-Chain NFT Infrastructure for Web 3.0",
  },
  {
    id: "Quicksilver-Mainnet",
    chainName: "quicksilver",
    chainId: "quicksilver-2",
    logo: "quicksilver.png",
    snapshotServer: "https://snapshots.polkachu.com/snapshots/quicksilver/",
    SnapshotProvider: "Polkachu",
    rpcServer: "https://quicksilver-rpc.polkachu.com:443",
    statesyncServer: "https://quicksilver-rpc.polkachu.com:443",
    restServer: "",
    rpcPeer: "https://quicksilver-rpc.polkachu.com:443",
    binaryName: "quicksilverd",
    serviceName: "quicksilverd",
    homeDirectoryName: ".quicksilverd",
    twitter: "https://twitter.com/quicksilverzone/",
    github: "https://github.com/ingenuity-build/quicksilver/",
    globe: "https://quicksilver.zone/",
    discord: "https://discord.gg/quicksilverprotocol",
    blockExplorer: "https://uquicksilver.explorers.guru/",
    validatorUrl:
      "https://quicksilver.explorers.guru/validator/quickvaloper1z0zq456ecuqq8qvkp4uysnu9tqdje9yt7f0rms",
    Valoper: "quickvaloper1z0zq456ecuqq8qvkp4uysnu9tqdje9yt7f0rms",
    denomName: "uqck",
    denomPow: 6,
    summaryDisabled: false,
    isTestnet: false,
    hardwareRequirements: "4CPU 8RAM 200GB",
    projectOverview:
      "Quicksilver Protocol is a permissionless, sovereign Cosmos SDK blockchain that allows delegators to stake assets against any validator running on any IBC-enabled chain and receive in turn a voucher representing a claim against that staked position.",
  },
];
