import React, { useEffect, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { chainsData } from '../../data/data';
import ChainCard from './ChainCard';

export default function SearchChain() {

    const [searchValue, setSearchValue] = useState('');

    const styles = {
        wrapper: 'py-[2rem] min-h-[80vh] mt-[4rem]',
        container: 'flex flex-col justify-center place-items-center px-8',
        searchContainer: 'relative',
        inputSearch: 'rounded-full text-[#353535] outline-none px-[2rem] py-[0.8rem] shadow-md w-full bg-[#fff] border-[1px] border-borderColor hover:border-[#000] focus:border-[#000] duration-300 text-[1rem] mt-[0.5rem] pl-[3rem]',
        searchIcon: 'absolute text-[1.5rem] top-[1.45rem] left-[1rem] cursor-pointer',
        contentContainer: 'grid sm:grid-cols-2 lg:grid-cols-3 gap-[2rem] mt-[4rem]',
        content: 'bg-[#fff] shadow-md rounded-xl border-[1px] border-borderColor2 p-4 hover:shadow-xl duration-300 chainCard hover:border-[rgba(0,0,0,0.3)]',
        archiveContent: 'bg-[rgba(0,0,0,0.04)] rounded-xl border-[1px] border-borderColor2 duration-300 p-4 hover:shadow-xl chainCard hover:border-[rgba(0,0,0,0.3)]',
        inputFooter: 'text-[0.8rem] text-[#888] text-center pt-[1rem]',
    }

    const [allData, setAllData] = useState(chainsData);
    const [filterData, setFilterData] = useState('');

    useEffect(() => {
        setFilterData(allData);
    }, [allData])

    const handleSearch = (keyword) => {
        setSearchValue(keyword);
        var newData = allData.filter((e) => 
            e.chainName.toLowerCase().includes(keyword.toLowerCase()) ||
            e.chainId.toLowerCase().includes(keyword.toLowerCase())
        )
        setFilterData(newData)
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.container} id="chains">
                <div className={styles.searchContainer}>
                    <input className={styles.inputSearch} type="text" placeholder='chain name' value={searchValue} onChange={(e) => { handleSearch(e.target.value) }} />
                    <AiOutlineSearch className={styles.searchIcon} />
                    <p className={styles.inputFooter}>Search from {allData.length} Chains</p>
                </div>
                <div className={styles.contentContainer}>
                    {
                        filterData && filterData.map((item, index) => {
                            return (
                                <div className={item.isArchive ? styles.archiveContent : styles.content} key={index}>
                                    <ChainCard chain={item} />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
