import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { compactNumber } from '../../../../scripts/utils';
import { AiFillQuestionCircle } from 'react-icons/ai'
import { Tooltip as ReactToolTip } from 'react-tooltip'

export default function MissedBlocksChart({ validatorsData }) {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        BarElement,
        Title,
        Tooltip,
        Legend,
        Filler
    );

    const options = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                titleFont: {
                    size: 20,
                    family: 'Monaco'
                },
                bodyFont: {
                    size: 20,
                    family: 'Monaco'
                },
                callbacks: {
                    title: function () {
                        return ''
                    },
                    label: function (context) {
                        let label = context.label || '';
                        let value = context.dataset.data[context.dataIndex];
                        return label + ': ' + value;
                    },
                },
            }
        },
        responsive: true,
        interaction: {
            intersect: false,
        },
        scales: {
            x: {
                display: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'Monaco'
                    },
                    callback: function (value, index) {
                        let label = this.getLabelForValue(index);
                        return label && label.length > 15
                            ? label.substring(0, 11) + '...'
                            : label;
                    }
                }
            },
            y: {
                display: true,
                ticks: {
                    precision: 0,
                    font: {
                        size: 15,
                        family: 'Monaco'
                    }
                }
            }
        }
    };

    const [data, setData] = useState(null)

    useEffect(() => {
        if (!validatorsData.validators) {
            return;
        }

        validatorsData.validators.sort((a, b) => b.missed_blocks_periods[1]?.missed - a.missed_blocks_periods[1]?.missed)
        let topActivevalidators = validatorsData.validators.slice(0, 125);
        let labels = validatorsData.validators.filter((validator) => validator.missed_blocks_periods[1]?.missed && validator.active).map((validator) => validator.moniker);
        let data = validatorsData.validators.filter((validator) => validator.missed_blocks_periods[1]?.missed && validator.active).map((validator) => validator.missed_blocks_periods[1]?.missed);

        if (!data.length) {
            return;
        }

        setData({
            labels: labels,
            datasets: [
                {
                    data: data,
                    backgroundColor: [
                        '#89D4F5', '#BCD759', '#FFBF00', '#9961A7',
                        '#4891EA', '#EE965B', '#F284D1', '#6FDBCB',
                        '#2D71C4', '#EF5A5A', '#609C29', '#C69B06',
                        '#8A2299', '#996D6C', '#2F2F6C', '#1C6C61',
                    ]
                }
            ]
        })

    }, [validatorsData])

    const styles = {
        wrapper: 'my-[2rem] bg-[#fff] p-4 rounded-md border-[1px] border-borderColor',
        container: 'w-[100%] h-[100%]',
        title: 'text-center text-[1.5rem] font-[500] text-[#353535] pb-4',
        icon: 'text-[1.5rem] text-[#353535] cursor-pointer',
    }

    return (
        <div className={data ? styles.wrapper : 'hidden'}>
            <div className={styles.container}>
                <h2 className={styles.title}>Missed Blocks (over last 15000 blocks)</h2>
                {data && <Bar options={options} data={data} />}
            </div>
        </div>
    )
}

