import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ChainSidebar from '../../components/chains/ChainSidebar';
import SectionHandler from './SectionHandler';
import { chainsData } from '../../data/data';
import { humanReadableTimeDifferenceString } from '../../scripts/utils';
import Footer from '../../components/common/Footer';

export default function Chains() {

    const styles = {
        wrapper: 'min-h-[100vh] mt-[4rem]',
        container: 'p-[1rem] md:p-[2rem] flex',
    }

    const location = useLocation();

    const [chainName, setChainName] = useState('');
    const [sectionName, setSectionName] = useState('');
    const [data, setData] = useState(chainsData);
    const [archiveReason, setArchiveReason] = useState('');

    useEffect(() => {
        setChainName(location.pathname.split('/')[1])
        setSectionName(location.search.split('?')[1])
        const newChain = data.filter((e) => e.id === location.pathname.split('/')[1]);
        setData(newChain);
        if (newChain.endedAt) {
            const endedAtNTimeAgo = humanReadableTimeDifferenceString(newChain.endedAt);
            const chainNet = newChain.isTestnet ? 'Testnet' : 'Mainnet';
            setArchiveReason(`${chainNet} ended ${endedAtNTimeAgo} ago`);
        }
    }, [location])

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <ChainSidebar
                        chainName={data[0].chainName}
                        sectionName={sectionName}
                        rpcServer={data[0].rpcServer}
                        logo={data[0].logo}
                        chainId={data[0].chainId}
                        archiveReason={archiveReason}
                        isArchive={data[0].isArchive}
                        summaryDisabled={data[0].summaryDisabled}
                    />
                    <div className="w-[100%] md:pl-[calc(250px+2rem)]">
                        <SectionHandler chain={data[0]} chainName={chainName} sectionName={sectionName} />
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    )
}
