import React from 'react'
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { Tooltip } from 'react-tooltip'

export default function CircularProgress({ bondedTokenRatio, tokensDistributionRatio, athPriceRatio }) {

    const styles = {
        wrapper: 'pt-[2rem]',
        container: 'grid grid-cols-1 md:grid-cols-3 gap-[2rem] place-items-center',
        bar: 'p-4 cursor-pointer'
    }


    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.bar} id="circular1" data-tooltip-content="This ratio shows percentage of bonded tokens in total supply. The higher this ratio the safer is the chain.">
                        {
                            <CircularProgressbarWithChildren
                                value={bondedTokenRatio?.ratio || 0}
                                styles={buildStyles({
                                    pathTransitionDuration: 0.5,
                                    textSize: '10px',
                                    pathColor: `${bondedTokenRatio?.outerStrokeColor}` || `'rgba(208, 215, 222, 1)`,
                                    trailColor: `${bondedTokenRatio?.innerStrokeColor}`,
                                    backgroundColor: `${bondedTokenRatio?.outerStrokeColor}`
                                })}
                            >
                                <p className='text-3xl'>{bondedTokenRatio?.ratio.toFixed(2) || 0}%</p>
                                <p>Bonded Token Ratio</p>
                            </CircularProgressbarWithChildren>
                        }
                    </div>
                    <div className={styles.bar} id="circular2" data-tooltip-content="This ratio shows percentage of validators who own more than 50% of bonded tokens in total. The higher this ratio the more decentralized is the chain.">
                        {
                            <CircularProgressbarWithChildren
                                value={tokensDistributionRatio?.ratio || 0}
                                styles={buildStyles({
                                    pathTransitionDuration: 0.5,
                                    textSize: '10px',
                                    pathColor: `${tokensDistributionRatio?.outerStrokeColor}` || `'rgba(208, 215, 222, 1)`,
                                    trailColor: `${tokensDistributionRatio?.innerStrokeColor}`,
                                    backgroundColor: `${tokensDistributionRatio?.outerStrokeColor}`
                                })}
                            >
                                <p className='text-3xl'>{tokensDistributionRatio?.ratio.toFixed(2) || 0}%</p>
                                <p>Tokens Distribution Ratio</p>
                            </CircularProgressbarWithChildren>
                        }
                    </div>
                    <div className={styles.bar} id="circular3" data-tooltip-content="This ratio shows percentage of current price in ATH price.">
                        {
                            <CircularProgressbarWithChildren
                                value={athPriceRatio?.ratio || 0}
                                styles={buildStyles({
                                    pathTransitionDuration: 0.5,
                                    textSize: '10px',
                                    pathColor: `${athPriceRatio?.outerStrokeColor}` || `'rgba(208, 215, 222, 1)`,
                                    trailColor: `${athPriceRatio?.innerStrokeColor}`,
                                    backgroundColor: `${athPriceRatio?.outerStrokeColor}`
                                })}
                            >
                                <p className='text-3xl'>{athPriceRatio?.ratio.toFixed(2) || 0}%</p>
                                <p>ATH Price Ratio</p>
                            </CircularProgressbarWithChildren>
                        }
                    </div>
                </div>
            </div>
            <Tooltip anchorId="circular1" />
            <Tooltip anchorId="circular2" />
            <Tooltip anchorId="circular3" />
        </>
    )
}
