import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AiFillStar, AiFillExclamationCircle } from 'react-icons/ai'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import ProgressBar from "@ramonak/react-progress-bar"
import { Link } from 'react-router-dom'

export default function ChainCard({ chain }) {

    const [gov, setGov] = useState('')

    const GetGov = (gov) => {
        let Status = false
        if (gov.result.length > 0) {
            Status = true;
        } else { Status = false; }
        return Status
    }

    const GetGovId = (gov) => {
        let Id = 0
        if (gov.result.length > 0) {
            Id = gov.result[0].id
        }
        return Id
    }

    useEffect(() => {
        axios.get(`https://rest.cosmos.directory/${chain.id}/gov/proposals?status=voting_period`).then((res) => {
            if (res.status === 200) {
                const _gov = res.data;
                _gov.Active = GetGov(gov);
                _gov.ID = GetGovId(gov);
                setGov(_gov)
            }
        }).catch((err) => { })
    }, [chain])

    const [price, setPrice] = useState('')

    const extractPrice = (coingekoSummary) => {
        let price = coingekoSummary?.market_data?.current_price?.usd;
        if (!price) {
            return '-';
        }
        return Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumSignificantDigits: 4
        }).format(price);
    }

    const [coinId, setCoinId] = useState(chain.coingekoCoinId || chain.id)

    useEffect(() => {
        axios.get(`https://api.coingecko.com/api/v3/coins/${coinId}`).then((res) => {
            if (res.status === 200) {
                setPrice(extractPrice(res.data))
            }
        }).catch((err) => { })
    }, [coinId])

    let apiChainId = chain.apiChainId || chain.id;

    const [progress, setProgress] = useState('')

    useEffect(() => {
        if (apiChainId === "ixo") {
            axios.get(`https://api-ixo.cosmostation.io/v1/status`).then((res) => {
                if (res.status === 200) {
                    setProgress(res.data.chain?.params?.calculated_apr.toFixed(2) * 100 || 0)
                }
            }).catch((err) => { })
        }
        else {
            axios.get(`https://chains.cosmos.directory/${apiChainId}`).then((res) => {
                if (res.status === 200) {
                    setProgress(res.data.chain?.params?.calculated_apr.toFixed(2) * 100 || 0)
                }
            }).catch((err) => { })
        }
    }, [apiChainId])

    const styles = {
        cardContainer: 'w-[80vw] sm:w-[40vw] lg:w-[28vw] relative',
        logoContainer: 'relative',
        logo: 'w-[50px] h-[50px]',
        typeContainer: 'flex justify-between place-items-center',
        typeText: 'text-[0.8rem] text-[#fff] bg-[#7e8fdfa9] w-[fit-content] px-[0.6rem] py-[5px] rounded-full',
        activeContainer: '',
        activeIcon: 'text-[1rem] absolute top-[-5px] right-[-5px] cursor-pointer text-[green]',
        supportIcon: 'text-[#ffc107] absolute right-[-2rem] top-0 cursor-pointer text-[1.25rem]',
        extraContainer: 'flex pt-[2rem] flex-row sm:flex-col md:flex-row justify-between place-items-center gap-[1rem] extraContainer',
        contentContainer: 'flex',
        container: 'flex justify-between place-items-center relative',
        priceContainer: '',
        price: 'absolute right-[10px] top-[4.25rem]',
        titleContainer: 'text-center flex flex-col justify-center place-items-center gap-[0.5rem]',
        title: 'text-[1.5rem] text-[#000] font-[600]',
        subtitle: 'text-[1rem] text-[#888]',
        buttonsContainer: 'pt-[1.6rem] pb-[-4.4rem] flex duration-300 place-items-center justify-center gap-[0.5rem] flex-col sm:flex-row hidden buttonsContainer',
        button: 'primaryButton duration-300 block w-[200px] sm:w-[120px] h-[30px] text-[0.8rem] border-[1px] border-[#000] rounded-full bg-[rgba(0,0,0,0.1)] text-[#000] hover:bg-[#333] hover:text-[#fff]',
        progressContainer: 'px-[0rem] block w-full pr-[1rem]',
    }

    return (
        <>
            <Link to={`/${chain.id}?summary`}>
                <div className={styles.cardContainer}>
                    <div className={styles.container}>
                        <div className={styles.logoContainer}>
                            <img src={`/images/chain/${chain.logo}`} alt={chain.id} className={styles.logo} />
                            {
                                chain.isProjectSupportUs ? (
                                    <>
                                        <AiFillStar id={`support_${chain.id}`} className={styles.supportIcon} data-tooltip-content={`${chain.chainName} supports us`} />
                                        <ReactTooltip anchorId={`support_${chain.id}`} />
                                    </>
                                ) : <></>
                            }

                        </div>
                        <div className={styles.titleContainer}>
                            <h3 className={styles.title}>{chain.chainName}</h3>
                            <p className={styles.subtitle}>{chain.chainId}</p>
                        </div>
                        <div className={styles.priceContainer}>
                            <div className={styles.price}>{price || '-'}</div>
                        </div>
                    </div>
                    <div className={styles.extraContainer}>
                        <div className={styles.typeContainer}>
                            <p className={styles.typeText}>
                                {
                                    chain.isTestnet ? (
                                        "Testnet"
                                    ) : (
                                        "Mainnet"
                                    )
                                }
                            </p>
                        </div>
                        <div className={styles.progressContainer}>
                            <ProgressBar
                                completed={progress}
                                className="progressWrapper"
                            />
                        </div>
                    </div>
                    <div className={styles.buttonsContainer}>
                        <Link to={`/${chain.id}?install`}>
                            <button className={styles.button}>Install</button>
                        </Link>
                        <Link to={`/${chain.id}?sync`}>
                            <button className={styles.button}>Sync</button>
                        </Link>
                        <Link to={`/${chain.id}?cheat-sheet`}>
                            <button className={styles.button}>Cheat Sheet</button>
                        </Link>
                    </div>
                    {
                        gov?.Active ? (
                            <>
                                <div className={styles.activeContainer}>
                                    <a href="{{chain.blockExplorer}}/gov/{{gov.ID}}" >
                                        <AiFillExclamationCircle className={styles.activeIcon} id={`star_${chain.id}`} data-tooltip-content={`No ${gov.ID} proposal is on voting status.&#10;&#13;${gov?.result[0].content.value.title}...&#10;&#13; Click to Vote now if not done so!`} />
                                    </a>
                                </div>
                                <ReactTooltip anchorId={`star_${chain.id}`} />
                            </>
                        ) : <></>
                    }
                </div>
            </Link>
        </>
    )
}
