import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { compactNumber } from '../../../../scripts/utils';

export default function VolumeChart({ coingeckoData }) {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        Filler
    );

    const options = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                titleFont: {
                    size: 20,
                    family: 'Monaco'
                },
                bodyFont: {
                    size: 20,
                    family: 'Monaco'
                },
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (context.parsed.y !== null) {
                            label += context.parsed.y + "";
                        }
                        return label;
                    }
                }
            }
        },
        responsive: true,
        interaction: {
            intersect: false,
        },
        scales: {
            x: {
                display: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'Monaco'
                    }
                }
            },
            y: {
                display: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'Monaco'
                    },
                    callback: function (value) {
                        return compactNumber(parseInt(value.toString()), 2);
                    }
                },
            }
        }
    };

    const [data, setData] = useState(null)

    useEffect(() => {
        if(!coingeckoData.total_volumes) {
            return;
        }
        let volume = coingeckoData.total_volumes.slice(0, -1);
        if (!volume.length) {
            return;
        }

        let volumeX = volume.map((item) => item[0]);
        let volumeY = volume.map((item) => item[1]);

        let volumeLabels = [];
        volumeX.forEach((item) => {
            let volumeLabel = new Date(item);
            volumeLabels.push(volumeLabel.toLocaleDateString('en', { month: 'short', day: 'numeric' }));
        });

        setData({
            labels: volumeLabels,
            datasets: [
                {
                    data: volumeY,
                    borderColor: "rgb(234, 128, 252)",
                    backgroundColor: "rgb(234, 128, 252, 0.1)",
                    fill: true,
                    borderWidth: 2,
                    tension: 0.4,
                }
            ]
        })

    }, [coingeckoData])

    const styles = {
        wrapper: 'my-[2rem] bg-[#fff] p-4 rounded-md border-[1px] border-borderColor',
        container: 'w-[100%] h-[100%]',
        title: 'text-center text-[1.5rem] font-[500] text-[#353535] pb-4'
    }

    return (
        <div className={data ? styles.wrapper : 'hidden'}>
            <div className={styles.container}>
                <h2 className={styles.title}>Volume Chart (14d)</h2>
                {data && <Line options={options} data={data} />}
            </div>
        </div>
    )
}

