export const mapData = [
    { "id": "2b3ac9e4652b91c50f893920394e18e0dd34b8cd", "ip": "204.16.244.107", "moniker": null, "latitude": "40.48247", "longitude": "-80.03640", "isp": "TeraSwitch Networks Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "TeraSwitch Networks Inc." },
    { "id": "a7d96dc929824613315dcc1c90fee119f28cc51f", "ip": "164.152.160.103", "moniker": "Tendermint", "latitude": "33.97997", "longitude": "-118.40630", "isp": "Allnodes Inc", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Allnodes Inc" },
    { "id": "5c189bb73ae43a6fee13794d5833f131c227985c", "ip": "3.96.90.73", "moniker": "Haliphron Atlanticus", "latitude": "45.42178", "longitude": "-75.69116", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/ca_64.png", "countryName": "Canada", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "ff8d90cf707dc43e3aec8e5ae56787b93eec9872", "ip": "204.16.244.108", "moniker": "cdbst8gvol8f8pb3158g", "latitude": "40.48247", "longitude": "-80.03640", "isp": "TeraSwitch Networks Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "TeraSwitch Networks Inc." },
    { "id": "2be90ecc844a071ff56b1a94dc48ff0cc386bf08", "ip": "135.181.20.164", "moniker": "e37667aa-44ba-4af5-9ec3-97bd1b7fcc0e", "latitude": "60.16940", "longitude": "24.93553", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "5f0299aff280e15b5570521f5aadb0fbd67550a8", "ip": "142.132.132.182", "moniker": "bwarelabs-archive-1", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "ab4ee41c7a2d9d7f0e3f7c85071460b513292b75", "ip": "15.204.197.51", "moniker": "49CE569AB0FF5FE11BB9F6BE7F9555ED", "latitude": "38.95579", "longitude": "-77.36463", "isp": "OVH US LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "675b7a4b3b4ff31cf8aa1f150f3df0e04df0d483", "ip": "142.132.132.186", "moniker": null, "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "c4bcccf924b5a2dd3798ef8b2b63479694da0a2c", "ip": "51.79.228.63", "moniker": "bwarelabs-archive-2", "latitude": "1.27987", "longitude": "103.84871", "isp": "OVH Singapore PTE. LTD", "countryFlag": "https://ipgeolocation.io/static/flags/sg_64.png", "countryName": "Singapore", "continentName": "Asia", "organization": "OVH SAS" },
    { "id": "edc569e420c0513b940e6bf18d4fdac9477036bb", "ip": "65.108.238.102", "moniker": "lavender.evmos.relayer", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "77b6d86dd3518dd4c1d9b2672d6f56db0b45d9cd", "ip": "18.209.229.95", "moniker": "evmos-clust-ar-mainnet", "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "ed6518c8cbd9636f1f793dd634f79825104d9651", "ip": "65.108.137.22", "moniker": "ECO Stake", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "458e7e0ab8084b4aac169a9b27576a4921e4d0f2", "ip": "148.251.78.182", "moniker": "mmmmmro", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "746d5a0040ef1ae5843f5bb74dd9c64e2ce6a726", "ip": "52.207.212.98", "moniker": null, "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "e6d50114b6646418e3042951f399c128056767af", "ip": "157.90.179.182", "moniker": null, "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "db2fb8af6aa80e472ca174c308994481ade46cb1", "ip": "65.109.59.174", "moniker": "stakin21", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "c7589a3d0be2b6324f4fb55785500e7e20aae977", "ip": "65.21.200.142", "moniker": null, "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "10c1369cf5788d0f8d720fc6a2e70314178afab2", "ip": "54.202.73.49", "moniker": "FirstFoundry", "latitude": "44.93326", "longitude": "-123.04381", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "4bc180ec11a89d57989cfef328a0b20f0ea71aa3", "ip": "35.227.65.2", "moniker": null, "latitude": "37.42240", "longitude": "-122.08421", "isp": "Google LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Google LLC" },
    { "id": "f9b100779650e64f7a66dd16c76b3e86477ccb5b", "ip": "34.106.233.103", "moniker": "node", "latitude": "37.42240", "longitude": "-122.08421", "isp": "Google LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Google LLC" },
    { "id": "e7beaa1145c05f1d14b25bb37934dea1189b6a02", "ip": "162.55.131.238", "moniker": null, "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "43730f5c0d348d566f0aef15ed32660123f98b14", "ip": "35.245.211.8", "moniker": null, "latitude": "37.42240", "longitude": "-122.08421", "isp": "Google LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Google LLC" },
    { "id": "e7fd6eb05da309c8abd74243aa721fa1b65ab94c", "ip": "94.130.206.215", "moniker": "heisenbug", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "04536da0ac4b28f323c869822588b0ec7612b5ad", "ip": "65.108.99.37", "moniker": null, "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "da49bd3c159fdabc7ffaaedc6bc54a56183e9ce9", "ip": "66.94.126.62", "moniker": "contabo-node-8", "latitude": "38.63273", "longitude": "-90.19624", "isp": "Contabo Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Contabo GmbH" },
    { "id": "519464eeec990bc48bdbb6378d972c9c6c39800e", "ip": "142.132.151.35", "moniker": null, "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "5451fadf04ef4403a69178b895ff63c6d91c7633", "ip": "209.126.77.199", "moniker": "POCKET-C0D3R-GW-2022-08-24-15", "latitude": "38.66913", "longitude": "-90.64333", "isp": "Datasoft Networks Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "CDM" },
    { "id": "1fd9c1043afef7f5d209c5e336a78b673eac0c6b", "ip": "35.245.184.103", "moniker": "node", "latitude": "37.42240", "longitude": "-122.08421", "isp": "Google LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Google LLC" },
    { "id": "03c72216648dd912a3a8df29fc455f3d5ee6b10d", "ip": "3.92.225.50", "moniker": "evmos-clust-ar-mainnet", "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "04756c753a8aee0f7c316ed1b94107878c8a8df3", "ip": "51.79.228.51", "moniker": "bwarelabs-archive-1", "latitude": "1.27987", "longitude": "103.84871", "isp": "OVH Singapore PTE. LTD", "countryFlag": "https://ipgeolocation.io/static/flags/sg_64.png", "countryName": "Singapore", "continentName": "Asia", "organization": "OVH SAS" },
    { "id": "eb37892c4c7924634a0e097dc5e786d8eb429076", "ip": "65.108.3.238", "moniker": null, "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "96557e26aabf3b23e8ff5282d03196892a7776fc", "ip": "204.16.242.187", "moniker": "bd-evmos-mainnet-state-sync-us-01", "latitude": "40.48247", "longitude": "-80.03640", "isp": "TeraSwitch Networks Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "TeraSwitch Networks Inc." },
    { "id": "f403e9492c975e2d50393fc4974420ef70ec459b", "ip": "44.197.177.166", "moniker": "evmos-clust-ar-mainnet", "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "6bbe3a8c52bbdf9b70a698ae3cfb423891940ffa", "ip": "152.228.225.215", "moniker": "chaintools-main", "latitude": "50.69343", "longitude": "3.19983", "isp": "OVH SAS", "countryFlag": "https://ipgeolocation.io/static/flags/fr_64.png", "countryName": "France", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "deb8a2bbf2ac46fec245b44431c3cd32deaf8155", "ip": "142.132.149.228", "moniker": null, "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "4afd2c65907e3e48f4a92bf9dafc4bcff3e6d955", "ip": "51.81.67.44", "moniker": "evmosprod2", "latitude": "38.95579", "longitude": "-77.36463", "isp": "OVH US LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "8592cce373410c21271512c70e68acc4d616dfe9", "ip": "57.128.20.159", "moniker": "evmos", "latitude": "48.57489", "longitude": "7.75417", "isp": "OVH SAS", "countryFlag": "https://ipgeolocation.io/static/flags/fr_64.png", "countryName": "France", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "3cc2911635c6665e5c4bce32424911e29029be37", "ip": "46.4.119.90", "moniker": null, "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "68d07d20426f38351d299285c8bf83028a8aee35", "ip": "204.16.244.247", "moniker": null, "latitude": "40.48247", "longitude": "-80.03640", "isp": "TeraSwitch Networks Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "TeraSwitch Networks Inc." },
    { "id": "d8d04e26f5d683a2f60213e3ad23319f727b7113", "ip": "198.244.213.92", "moniker": "node", "latitude": "51.48588", "longitude": "0.18357", "isp": "OVH Ltd", "countryFlag": "https://ipgeolocation.io/static/flags/gb_64.png", "countryName": "United Kingdom", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "e48731a1434907e26c67cf9e131fbdd449436855", "ip": "15.235.165.62", "moniker": "blake-lively", "latitude": "1.27987", "longitude": "103.84871", "isp": "OVH Singapore PTE. LTD", "countryFlag": "https://ipgeolocation.io/static/flags/sg_64.png", "countryName": "Singapore", "continentName": "Asia", "organization": "OVH SAS" },
    { "id": "b18132c3ecf1c7057d418a72c82807080d8a7053", "ip": "65.21.93.108", "moniker": "forbole-hel", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "565252a4bc05cd5d48084f4a5e1ab9689a433deb", "ip": "176.9.44.29", "moniker": "node15", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "ba3e9af6380e71521aa5d1c7561bc8f9457ab275", "ip": "204.16.244.120", "moniker": "cdbstqpko4f2508kmafg", "latitude": "40.48247", "longitude": "-80.03640", "isp": "TeraSwitch Networks Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "TeraSwitch Networks Inc." },
    { "id": "66b369fccd238aa300cbde61b1799b39afb22005", "ip": "51.38.62.199", "moniker": "AlphaNodes.EVMOS", "latitude": "50.69224", "longitude": "3.20003", "isp": "OVH SAS", "countryFlag": "https://ipgeolocation.io/static/flags/fr_64.png", "countryName": "France", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "5d4a67dac324ca252d22fc92fb4776e1195e34f6", "ip": "65.108.43.26", "moniker": null, "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "24db7b10f4985d0578eda2b67398a47f223c29da", "ip": "65.108.108.179", "moniker": null, "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "c130c7ec8f901f86fd5eca910ccb94ca008f6f2f", "ip": "65.108.135.140", "moniker": "Stake Frites (0,0)", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "a7ce3233363d00f79038b1de1bd81a10fc7afe7c", "ip": "204.16.244.124", "moniker": null, "latitude": "40.48247", "longitude": "-80.03640", "isp": "TeraSwitch Networks Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "TeraSwitch Networks Inc." },
    { "id": "e0da1fe0f995bee84c5a591b1d87828f351a63b6", "ip": "204.16.244.122", "moniker": null, "latitude": "40.48247", "longitude": "-80.03640", "isp": "TeraSwitch Networks Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "TeraSwitch Networks Inc." },
    { "id": "f29f1be9ae0bf73b699b9094ccf3553a59430d91", "ip": "204.16.244.123", "moniker": null, "latitude": "40.48247", "longitude": "-80.03640", "isp": "TeraSwitch Networks Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "TeraSwitch Networks Inc." },
    { "id": "6ae925075a25f3e134e4dc04877827ee7042a137", "ip": "34.201.8.190", "moniker": "evmos-clust-ar-mainnet", "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "0393c19b176d1cf8bc560c5a8fa990301deb1a7e", "ip": "95.216.235.52", "moniker": null, "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "384cd06ac005a2162e8a41e28eeaa5ef00467c1b", "ip": "15.204.197.68", "moniker": "DF3F64BD300035F5443A346F1745FFBA", "latitude": "38.95579", "longitude": "-77.36463", "isp": "OVH US LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "4ed18b7b10cf76afca3497cbed1db24a153e1b72", "ip": "18.204.204.169", "moniker": "evmos-clust-ar-mainnet", "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "acdf3de82256e85ed7f4f6435653c23d60c274f7", "ip": "54.74.147.19", "moniker": "GalaxyDigital-3", "latitude": "53.34807", "longitude": "-6.24827", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/ie_64.png", "countryName": "Ireland", "continentName": "Europe", "organization": "Amazon.com, Inc." },
    { "id": "babc3f3f7804933265ec9c40ad94f4da8e9e0017", "ip": "38.146.3.100", "moniker": "evmos_9001-2-seed", "latitude": "42.55962", "longitude": "-83.19336", "isp": "InfoSec Pros", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Cogent Communications" },
    { "id": "40f4fac63da8b1ce8f850b0fa0f79b2699d2ce72", "ip": "144.76.76.109", "moniker": "evmos-node-2", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "b119efc22e5404ca896538f02e9481414552bc53", "ip": "141.95.124.164", "moniker": "nnm", "latitude": "50.38823", "longitude": "8.07392", "isp": "OVH GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "126255d17456bea8c3c1677c2cada6a9dd4cc0bf", "ip": "107.135.15.66", "moniker": "Architect Nodes", "latitude": "38.62775", "longitude": "-90.19956", "isp": "AT&T Services, Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "AT&T Services, Inc." },
    { "id": "15187a425115889f4027965cb1f3d416c370be4f", "ip": "65.108.77.152", "moniker": "Validatrium", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "3622f96487d4d36b8d6404a64677d3086972ceac", "ip": "85.237.193.90", "moniker": "Consensus One", "latitude": "52.23560", "longitude": "21.01037", "isp": "Consensus One Sp. z o.o.", "countryFlag": "https://ipgeolocation.io/static/flags/pl_64.png", "countryName": "Poland", "continentName": "Europe", "organization": "Consensus One Sp. z o.o." },
    { "id": "2406e7af3fdcb6c38b90a916bc2a2d309f9e41b0", "ip": "13.215.12.111", "moniker": null, "latitude": "1.29041", "longitude": "103.85211", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/sg_64.png", "countryName": "Singapore", "continentName": "Asia", "organization": "Amazon.com, Inc." },
    { "id": "af62bbb6888e6812ea2053347ff8065176d973c5", "ip": "192.155.88.152", "moniker": "Provalidator", "latitude": "40.80849", "longitude": "-74.46444", "isp": "Linode, LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Linode, LLC" },
    { "id": "d00083a2129aead8c10a3e787fd48d767d38feee", "ip": "164.152.160.63", "moniker": "Tendermint", "latitude": "33.97997", "longitude": "-118.40630", "isp": "Allnodes Inc", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Allnodes Inc" },
    { "id": "64433b8a26f8d39528c4c87740768e0bb24da9e1", "ip": "65.108.122.99", "moniker": "test", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "101db958a7da37326a52f82d4bd58bfdf44cb1f3", "ip": "15.235.114.177", "moniker": "OrbitalApes", "latitude": "45.50254", "longitude": "-73.57160", "isp": "OVH Hosting, Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/ca_64.png", "countryName": "Canada", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "1bf1d58b0c301badfdad70ccd136e33d43b5237c", "ip": "65.21.192.108", "moniker": null, "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "936a51a61d19405df66644377a774b66272de08d", "ip": "65.109.37.100", "moniker": "MN92AH1K", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "7112cea114102a9c56b6940c0cdd8404127f3adc", "ip": "167.235.102.90", "moniker": "BwareLabs-sentry", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "1bd58858567fb887d23c52359f8b15db74ed8295", "ip": "15.235.86.205", "moniker": "snow", "latitude": "45.50254", "longitude": "-73.57160", "isp": "OVH Hosting, Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/ca_64.png", "countryName": "Canada", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "4de2d5f7a806acd3407ebf58ec95ce8384e629a0", "ip": "65.108.142.81", "moniker": "Stakely.io", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "bcdc0d81095b4cea6ef31aa25195b15239dd1c08", "ip": "162.19.81.54", "moniker": "chorus_sentry37", "latitude": "50.98721", "longitude": "2.12054", "isp": "OVH SAS", "countryFlag": "https://ipgeolocation.io/static/flags/fr_64.png", "countryName": "France", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "b5c91bbc5b773300b247f8cd180289f3478fc2c4", "ip": "88.218.224.98", "moniker": "SmartStake", "latitude": "49.75812", "longitude": "9.80695", "isp": "Michael Sebastian Schinzel trading as IP-Projects GmbH & Co. KG", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Michael Sebastian Schinzel trading as IP-Projects GmbH & Co. KG" },
    { "id": "05f336c16299ec9f480f29c09988db3897e876e5", "ip": "162.55.103.20", "moniker": "node", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "20bd9d585d214144fc0511a7845a71ae82f2025a", "ip": "65.108.76.220", "moniker": "ugaenn", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "58418c6c66b0b6a59438aa7c96da4b736df3d472", "ip": "162.19.136.45", "moniker": "pokt-1", "latitude": "50.38823", "longitude": "8.07392", "isp": "OVH GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "819c771de763faba18aee06b6b724f11dec4f95d", "ip": "18.206.118.36", "moniker": null, "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "6c02d7f1bc7e639c5485f9373390f51463b27969", "ip": "104.207.154.135", "moniker": "SpacePepe Labs", "latitude": "34.05855", "longitude": "-118.23671", "isp": "Vultr Holdings, LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "The Constant Company, LLC" },
    { "id": "b6d96357d7e764b5bebd59f805f021b588e12523", "ip": "162.19.69.126", "moniker": "NodeStake", "latitude": "50.98721", "longitude": "2.12054", "isp": "OVH SAS", "countryFlag": "https://ipgeolocation.io/static/flags/fr_64.png", "countryName": "France", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "570a316efddc6b35d436a77558a66dd3dcac5ade", "ip": "23.81.180.230", "moniker": "Cosmostation", "latitude": "37.38827", "longitude": "-121.88843", "isp": "Leaseweb USA, Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Leaseweb USA, Inc." },
    { "id": "8af0e8c2fb25166a72d6320e8e20df5bc2ae3d4b", "ip": "34.171.202.132", "moniker": null, "latitude": "37.42240", "longitude": "-122.08421", "isp": "Google LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Google LLC" },
    { "id": "6032873edbaf1bc3c7ef7610d5fc4addc0b48971", "ip": "169.155.44.90", "moniker": "Tendermint", "latitude": "33.97997", "longitude": "-118.40630", "isp": "Allnodes Inc", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Allnodes Inc" },
    { "id": "8e291789ea39aade00a48bf4c5c04e4f2bf392b4", "ip": "43.157.122.45", "moniker": "sentry1", "latitude": "23.12518", "longitude": "113.28064", "isp": "Tencent Cloud", "countryFlag": "https://ipgeolocation.io/static/flags/cn_64.png", "countryName": "China", "continentName": "Asia", "organization": "Shenzhen Tencent Computer Systems Company Limited" },
    { "id": "ae0beffb17e1fd61367be434a4c72a0688757403", "ip": "65.108.238.6", "moniker": "evmosd_main", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "fa5cd2267e0f7d0efb581986022bbfd83d4effb9", "ip": "195.14.6.44", "moniker": "01node", "latitude": "44.43314", "longitude": "26.12337", "isp": "SECURE EXPERT SOFTWARE SRL", "countryFlag": "https://ipgeolocation.io/static/flags/ro_64.png", "countryName": "Romania", "continentName": "Europe", "organization": "SECURE EXPERT SOFTWARE SRL" },
    { "id": "a1e0ae7538cc6d040824499dd1b3080fb2b80488", "ip": "195.14.6.45", "moniker": "01node", "latitude": "44.43314", "longitude": "26.12337", "isp": "SECURE EXPERT SOFTWARE SRL", "countryFlag": "https://ipgeolocation.io/static/flags/ro_64.png", "countryName": "Romania", "continentName": "Europe", "organization": "SECURE EXPERT SOFTWARE SRL" },
    { "id": "e297dce87ff7ca4d4c7fbc1cf7c81b0033f8dccc", "ip": "88.198.34.206", "moniker": "EValidavia", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "d09f12ead63a0bfbac425b33ac49d7f6135dc1f6", "ip": "44.205.7.81", "moniker": "evmos-clust-ar-mainnet", "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "9f8a7336c83e1a6939c93fb8e2377559379c1647", "ip": "65.108.200.49", "moniker": "Erel", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "c9136e3cf46f46fc400fb7ae7717421e2d16fb6a", "ip": "51.89.155.2", "moniker": "CosmosPug", "latitude": "51.48588", "longitude": "0.18357", "isp": "OVH Ltd", "countryFlag": "https://ipgeolocation.io/static/flags/gb_64.png", "countryName": "United Kingdom", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "6fe993d412221e187e86f4b35a31c936e156cf15", "ip": "65.108.122.254", "moniker": null, "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "cb63d6d8816c013773c8ab54a80d4edd52f22930", "ip": "23.236.118.227", "moniker": "w64644-2", "latitude": "1.27857", "longitude": "103.85762", "isp": "Zenlayer Inc", "countryFlag": "https://ipgeolocation.io/static/flags/sg_64.png", "countryName": "Singapore", "continentName": "Asia", "organization": "Zenlayer Inc" },
    { "id": "523811d95d099ef737a599f6805697bb45fa2fd5", "ip": "107.155.67.202", "moniker": "Architect Nodes", "latitude": "32.82222", "longitude": "-96.87004", "isp": "", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "HIVELOCITY, Inc." },
    { "id": "eba108332e888a15d770f52bacefc71cf4b87da1", "ip": "34.88.224.168", "moniker": "sentry1", "latitude": "37.42240", "longitude": "-122.08421", "isp": "Google LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Google LLC" },
    { "id": "223447903605fad89aa3d3678c9a98d11937d611", "ip": "51.250.35.74", "moniker": null, "latitude": "55.73398", "longitude": "37.58829", "isp": "Yandex.Cloud LLC", "countryFlag": "https://ipgeolocation.io/static/flags/ru_64.png", "countryName": "Russia", "continentName": "Europe", "organization": "Yandex.Cloud LLC" },
    { "id": "a1fff9314ec250629e34fcd1f5a8468e3e242f71", "ip": "135.181.22.238", "moniker": "rly-evmos", "latitude": "60.16940", "longitude": "24.93553", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "257485774b352f0ca3d57161a453649e6200b2d4", "ip": "5.9.147.22", "moniker": "EthermintMikhail", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "1f0663804fb80a94678392ee8fc7a05ed785a621", "ip": "169.155.168.29", "moniker": "Tendermint", "latitude": "33.97997", "longitude": "-118.40630", "isp": "Allnodes Inc", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Allnodes Inc" },
    { "id": "de2b03e988c882e1c36da9ccf20807c72459de18", "ip": "51.81.208.122", "moniker": "evmos1665089259", "latitude": "45.53393", "longitude": "-122.95657", "isp": "OVH US LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "707ffa0f76a68ab37da5b49d2891081cf011ddce", "ip": "3.86.149.21", "moniker": "evmos-clust-ar-mainnet", "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "b86bc9529ee94fa6d6c18f3ab8d5fb86efa58416", "ip": "162.19.169.164", "moniker": "ninja", "latitude": "50.38823", "longitude": "8.07392", "isp": "OVH GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "66c645609d29a5a3bae7853c1239678ac847f5a3", "ip": "52.15.128.56", "moniker": "2d30772e-4837-4a60-bd20-880da9147c15", "latitude": "39.96199", "longitude": "-83.00275", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "b8a5b5f915fba580a2b05dfefbec3b8c1cf05ac7", "ip": "13.214.168.115", "moniker": "bitrue-node", "latitude": "1.29041", "longitude": "103.85211", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/sg_64.png", "countryName": "Singapore", "continentName": "Asia", "organization": "Amazon.com, Inc." },
    { "id": "8d8124fa3ee8a12b94bcf4536dda7b94b197b449", "ip": "134.65.193.235", "moniker": "Tendermint", "latitude": "33.97997", "longitude": "-118.40630", "isp": "Allnodes Inc", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Allnodes Inc" },
    { "id": "0a26405b529f0e563b641df0c757d2f87d6e07fd", "ip": "144.76.63.67", "moniker": "ezstaking-sentry-1", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "f84869ac720b1ca0cfcee771629a0da113b3a5a4", "ip": "54.38.46.12", "moniker": "68c5fc99-4598-4cd9-88a4-99b7409da2c8", "latitude": "50.69224", "longitude": "3.20003", "isp": "OVH SAS", "countryFlag": "https://ipgeolocation.io/static/flags/fr_64.png", "countryName": "France", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "7c38f2b5b3feca2cb31138cf7179be426d7be4ae", "ip": "34.159.202.124", "moniker": null, "latitude": "37.42240", "longitude": "-122.08421", "isp": "Google LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Google LLC" },
    { "id": "8b92e58ba462c38e8840cc9f5b51ac94f7c7ba59", "ip": "65.108.45.115", "moniker": "Astake", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "c369eba335793639acb914cdd142deba857e6077", "ip": "138.201.37.244", "moniker": "3jfm8h", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "079cabdc16190430014bf988b8b052869eb6d9dd", "ip": "23.88.4.136", "moniker": "Tendermint", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "7ff6e8c8263a094fb37bdb88cfa16c58ce147075", "ip": "138.201.62.50", "moniker": null, "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "f990583827186834964525881bec472c01e5fd33", "ip": "2a01:4f9:6b:476d::2", "moniker": "evmos-archive", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "d07e1d6a7f3c66cd5f8dd21c28b54bbedab691e4", "ip": "35.205.247.43", "moniker": "bd-evmos-mainnet-full-node-01", "latitude": "37.42240", "longitude": "-122.08421", "isp": "Google LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Google LLC" },
    { "id": "926ac640e2b7d0148937dff825ab220de2899aba", "ip": "169.155.44.122", "moniker": "Tendermint", "latitude": "33.97997", "longitude": "-118.40630", "isp": "Allnodes Inc", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Allnodes Inc" },
    { "id": "6cb42d6208a07e16aa170cbe545f5fed8a6c2423", "ip": "108.209.99.69", "moniker": "Node", "latitude": "33.85455", "longitude": "-84.21714", "isp": "AT&T Corp.", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "AT&T Corp." },
    { "id": "a17b12a104ee0c7c48570020a024752a12b8f44e", "ip": "15.235.114.198", "moniker": "bwarelabs-archive-0", "latitude": "45.50254", "longitude": "-73.57160", "isp": "OVH Hosting, Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/ca_64.png", "countryName": "Canada", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "75ea03b6ef714a5e402fe3d136ad69376c9143ea", "ip": "51.81.243.150", "moniker": "evmosprod2", "latitude": "45.53393", "longitude": "-122.95657", "isp": "OVH US LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "67dca2fe3700b1c2298aab50f6028399c65d6944", "ip": "169.155.44.29", "moniker": "Tendermint", "latitude": "33.97997", "longitude": "-118.40630", "isp": "Allnodes Inc", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Allnodes Inc" },
    { "id": "bfa7a6b41bf1755c5eeeb3efc9910e0d8a4ade12", "ip": "142.132.135.125", "moniker": null, "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "cf6f69305bd91f43159b0051fbfb28fa32d4b18d", "ip": "65.109.57.124", "moniker": "4a2e2b7beaa9", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "992753251e464c15027c4e79a7c334ce021f90b6", "ip": "144.217.74.11", "moniker": "covalent-ovh-01", "latitude": "45.50254", "longitude": "-73.57160", "isp": "OVH Hosting, Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/ca_64.png", "countryName": "Canada", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "a171ff7f60ef98042f63add91641966daa2ab623", "ip": "159.69.66.149", "moniker": null, "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "f055bf9c3f1ef0b2755317133a7e60adfa1bd839", "ip": "3.82.216.154", "moniker": "evmos-clust-ar-mainnet", "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "109092451cee1ca052912c09406e6ea6d7d42e43", "ip": "15.235.13.144", "moniker": "GduZOlZ6ih", "latitude": "45.50254", "longitude": "-73.57160", "isp": "OVH Hosting, Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/ca_64.png", "countryName": "Canada", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "6e450b00e9d8d13a89492300484246f92975cced", "ip": "65.21.198.250", "moniker": "Tendermint", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "5230bb1bf132fdcddeb0f966a5139c74593423aa", "ip": "3.226.241.247", "moniker": "evmos-clust-ar-mainnet", "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "ab90584c1721fce720052c0b071875693cf052b4", "ip": "65.109.58.226", "moniker": "nodejumper", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "cb6ae22e1e89d029c55f2cb400b0caa19cbe5523", "ip": "3.98.186.232", "moniker": "Haliphron Atlanticus", "latitude": "45.42178", "longitude": "-75.69116", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/ca_64.png", "countryName": "Canada", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "dc6225a9153cbb7924b5f39d90ab963661992b14", "ip": "65.109.58.225", "moniker": "nodejumper", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "4332514ff0f5f8869acecf8651b396cbb9754844", "ip": "141.98.219.211", "moniker": null, "latitude": "40.48247", "longitude": "-80.03640", "isp": "TeraSwitch Networks Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "TeraSwitch Networks Inc." },
    { "id": "6c232db888406faa175b3231ade421de52ef9732", "ip": "65.21.230.230", "moniker": "Illuminati", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "1b4eeb315acdec5edd653648f964f6546844a117", "ip": "65.108.141.109", "moniker": "silent", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "8a733fd26f3ff1974cc2eb72a57f6c344da8d026", "ip": "15.204.196.180", "moniker": "carbonZERO", "latitude": "38.95579", "longitude": "-77.36463", "isp": "OVH US LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "4c11e7ed807a7a65b207cdeb41c511d344309d05", "ip": "3.67.220.240", "moniker": "abc", "latitude": "50.11208", "longitude": "8.68341", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Amazon.com, Inc." },
    { "id": "7c6e01c1dac41d67f1dcaa10411ec86456ff1f27", "ip": "44.204.197.144", "moniker": "evmos-clust-ar-mainnet", "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "ddc9c72a9c1beb6832c52951f86138a269b31eb7", "ip": "185.150.189.146", "moniker": "node1", "latitude": "25.79751", "longitude": "-80.23006", "isp": "ReliableSite.Net LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "ReliableSite.Net LLC" },
    { "id": "ad9f08613da5dfff730b16caad33a11e1833faa0", "ip": "57.128.144.225", "moniker": "forbole-lon", "latitude": "51.51112", "longitude": "-0.07965", "isp": "OVH Ltd", "countryFlag": "https://ipgeolocation.io/static/flags/gb_64.png", "countryName": "United Kingdom", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "994712950a7daad075031358129c646bbfb00af8", "ip": "51.195.4.139", "moniker": "node", "latitude": "50.38823", "longitude": "8.07392", "isp": "OVH GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "8abde91c2bdbd12fcc94dbe4933c91a3fe9c55e4", "ip": "65.108.237.179", "moniker": null, "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "7cb741da246b987be99ddd44bfcf390d4279f59b", "ip": "65.108.192.173", "moniker": "Evmosius", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "d201bac976e3f72dabf17718ec3f9786497bc273", "ip": "141.95.85.195", "moniker": null, "latitude": "50.38823", "longitude": "8.07392", "isp": "OVH GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "1918dc023a6e206d9c319543bd361b851b5e69ef", "ip": "204.16.244.99", "moniker": "cdcmlflnksrqhb1oi4a0", "latitude": "40.48247", "longitude": "-80.03640", "isp": "TeraSwitch Networks Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "TeraSwitch Networks Inc." },
    { "id": "0c260e3652258bdfa3743ff54ee7eb33d1627a37", "ip": "144.76.24.81", "moniker": "zighelm-node", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "617c72274d28bf0c4ef56a399261227e389ab09f", "ip": "161.97.130.97", "moniker": "efn2-potatoe", "latitude": "48.10425", "longitude": "11.60102", "isp": "Contabo GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Contabo GmbH" },
    { "id": "4054e4a5453f952eaa70898274d37f55f898d8c0", "ip": "146.59.53.178", "moniker": "Stakely.io", "latitude": "52.22552", "longitude": "21.04974", "isp": "OVH Sp. z o. o.", "countryFlag": "https://ipgeolocation.io/static/flags/pl_64.png", "countryName": "Poland", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "dec587d55ff38827ebc6312cedda6085c59683b6", "ip": "74.118.143.77", "moniker": null, "latitude": "52.36993", "longitude": "4.90788", "isp": "TeraSwitch Networks Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/nl_64.png", "countryName": "Netherlands", "continentName": "Europe", "organization": "TeraSwitch Networks Inc." },
    { "id": "d19cba5e3b35abd8c9eeec4b56fce803d0d1945c", "ip": "35.230.149.229", "moniker": "sentry2", "latitude": "37.42240", "longitude": "-122.08421", "isp": "Google LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Google LLC" },
    { "id": "f9d30ba8b651f5bb803cf776f358251c47aa5bd7", "ip": "47.75.104.62", "moniker": "okbtothemoon", "latitude": "39.90657", "longitude": "116.38765", "isp": "ALICLOUD-HK", "countryFlag": "https://ipgeolocation.io/static/flags/cn_64.png", "countryName": "China", "continentName": "Asia", "organization": "Alibaba (US) Technology Co., Ltd." },
    { "id": "6d22f09480e9e4d3dfb5e1e3880864680981577e", "ip": "23.88.81.42", "moniker": "5ba461", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "c8d0c8c1f7c1906f29e57c5295b6de16ee74856c", "ip": "95.217.37.135", "moniker": "d3c0bce81ff17ef3", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "efcd8b4f733cf76337e0f403ad598a7a63385079", "ip": "65.108.201.46", "moniker": "bdc1ac86f2ae", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "3ada6b8a95f7894088513cf9c0aa21c8883f7022", "ip": "65.108.108.42", "moniker": "0base.vc-evmos_9001-2-evmos-0", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "b015deb37dd917496fa8c677e8192949a88ce711", "ip": "169.155.44.46", "moniker": "Tendermint", "latitude": "33.97997", "longitude": "-118.40630", "isp": "Allnodes Inc", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Allnodes Inc" },
    { "id": "06fecab14326da960249ba3f439cca1eb9269cf5", "ip": "3.87.36.66", "moniker": null, "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "34d879b2c5cbe07cb96ed270b517fbf040bb5d0f", "ip": "168.119.139.155", "moniker": "boromir-1", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "8df6e496ed723f8b94852c6b159cc8098900b57a", "ip": "3.239.69.242", "moniker": null, "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "8d83801176e756ca14e7eb53d0a0f1e2902e1224", "ip": "116.202.129.175", "moniker": "GetSome", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "ac5860fb15d2b006432503f1dbe2d04cbcd08d77", "ip": "195.189.96.111", "moniker": "chorus_sentry36", "latitude": "52.37018", "longitude": "4.87324", "isp": "UAB Cherry Servers", "countryFlag": "https://ipgeolocation.io/static/flags/nl_64.png", "countryName": "Netherlands", "continentName": "Europe", "organization": "UAB Cherry Servers" },
    { "id": "3bc5bbcc72daa011c028a829b49ea480cc469e2f", "ip": "51.81.243.87", "moniker": null, "latitude": "45.53393", "longitude": "-122.95657", "isp": "OVH US LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "13dbf5d5197a7fa4fe069df6269ab46f9305e126", "ip": "54.199.245.69", "moniker": "yyy", "latitude": "35.67619", "longitude": "139.65031", "isp": "Amazon Technologies Inc. (EC2)", "countryFlag": "https://ipgeolocation.io/static/flags/jp_64.png", "countryName": "Japan", "continentName": "Asia", "organization": "Amazon.com, Inc." },
    { "id": "7062c01d579f1a8bfa97bf977561beba96882408", "ip": "65.108.64.98", "moniker": "Tendermint", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "1b35cbab326f664ff34542517702ba6e510caf44", "ip": "74.118.136.145", "moniker": "cbdvcqq33utthf9guhr0", "latitude": "52.36993", "longitude": "4.90788", "isp": "TeraSwitch Networks Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/nl_64.png", "countryName": "Netherlands", "continentName": "Europe", "organization": "TeraSwitch Networks Inc." },
    { "id": "89f5a70965ce6b7c4e449bd359e4516f11b64459", "ip": "93.159.134.156", "moniker": "89uji2dwfer2", "latitude": "50.07343", "longitude": "19.91540", "isp": "Connected sp. z o. o.", "countryFlag": "https://ipgeolocation.io/static/flags/pl_64.png", "countryName": "Poland", "continentName": "Europe", "organization": "Connected sp. z o. o." },
    { "id": "208107dc1275bcdcd824015b127084aa9446c620", "ip": "65.108.10.49", "moniker": "Validator.run", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "6f0ddc87635827665428b3209f4d249300d9cfb1", "ip": "74.118.136.146", "moniker": null, "latitude": "52.36993", "longitude": "4.90788", "isp": "TeraSwitch Networks Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/nl_64.png", "countryName": "Netherlands", "continentName": "Europe", "organization": "TeraSwitch Networks Inc." },
    { "id": "6878a63d051150ca19e1649b85a16b934d098959", "ip": "44.204.253.67", "moniker": "evmos-clust-ar-mainnet", "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "e038b059b25bc0d094c266b0072b8b47e6987a53", "ip": "3.110.156.9", "moniker": null, "latitude": "19.14045", "longitude": "72.88235", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/in_64.png", "countryName": "India", "continentName": "Asia", "organization": "Amazon.com, Inc." },
    { "id": "ebc272824924ea1a27ea3183dd0b9ba713494f83", "ip": "178.211.139.77", "moniker": "zo2xxfjmjqo8", "latitude": "52.19573", "longitude": "20.99210", "isp": "MEVSPACE sp. z o.o.", "countryFlag": "https://ipgeolocation.io/static/flags/pl_64.png", "countryName": "Poland", "continentName": "Europe", "organization": "MEVSPACE sp. z o.o." },
    { "id": "b7f783ed23fb78e4621deff82f1af219342c5b16", "ip": "54.224.37.135", "moniker": "evmos-clust-ar-mainnet", "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "19e9950eda2ce9e56b47f06567f46b562d7a31ba", "ip": "65.109.26.40", "moniker": "test", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "3e5fa9fdba7aae8bccb77b95589a9e5b699af57c", "ip": "185.111.159.234", "moniker": "Validatus", "latitude": "48.10425", "longitude": "11.60102", "isp": "Contabo GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Contabo GmbH" },
    { "id": "717c7a08cda6b3962921f5230c093a6fec61ddbb", "ip": "147.135.65.173", "moniker": "9E0C5861E73D03268308052CF1BDED6A", "latitude": "38.95579", "longitude": "-77.36463", "isp": "OVH US LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "d8b157a87c4c03eecaa146b38e722033bd973ce7", "ip": "89.245.24.76", "moniker": "windpowerstake22", "latitude": "51.53055", "longitude": "7.52230", "isp": "1&1 Versatel Deutschland GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "1&1 Versatel Deutschland GmbH" },
    { "id": "d3abb4dbf82f5f0d1d30f0706c077b2a90379601", "ip": "178.63.86.221", "moniker": "90053a4d-778d-4365-8510-339e7ba0a656", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "c124ce0b508e8b9ed1c5b6957f362225659b5343", "ip": "169.155.44.132", "moniker": null, "latitude": "33.97997", "longitude": "-118.40630", "isp": "Allnodes Inc", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Allnodes Inc" },
    { "id": "28e54b669616966d40399645f364705846c23e32", "ip": "51.83.96.150", "moniker": "node-1", "latitude": "50.69224", "longitude": "3.20003", "isp": "OVH SAS", "countryFlag": "https://ipgeolocation.io/static/flags/fr_64.png", "countryName": "France", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "6cb6a6b567b6e39360a5f27c01a297149e18c22a", "ip": "65.108.227.181", "moniker": "dkj3mr", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "81fd576ee21378fcd4edb10c733a24e6ac6beb58", "ip": "3.83.254.51", "moniker": "evmos-clust-ar-mainnet", "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "aca1d73b509a14af5cad7642f4dee873f9025711", "ip": "35.170.69.44", "moniker": "evmos-clust-ar-mainnet", "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "d8e47d5338175aec6c5a5998f61c8de034b45cf1", "ip": "142.132.159.185", "moniker": "KalpaTech", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "1ad14ec9cb880005257d156eb201e9b65dcbc775", "ip": "54.237.78.233", "moniker": "evmos-clust-ar-mainnet", "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "c88155315e46f8daffd0f889c608cfa60a74f7ce", "ip": "35.195.42.129", "moniker": null, "latitude": "37.42240", "longitude": "-122.08421", "isp": "Google LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Google LLC" },
    { "id": "8276d7d4706ad269547bf98af6a9645f6e8d0a25", "ip": "54.224.247.19", "moniker": null, "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "22e06175a263884104844cbc964c396e5f60d61e", "ip": "206.72.198.78", "moniker": "evmos02pcore", "latitude": "40.78606", "longitude": "-74.07444", "isp": "Interserver, Inc", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Interserver, Inc" },
    { "id": "944f85c7fd7540124c6cff0496e0805010b141db", "ip": "5.9.102.204", "moniker": "AkinoNode", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "b7c4643cb357e03437eb1ab3f5b420b29bbc104d", "ip": "15.204.140.216", "moniker": "test", "latitude": "38.95579", "longitude": "-77.36463", "isp": "OVH US LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "1629de385bf581bc2e21f280e0d7bc7fa4cf035a", "ip": "5.9.106.102", "moniker": null, "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "d52bccca3d420d139775892a005495b47f5390ca", "ip": "142.132.193.194", "moniker": "ECO Stake", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "de2c5e946e21360d4ffa3885579fa038a7d9776e", "ip": "46.101.148.190", "moniker": "pub-node-2", "latitude": "50.11208", "longitude": "8.68341", "isp": "DigitalOcean, LLC", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "DigitalOcean, LLC" },
    { "id": "c98aa48b4ca636efeb9da1e9da5a251f3b3322ee", "ip": "188.40.127.38", "moniker": "localmainnet", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "8a0efdef498a056be4e2f0b72446276306c3a2cd", "ip": "54.161.156.75", "moniker": null, "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "86eda31ce6ce34e63af5c424cdf7aedf06d1b3ac", "ip": "135.181.139.123", "moniker": "Stakecito", "latitude": "60.16940", "longitude": "24.93553", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "97b7bb2eaf05e24a16b24d179d2cd6ce6dffbe16", "ip": "78.46.37.162", "moniker": "qubelabs", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "87eed410c5eeefc72b8f4848de35477cfb93339d", "ip": "65.108.121.133", "moniker": "test", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "1a7bee67d6337d09380b824b952872bdc5dca86f", "ip": "38.242.194.56", "moniker": "KalpaTech", "latitude": "51.18739", "longitude": "6.86733", "isp": "Contabo GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Cogent Communications" },
    { "id": "77debc9fb4baa3ac37d1df2d45e6c3dd8e48e09b", "ip": "65.109.25.139", "moniker": "MCB", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "6efc68b5e07afb8f5d7606dd83c07c841b6f9d5a", "ip": "65.21.202.154", "moniker": "Vanlee", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "f4bf57c116cb7324407d6ed1a9db485380de361d", "ip": "151.106.40.38", "moniker": "airdropdao", "latitude": "50.70226", "longitude": "3.16834", "isp": "Host Europe GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fr_64.png", "countryName": "France", "continentName": "Europe", "organization": "Host Europe GmbH" },
    { "id": "7f06ec15a94c2b0f99b4f74331e2fcae51c20a1c", "ip": "69.46.15.82", "moniker": "node", "latitude": "27.98650", "longitude": "-82.51344", "isp": "HIVELOCITY, Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "HIVELOCITY, Inc." },
    { "id": "d3620b3809f5901ce6690bdbc3790ea474030c77", "ip": "138.201.32.103", "moniker": "MCB", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "641c6bac47a420e47c950b4ace03956b29476a21", "ip": "135.181.73.170", "moniker": "PRO-NODES", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "5d0e9415713d97da8d37f0d068cdf3040bd9846d", "ip": "67.209.54.38", "moniker": "monki", "latitude": "1.27857", "longitude": "103.85762", "isp": "TeraSwitch Networks Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/sg_64.png", "countryName": "Singapore", "continentName": "Asia", "organization": "TeraSwitch Networks Inc." },
    { "id": "6679307f393c650e097e09270ef7a3ad705ae9a3", "ip": "34.89.35.229", "moniker": "SWU", "latitude": "37.42240", "longitude": "-122.08421", "isp": "Google LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Google LLC" },
    { "id": "38d6b22a4188da94514cf8ee018cc922172301c7", "ip": "135.181.56.25", "moniker": "Tendermint", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "5bf629dce1c58015f14334c8b5f985f16656eb03", "ip": "23.88.69.22", "moniker": "Allnodes", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "cf6b8f0ce650a1ad8dd2a18a3f610410b23fd9d2", "ip": "138.201.85.176", "moniker": null, "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "e5ca9549ccc073843840f453f6d331c5081eb679", "ip": "173.215.85.171", "moniker": "NosNode", "latitude": "41.64098", "longitude": "-93.77534", "isp": "MI FIBER URBANDALE", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Aureon Network Services" },
    { "id": "ed065990ad0b772fc78a14956dcfb4e56afa1bcf", "ip": "88.208.227.112", "moniker": "SmartStakeBackup", "latitude": "51.86132", "longitude": "-2.25053", "isp": "Fasthosts Internet Limited", "countryFlag": "https://ipgeolocation.io/static/flags/gb_64.png", "countryName": "United Kingdom", "continentName": "Europe", "organization": "IONOS SE" },
    { "id": "850a5c8af7e21d196fb6e12b063292ca053a8594", "ip": "65.21.72.115", "moniker": "restake", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "bd1425ea168f51e10b836b6fa8502078d08d4ada", "ip": "135.181.5.219", "moniker": "hello-evmos-main", "latitude": "60.16940", "longitude": "24.93553", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "d0807e5d8cb70bea5baf0c88e988a5d3ab95944a", "ip": "15.235.86.146", "moniker": "bwarelabs-archive-1", "latitude": "45.50254", "longitude": "-73.57160", "isp": "OVH Hosting, Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/ca_64.png", "countryName": "Canada", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "39344fdbb6363ac04fa7740107d52139dc1a4cec", "ip": "5.9.81.187", "moniker": "alkfn", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "e1994db78f58a6fa63d2259b4c75783ccbd43844", "ip": "65.108.79.198", "moniker": "StakeLab", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "f842ac52c071d6aaf333231a5901e19d50610526", "ip": "20.238.81.178", "moniker": null, "latitude": "53.34807", "longitude": "-6.24827", "isp": "MICROSOFT-CORP-MSN-AS-BLOCK", "countryFlag": "https://ipgeolocation.io/static/flags/ie_64.png", "countryName": "Ireland", "continentName": "Europe", "organization": "MICROSOFT-CORP-MSN-AS-BLOCK" },
    { "id": "65e1b25f46ebb1a42b80d4bd875e2f326f87c12d", "ip": "95.217.88.61", "moniker": null, "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "ac38a25fddf36a8564d7a0c914ca724f515eef82", "ip": "15.235.53.91", "moniker": "b-evmos-relayer", "latitude": "45.50254", "longitude": "-73.57160", "isp": "OVH Hosting, Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/ca_64.png", "countryName": "Canada", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "0a056dd6f974e43ffc05b42ddb1f4eede99cb851", "ip": "46.4.91.49", "moniker": "alkfn", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "ac9488ad39ed1a47532ad50eef145464bab993b3", "ip": "95.217.76.90", "moniker": null, "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "1b6ca90ac2684b8e5215fad0e57c800104c38b55", "ip": "44.204.119.140", "moniker": null, "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "a0bf476def8f9b42417912d9eb84209b568c8f8d", "ip": "65.108.129.165", "moniker": "evmos-node", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "ecdcbbf76eb8be12689ca6696ac5e7ea762d7301", "ip": "204.16.241.16", "moniker": null, "latitude": "40.48247", "longitude": "-80.03640", "isp": "TeraSwitch Networks Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "TeraSwitch Networks Inc." },
    { "id": "5b03fb91d5373312d845575671c7bd2d86593f5f", "ip": "65.109.35.90", "moniker": "test", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "f3ec4d7d17325509cb44addc47bd8b59b00c674a", "ip": "23.88.73.81", "moniker": "Wetez", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "d76cae129069fb8cf7ea15735ee102b88855ffe7", "ip": "192.99.63.196", "moniker": "2fa3d073-b636-4bab-8d87-bce5c5e9260e", "latitude": "45.50254", "longitude": "-73.57160", "isp": "OVH Hosting, Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/ca_64.png", "countryName": "Canada", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "5cfaee964d5dec277bb377d77cbe2ecd7ca29c7e", "ip": "138.201.203.134", "moniker": null, "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "3bde211c037664fdffce2d03cf8d4325ef3d7c5e", "ip": "65.108.231.60", "moniker": null, "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "4e66d50c3449fdedbe56a51e7065778fc89213de", "ip": "178.63.74.30", "moniker": "interop", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "4ba8c28640d974c079fac73d363a2495b26bdbf8", "ip": "3.250.201.243", "moniker": "GalaxyDigital-1", "latitude": "53.34807", "longitude": "-6.24827", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/ie_64.png", "countryName": "Ireland", "continentName": "Europe", "organization": "Amazon.com, Inc." },
    { "id": "91e220fd528df95112ccefb311f008a4711664f4", "ip": "93.189.30.107", "moniker": "dankuzone_w_daic", "latitude": "48.24420", "longitude": "16.35730", "isp": "IPAX OG", "countryFlag": "https://ipgeolocation.io/static/flags/at_64.png", "countryName": "Austria", "continentName": "Europe", "organization": "IPAX OG" },
    { "id": "9e1ce4e749de5aeca7e54a88d3baf274d31e8a63", "ip": "57.128.45.40", "moniker": null, "latitude": "50.98721", "longitude": "2.12054", "isp": "OVH SAS", "countryFlag": "https://ipgeolocation.io/static/flags/fr_64.png", "countryName": "France", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "79a0dc3d19ae5be09ce2cd1b8018fc27a978d156", "ip": "5.9.107.249", "moniker": "ALTER | PATHROCKNETWORK", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "c994d0b1c10222a936b4285a674c5b4db06adeda", "ip": "169.155.169.83", "moniker": "Tendermint", "latitude": "33.97997", "longitude": "-118.40630", "isp": "Allnodes Inc", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Allnodes Inc" },
    { "id": "22662a0eb0b484dce5b5c4c4c13511b3e671e178", "ip": "68.183.181.120", "moniker": "witval", "latitude": "1.32127", "longitude": "103.69564", "isp": "DigitalOcean, LLC", "countryFlag": "https://ipgeolocation.io/static/flags/sg_64.png", "countryName": "Singapore", "continentName": "Asia", "organization": "DigitalOcean, LLC" },
    { "id": "df810a487725520c7a3153ff733cd70fa6c81323", "ip": "24.5.135.217", "moniker": "ph01", "latitude": "37.19209", "longitude": "-122.11168", "isp": "Comcast", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Comcast Cable Communications, LLC" },
    { "id": "3742ff16bf95c49fcec4eb9cbbef01d98b5759c2", "ip": "136.243.40.41", "moniker": "ECO Stake", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "66e74feb43696e6a9a00eee340a34a81bce1f655", "ip": "141.95.84.102", "moniker": null, "latitude": "50.38823", "longitude": "8.07392", "isp": "OVH GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "6e6a4633e0d1c4652b6f51f258d8497ad5eb1a94", "ip": "49.12.176.135", "moniker": "windpowerstakex5", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "ed4f91fe549ab5ed382c8bf3c6c65513ca201668", "ip": "54.174.248.54", "moniker": "evmos-clust-ar-mainnet", "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "69696b704be0ff2ebd244412aba334177a51b683", "ip": "141.94.242.36", "moniker": "evmosianic", "latitude": "50.69343", "longitude": "3.19983", "isp": "OVH SAS", "countryFlag": "https://ipgeolocation.io/static/flags/fr_64.png", "countryName": "France", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "b212d5740b2e11e54f56b072dc13b6134650cfb5", "ip": "169.155.45.140", "moniker": "Tendermint", "latitude": "33.97997", "longitude": "-118.40630", "isp": "Allnodes Inc", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Allnodes Inc" },
    { "id": "06ba2a7b8f1f791cfeb580e0d0eb6f42d45f3fc8", "ip": "145.239.2.52", "moniker": "282ab871-968a-4fae-b574-3bf79f3aab37", "latitude": "50.38823", "longitude": "8.07392", "isp": "OVH GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "69e6ff165ed2ab729c7c1b11ea0b9dc9b1dfb0a5", "ip": "135.125.5.34", "moniker": "rpc-1.evmos.nodes.guru", "latitude": "48.57489", "longitude": "7.75417", "isp": "OVH SAS", "countryFlag": "https://ipgeolocation.io/static/flags/fr_64.png", "countryName": "France", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "9efb22840e7582d1b5865ada49b7ec646a581c73", "ip": "89.58.30.185", "moniker": null, "latitude": "49.02907", "longitude": "8.35704", "isp": "netcup GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "netcup GmbH" },
    { "id": "81e0b70663f2b00a9b6d0e86c20cd0d09a1209dc", "ip": "185.16.39.151", "moniker": null, "latitude": "52.19573", "longitude": "20.99210", "isp": "MEVSPACE sp. z o.o.", "countryFlag": "https://ipgeolocation.io/static/flags/pl_64.png", "countryName": "Poland", "continentName": "Europe", "organization": "MEVSPACE sp. z o.o." },
    { "id": "98f952e99b04c77e711ee4a40efaa73a10fe35fb", "ip": "141.98.219.41", "moniker": null, "latitude": "40.48247", "longitude": "-80.03640", "isp": "TeraSwitch Networks Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "TeraSwitch Networks Inc." },
    { "id": "c7beb333f8baa388e67026cbb2441c54c7571b52", "ip": "162.19.136.115", "moniker": null, "latitude": "50.38823", "longitude": "8.07392", "isp": "OVH GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "dce9103cf82deed98e51e51816bc02db2cc74bd8", "ip": "141.95.155.224", "moniker": "5c3e4c21340be41004ce097f5617a7ff", "latitude": "50.98721", "longitude": "2.12054", "isp": "OVH SAS", "countryFlag": "https://ipgeolocation.io/static/flags/fr_64.png", "countryName": "France", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "ca9acedbc59db2ec76ccefa2a855c2236cdf7bb9", "ip": "44.200.16.205", "moniker": null, "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "0cd829f7ef114d0cf9195a40e66d98ab292c2b9c", "ip": "142.132.156.243", "moniker": "2xStake.com", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "97e4468ac589eac505a800411c635b14511a61bb", "ip": "5.9.239.236", "moniker": null, "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "88709f402387350e6b62fd7ba61653fb3e44bae4", "ip": "65.108.101.158", "moniker": "Validatrium-rpc-node", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "85c3ea9adade9a24d38eed9cbf9825007d7fa1de", "ip": "65.109.48.230", "moniker": "evmos", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "2db6e66dbc29bec302236957d43422f55cc2620b", "ip": "65.108.106.172", "moniker": "bilbo", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "2f3c3c56a98e41a1365dee5ebd0a4d357efdac1e", "ip": "209.250.239.205", "moniker": "evmos", "latitude": "50.12000", "longitude": "8.73340", "isp": "Vultr Holdings LLC Frankfurt", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "The Constant Company, LLC" },
    { "id": "89e5d89655b444f139f0a27cade081709c4ea11e", "ip": "65.108.71.166", "moniker": "d775e9aa-1f4b-4629-83e7-9a2301ddeb75", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "28644f5cc630a32e2a5407a51b6eaa20e8c562aa", "ip": "65.108.120.3", "moniker": "jabbey", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "50030b4e05c7cb00104e57550bb2fd22173c6d20", "ip": "167.235.2.68", "moniker": null, "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "8a5288b84f8a3b70215609da5e3b2da28c3886da", "ip": "45.132.244.177", "moniker": null, "latitude": "49.02907", "longitude": "8.35704", "isp": "netcup GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "netcup GmbH" },
    { "id": "a73e6313929958d7c6c2e73b69f15d8166bf19c3", "ip": "65.108.226.18", "moniker": "12p4jlkdaj", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "2a7e87599053824c65734d8ed7338c5227759b64", "ip": "65.108.98.123", "moniker": "MMS", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "1e4ac60eb90bf318c30810973ba06511957be84d", "ip": "54.76.203.21", "moniker": null, "latitude": "53.34807", "longitude": "-6.24827", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/ie_64.png", "countryName": "Ireland", "continentName": "Europe", "organization": "Amazon.com, Inc." },
    { "id": "dcefe58ac9c7d880230d08815ea3d6cd0215c3e2", "ip": "195.201.198.231", "moniker": "Tendermint", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "7e54596ccf940d8dee7e3b554e23b993e6736b4a", "ip": "44.211.76.160", "moniker": "evmos-clust-ar-mainnet", "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "3db31759ebc801911f62f676d0f34f6539c8a5db", "ip": "162.55.88.232", "moniker": "MyEvmos", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "334abcfe38b4f8602750973d3c2c4874dedbb8d3", "ip": "162.19.136.124", "moniker": "pokt-1", "latitude": "50.38823", "longitude": "8.07392", "isp": "OVH GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "3e2ad33ab8f19e699568aa483e0111101d845638", "ip": "142.132.214.190", "moniker": "blockscape", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "594dec14d75bdd98a86ad838db4cb97bb3fd7cbf", "ip": "35.237.82.161", "moniker": "node", "latitude": "37.42240", "longitude": "-122.08421", "isp": "Google LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Google LLC" },
    { "id": "5865006494270e4ac5da36af710fcfe90125e125", "ip": "78.110.161.68", "moniker": null, "latitude": "52.39785", "longitude": "-1.58033", "isp": "UK Dedicated Servers Limited", "countryFlag": "https://ipgeolocation.io/static/flags/gb_64.png", "countryName": "United Kingdom", "continentName": "Europe", "organization": "UK Dedicated Servers Limited" },
    { "id": "7b94c26b1e1dace299cd25e8d826c42a6cbca536", "ip": "18.214.26.60", "moniker": "evmos-clust-ar-mainnet", "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "8c1912d693f005e839eacaa1f9be107d8cf9443a", "ip": "165.22.203.215", "moniker": "Blockchain at Berkeley", "latitude": "52.35384", "longitude": "4.96009", "isp": "DigitalOcean, LLC", "countryFlag": "https://ipgeolocation.io/static/flags/nl_64.png", "countryName": "Netherlands", "continentName": "Europe", "organization": "DigitalOcean, LLC" },
    { "id": "fc86e7e75c5d2e4699535e1b1bec98ae55b16826", "ip": "34.145.93.119", "moniker": "bd-evmos-mainnet-seed-node-02", "latitude": "37.42240", "longitude": "-122.08421", "isp": "Google LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Google LLC" },
    { "id": "ff1778dd3efe597033671780db55ed0508971921", "ip": "5.9.138.213", "moniker": "bwarelabs-archive-0", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "bf2b2c9bd100c09400aea35408c2979eb362d119", "ip": "65.21.229.33", "moniker": "Lex_Prime", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "36ab5066cf0ba5aa39991bed92c69e61d2e0add3", "ip": "65.21.227.95", "moniker": "ibc", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "8a5288b84f8a3b70215609da5e3b2da28c3886da", "ip": "157.90.213.21", "moniker": "Validatus", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "6a9826d3869ac82213d0e20d646558648b5fa66e", "ip": "34.207.123.192", "moniker": null, "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "af62bbb6888e6812ea2053347ff8065176d973c5", "ip": "157.230.61.72", "moniker": null, "latitude": "40.79301", "longitude": "-74.02038", "isp": "DigitalOcean, LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "DigitalOcean, LLC" },
    { "id": "62eeec8992fdb4465079981e3d3aaaa7e16e1845", "ip": "94.130.222.114", "moniker": "test5", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "46821aa06600a0956c97a7aedbc3a929fd2d698c", "ip": "122.10.159.26", "moniker": "w64646", "latitude": "35.68408", "longitude": "139.80885", "isp": "Zenlayer Inc", "countryFlag": "https://ipgeolocation.io/static/flags/jp_64.png", "countryName": "Japan", "continentName": "Asia", "organization": "Zenlayer Inc" },
    { "id": "b757cdba8cc96b7290dc65d979e15fa6d12bbaf5", "ip": "146.59.85.220", "moniker": "IBCC DAO", "latitude": "52.22552", "longitude": "21.04974", "isp": "OVH Sp. z o. o.", "countryFlag": "https://ipgeolocation.io/static/flags/pl_64.png", "countryName": "Poland", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "1915b0217865b968646768e2761a8669d5e24bd5", "ip": "65.108.44.149", "moniker": null, "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "992bb92318a7e2cea003d602ef436094b977fecc", "ip": "142.132.198.47", "moniker": null, "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "4086e80acd782fde08849f257144426ba2db058b", "ip": "95.216.2.90", "moniker": "sentryA", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "41a695fdd1c0bc6923d136d1eb5a71673dc168b3", "ip": "173.234.17.196", "moniker": "ryzen-3", "latitude": "51.51492", "longitude": "-0.08238", "isp": "LEASEWEB UK LIMITED", "countryFlag": "https://ipgeolocation.io/static/flags/gb_64.png", "countryName": "United Kingdom", "continentName": "Europe", "organization": "LEASEWEB UK LIMITED" },
    { "id": "83c94142b93ee88ea143edb608789bd6c635c84c", "ip": "185.207.250.46", "moniker": "KalpaTech", "latitude": "48.10425", "longitude": "11.60102", "isp": "Contabo GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Contabo GmbH" },
    { "id": "425b9969981dd38bbc53c0f3f01593fdca631667", "ip": "65.108.131.174", "moniker": "hello-evmos-relayer", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "0ea549e4fc360b5252e15bfe34c98ea3154d244f", "ip": "65.108.0.165", "moniker": "hello-evmos-backup", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "e30795f965a7e622b1207618e5bafbb6aa385b50", "ip": "136.243.199.113", "moniker": "upnode.org", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "804f50132cfb664551d6544bb49762b78391df6d", "ip": "51.158.156.86", "moniker": null, "latitude": "52.36836", "longitude": "5.20722", "isp": "Scaleway", "countryFlag": "https://ipgeolocation.io/static/flags/nl_64.png", "countryName": "Netherlands", "continentName": "Europe", "organization": "ONLINE S.A.S." },
    { "id": "cbe46b04c4a0a4e1a8d68d77ec0b894ca2b8458b", "ip": "93.92.132.73", "moniker": "blockscape", "latitude": "53.54794", "longitude": "10.04297", "isp": "ScaleUp Technologies GmbH & Co. KG", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "ScaleUp Technologies GmbH & Co. KG" },
    { "id": "2d68f10325b45215311178801509fce231160a90", "ip": "65.108.1.46", "moniker": "node-h10", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "be4ab9b1689e3a0012ce04d67103a7458a34f7b5", "ip": "145.239.9.99", "moniker": "node-1", "latitude": "50.69224", "longitude": "3.20003", "isp": "OVH SAS", "countryFlag": "https://ipgeolocation.io/static/flags/fr_64.png", "countryName": "France", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "6ddadc8c25a6e9d57a8aefd728cf70681deb3b53", "ip": "65.109.18.251", "moniker": "NodeOf Cosmonauts - Evmos", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "0f75f9ee5c5cfa675ca652a79d99139f48f4aa4d", "ip": "65.109.94.221", "moniker": "romanv1812", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "471518432477e31ea348af246c0b54095d41352c", "ip": "88.198.131.124", "moniker": null, "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "2b35a42c54ec562fc1a54e29d8cb7f8818567f69", "ip": "67.209.54.93", "moniker": "sgp1evmos08core", "latitude": "1.27857", "longitude": "103.85762", "isp": "TeraSwitch Networks Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/sg_64.png", "countryName": "Singapore", "continentName": "Asia", "organization": "TeraSwitch Networks Inc." },
    { "id": "a903e06e8798fa4a9aecfe755b2e3e476ee00ace", "ip": "94.130.36.120", "moniker": "Crypto Lion", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "50ce5537afa78216741c1a283b253a895f2869dd", "ip": "149.202.72.176", "moniker": "node-1", "latitude": "50.69224", "longitude": "3.20003", "isp": "OVH SAS", "countryFlag": "https://ipgeolocation.io/static/flags/fr_64.png", "countryName": "France", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "fd569890721157a9ac4312c68bda4594dc74d109", "ip": "65.108.106.241", "moniker": "evmos-restake-bada", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "b406094a8d5ed6eafe4bedfef7c44693f8e82234", "ip": "154.53.39.71", "moniker": null, "latitude": "38.63273", "longitude": "-90.19624", "isp": "Contabo Inc", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Cogent Communications" },
    { "id": "7323686f7146a4e858db0d582dcbe050d7469d71", "ip": "142.132.197.103", "moniker": "Wetez-rpc2", "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "d3d7a0c107994c7388a075b5bef1cfe0dc672cb3", "ip": "65.21.90.137", "moniker": null, "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "0247eb1196556c00bd9f24a246c8df4ce44c00b0", "ip": "213.227.164.85", "moniker": "Interbloc", "latitude": "48.19471", "longitude": "16.35046", "isp": "ANEXIA Internetdienstleistungs GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/at_64.png", "countryName": "Austria", "continentName": "Europe", "organization": "ANEXIA Internetdienstleistungs GmbH" },
    { "id": "8387c488d769e70ca90d218f8027caf444969916", "ip": "144.76.118.136", "moniker": "Ubuntu-2004-focal-64-minimal", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "181ffa1d33cef4e26b672defafb1cda1b43de6d4", "ip": "195.3.221.10", "moniker": "m29km8cbsfp0", "latitude": "52.23560", "longitude": "21.01037", "isp": "Innowacyjne Rozwiazania Informatyczne.pl sp. z o.o.", "countryFlag": "https://ipgeolocation.io/static/flags/pl_64.png", "countryName": "Poland", "continentName": "Europe", "organization": "MEVSPACE sp. z o.o." },
    { "id": "5eaaa1d6a7ff68c40c0b74ad466eae524043aff2", "ip": "65.108.69.121", "moniker": "Tendermint", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "fc6ae8f88e635ffacccb187c52a3d431d2b41e52", "ip": "74.96.207.61", "moniker": null, "latitude": "38.89037", "longitude": "-77.03196", "isp": "Verizon Business", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Verizon Business" },
    { "id": "39b96fa4d7db2a7adf0ad799d6b1c3a3883e5301", "ip": "157.90.169.110", "moniker": null, "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "22e06175a263884104844cbc964c396e5f60d61e", "ip": "66.45.236.82", "moniker": "evmos02pcore", "latitude": "40.78606", "longitude": "-74.07444", "isp": "Interserver, Inc", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Interserver, Inc" },
    { "id": "3d3f642f961c6ec867685fe1ecb04b0f1e616049", "ip": "142.93.215.56", "moniker": "KingSuper", "latitude": "12.84541", "longitude": "77.66434", "isp": "DigitalOcean, LLC", "countryFlag": "https://ipgeolocation.io/static/flags/in_64.png", "countryName": "India", "continentName": "Asia", "organization": "DigitalOcean, LLC" },
    { "id": "1f78076e068b4726314a834851e05c1d46e7204a", "ip": "198.244.165.175", "moniker": "cros-nest", "latitude": "51.48588", "longitude": "0.18357", "isp": "OVH Ltd", "countryFlag": "https://ipgeolocation.io/static/flags/gb_64.png", "countryName": "United Kingdom", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "d6611ecd394ff7948dbe1aeeb539be05f825c530", "ip": "188.166.99.192", "moniker": null, "latitude": "52.35384", "longitude": "4.96009", "isp": "DigitalOcean, LLC", "countryFlag": "https://ipgeolocation.io/static/flags/nl_64.png", "countryName": "Netherlands", "continentName": "Europe", "organization": "DigitalOcean, LLC" },
    { "id": "104e98c20c131668564d10ad64dc52bb230f18f6", "ip": "65.21.127.197", "moniker": "Tendermint", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "779fb316568513b3d136b8aca2f8070855eb227e", "ip": "65.109.18.166", "moniker": "rpc-1.testnet.evmos.nodes.guru", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "6047f6c52c556bf9f0c48630eee5ab793615f1b5", "ip": "67.209.54.83", "moniker": "sgp1evmos07core", "latitude": "1.27857", "longitude": "103.85762", "isp": "TeraSwitch Networks Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/sg_64.png", "countryName": "Singapore", "continentName": "Asia", "organization": "TeraSwitch Networks Inc." },
    { "id": "f301d91fa04bf8053547fc269faf364d159d7b0b", "ip": "65.108.103.184", "moniker": "t0", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "574c79ed75d5df63e7956e67661a9aff958dc7cc", "ip": "74.96.207.58", "moniker": "fpb", "latitude": "38.89037", "longitude": "-77.03196", "isp": "Verizon Business", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Verizon Business" },
    { "id": "26a4ffcf2a710beb1e8840a92219d9207ab8c02b", "ip": "35.196.109.226", "moniker": "node", "latitude": "37.42240", "longitude": "-122.08421", "isp": "Google LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Google LLC" },
    { "id": "9dc2dfecf1ccafa4fb74254894aaa697626a64e0", "ip": "78.141.247.100", "moniker": "SWU", "latitude": "51.52040", "longitude": "-0.07590", "isp": "Vultr Holdings LLC London", "countryFlag": "https://ipgeolocation.io/static/flags/gb_64.png", "countryName": "United Kingdom", "continentName": "Europe", "organization": "The Constant Company, LLC" },
    { "id": "3838eed917034133a8b7c4b0ef493bccae6a1532", "ip": "135.181.215.115", "moniker": null, "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "3e5bf8b2ddf10cca62e603a78b0f84f0f035c8a6", "ip": "195.3.221.21", "moniker": "lavender.evmos.main", "latitude": "52.23560", "longitude": "21.01037", "isp": "Innowacyjne Rozwiazania Informatyczne.pl sp. z o.o.", "countryFlag": "https://ipgeolocation.io/static/flags/pl_64.png", "countryName": "Poland", "continentName": "Europe", "organization": "MEVSPACE sp. z o.o." },
    { "id": "588cedb70fa1d98c14a2f2c1456bfa41e1a156a8", "ip": "2a01:4f9:6b:2512::9539", "moniker": null, "latitude": "49.12483", "longitude": "10.78052", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "28eb72bba54d1f456d7b956acb126df63f78ad7b", "ip": "65.108.134.12", "moniker": "ECO Stake", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "b0f021a272f296e3d9ef9aa8f4a2d76d3792a8bd", "ip": "51.161.86.56", "moniker": "binary_holdings", "latitude": "45.50254", "longitude": "-73.57160", "isp": "OVH Hosting, Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/ca_64.png", "countryName": "Canada", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "e3e11fca4ecf4035a751f3fea90e3a821e274487", "ip": "34.79.44.243", "moniker": "bd-evmos-mainnet-seed-node-01", "latitude": "37.42240", "longitude": "-122.08421", "isp": "Google LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Google LLC" },
    { "id": "e4128e7742b742a1b82d63397ae1ca6574187662", "ip": "138.201.8.248", "moniker": "Allnodes", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "f201e6bd9f86d248a4b83cbe672010eafffdac1c", "ip": "66.172.36.133", "moniker": null, "latitude": "34.04399", "longitude": "-118.42142", "isp": "Creative Technoligies", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Evocative, Inc." },
    { "id": "8ee457383eaae63e874f67b938940f83af60ce75", "ip": "65.108.250.27", "moniker": "xyz", "latitude": "60.17116", "longitude": "24.93265", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/fi_64.png", "countryName": "Finland", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "1ac0b9f0ad5e5507224ef2e3c173c87c49434005", "ip": "51.81.242.168", "moniker": "wee", "latitude": "45.53393", "longitude": "-122.95657", "isp": "OVH US LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "00b90cb811dde35071bd77d644cefdfbee887a43", "ip": "66.172.36.134", "moniker": "node", "latitude": "34.04399", "longitude": "-118.42142", "isp": "Creative Technoligies", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Evocative, Inc." },
    { "id": "5d4a67dac324ca252d22fc92fb4776e1195e34f6", "ip": "141.95.105.60", "moniker": "Node", "latitude": "50.38823", "longitude": "8.07392", "isp": "OVH GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "OVH SAS" },
    { "id": "3662ee5fc3759c2ed67e7bf386d8a03377eb46b8", "ip": "95.214.55.67", "moniker": "carbonZERO-1", "latitude": "52.23560", "longitude": "21.01037", "isp": "MEVSPACE sp. z o.o.", "countryFlag": "https://ipgeolocation.io/static/flags/pl_64.png", "countryName": "Poland", "continentName": "Europe", "organization": "MEVSPACE sp. z o.o." },
    { "id": "6e91c9f077eafc1014e06be6c7e4394bf4b1c960", "ip": "38.242.255.29", "moniker": "POCKET-C0D3R-GW-2022-08-24-21", "latitude": "51.18739", "longitude": "6.86733", "isp": "Contabo GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Cogent Communications" },
    { "id": "b3ec65f24ec3f788b10931ddffb1e912678dc1be", "ip": "5.9.77.5", "moniker": "Ubuntu-2004-focal-64-minimal", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "0d62f64fd433ab92b4f6aebcf1feda983af1aa9b", "ip": "95.214.55.63", "moniker": "7933B3B8040CC144CC0E30C864DC7A7E", "latitude": "52.23560", "longitude": "21.01037", "isp": "MEVSPACE sp. z o.o.", "countryFlag": "https://ipgeolocation.io/static/flags/pl_64.png", "countryName": "Poland", "continentName": "Europe", "organization": "MEVSPACE sp. z o.o." },
    { "id": "6c629819fa12da96172e5501337a2b9b8ca47949", "ip": "15.235.51.191", "moniker": "bf8a141256fc7af617fc145b2aa7d9b5", "latitude": "45.50254", "longitude": "-73.57160", "isp": "OVH Hosting, Inc.", "countryFlag": "https://ipgeolocation.io/static/flags/ca_64.png", "countryName": "Canada", "continentName": "North America", "organization": "OVH SAS" },
    { "id": "1f94a7e6fdbef9e3d2812c4f2920cb478c6f3a2c", "ip": "52.3.235.180", "moniker": "evmos-clust-ar-mainnet", "latitude": "37.54068", "longitude": "-77.43367", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Amazon.com, Inc." },
    { "id": "b6641386f22ebf57c7820b07179a39dd97d7316b", "ip": "5.9.87.216", "moniker": "SWU", "latitude": "50.47914", "longitude": "12.33547", "isp": "Hetzner Online GmbH", "countryFlag": "https://ipgeolocation.io/static/flags/de_64.png", "countryName": "Germany", "continentName": "Europe", "organization": "Hetzner Online GmbH" },
    { "id": "900c8362ea30aa5999443690e4df67c1a53dbd53", "ip": "34.139.176.105", "moniker": null, "latitude": "37.42240", "longitude": "-122.08421", "isp": "Google LLC", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Google LLC" },
    { "id": "8584a017727808e59c451e1955c0f0e666a807b2", "ip": "18.133.220.65", "moniker": "evmos_node", "latitude": "51.50643", "longitude": "-0.12719", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/gb_64.png", "countryName": "United Kingdom", "continentName": "Europe", "organization": "Amazon.com, Inc." },
    { "id": "fc1d8ad92dd1a6522cee9a185d843ffbefd38e55", "ip": "3.249.180.171", "moniker": "GalaxyDigital-0", "latitude": "53.34807", "longitude": "-6.24827", "isp": "Amazon Technologies Inc. (AMAZON)", "countryFlag": "https://ipgeolocation.io/static/flags/ie_64.png", "countryName": "Ireland", "continentName": "Europe", "organization": "Amazon.com, Inc." },
    { "id": "293063c5a6114256ad520b9b45c53acdbe34cd68", "ip": "66.172.36.136", "moniker": "node", "latitude": "34.04399", "longitude": "-118.42142", "isp": "Creative Technoligies", "countryFlag": "https://ipgeolocation.io/static/flags/us_64.png", "countryName": "United States", "continentName": "North America", "organization": "Evocative, Inc." }
]