import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AiOutlineTwitter, AiOutlineGithub } from 'react-icons/ai'
import { BsTelegram } from 'react-icons/bs'
import { Link, useLocation } from 'react-router-dom'
import { chainsData } from '../../data/data'
import { IoMdArrowDropdownCircle, IoMdArrowDropupCircle } from 'react-icons/io'

export default function Header() {

    useEffect(function mount() {
        const header = document.getElementById('header');

        function onScroll() {
          if (this.scrollY >= 100) {
            header.classList.add('scroll-header');
          }
          else {
            header.classList.remove('scroll-header');
          }
        }
        window.addEventListener("scroll", onScroll);

        return function unMount() {
          window.removeEventListener("scroll", onScroll);
        };

      });

    const styles = {
        wrapper: 'px-[1rem] sm:px-[4rem] w-[100vw] fixed top-0 left-0 z-[100] bg-[transparent] duration-300',
        container: 'h-[4rem] flex justify-between place-items-center text-center',
        iconContainer: 'flex place-items-center gap-[1rem] duration-300',
        iconContainerExtra: 'hidden md:flex place-items-center gap-[1rem] duration-300',
        icon: 'text-[1.5rem] text-[#fff] hover:text-[#3956de] cursor-pointer duration-300',
        iconExtra: 'text-[1.5rem] text-[#3956de] hover:text-[#999] cursor-pointer duration-300',
        logoContainer: 'px-4 h-full flex justify-center place-items-center duration-300',
        logo: 'w-[100px] h-[50px]',
        menuContainer: 'block md:hidden',
        menuIcon: 'text-[#3956de] hover:text-[#999] cursor-pointer text-[2rem]',
    }

    const location = useLocation();

    const [menuActive, setMenuActive] = useState(false)

    const openMenu = () => {
        setMenuActive(true)
        document.querySelector('#chainExtra').classList.add('menuActive');
        document.querySelectorAll('#sidebarItemLinks').forEach(item => {
            item.addEventListener('click', () => {
                setMenuActive(false)
                document.querySelector('#chainExtra').classList.remove('menuActive');
            })
        })
    }

    const closeMenu = () => {
        setMenuActive(false)
        document.querySelector('#chainExtra').classList.remove('menuActive');
    }

    return (
        <>
            <div className={styles.wrapper} id='header'>
                <div className={styles.container}>
                    <div className={styles.logoContainer} id='logoContainer'>
                        <Link to="/">
                            <img className={styles.logo} src="/images/logo.png" alt="" />
                        </Link>
                    </div>
                    {
                        location.pathname !== '/' ? (
                            <div className={styles.menuContainer}>
                                {
                                    !menuActive ? (
                                        <IoMdArrowDropdownCircle className={styles.menuIcon} onClick={() => { openMenu() }} />
                                    ) : (
                                        <IoMdArrowDropupCircle className={styles.menuIcon} onClick={() => { closeMenu() }} />
                                    )
                                }
                            </div>
                        ) : <></>
                    }
                    <div className={location.pathname === '/' ? styles.iconContainer : styles.iconContainerExtra}>
                        <a href="https://twitter.com/anurg" target='_blank' rel='noreferrer'>
                            <AiOutlineTwitter className={location.pathname === '/' ? styles.icon : styles.iconExtra} id='socialIcon'/>
                        </a>
                        <a href="https://github.com/anurg" target='_blank' rel='noreferrer'>
                            <AiOutlineGithub className={location.pathname === '/' ? styles.icon : styles.iconExtra} id='socialIcon'/>
                        </a>
                        <a href="https://t.me/+SVC1dGERz7tkNDQ1" target='_blank' rel='noreferrer'>
                            <BsTelegram className={location.pathname === '/' ? styles.icon + ' text-[1.25rem]' : styles.iconExtra + ' text-[1.25rem]'} id='socialIcon'/>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}
