import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { compactNumber } from '../../../../scripts/utils'
import CircularProgress from './CircularProgress'
import CommissionChart from './CommissionChart'
import DecentralizationMap from './DecentralizationMap'
import LeaderBoard from './LeaderBoard'
import MissedBlocksChart from './MissedBlocksChart'
import NodesHostingGraph from './NodesHostingGraph'
import NodesHostingBoard from './NodesHostingBoard'
import PriceChart from './PriceChart'
import VolumeChart from './VolumeChart'
import VotingPowerChart from './VotingPowerChart'
import NodesContinent from './NodesContinent'
import NodesCountry from './NodesCountry'


export default function SummaryHandler({ chain }) {

    const innerStrokeColor_SUCCESS = 'rgba(120, 192, 0, 0.4)';
    const outerStrokeColor_SUCCESS = 'rgba(120, 192, 0, 1)';
    const innerStrokeColor_WARN = 'rgba(255, 193, 7, 0.4)';
    const outerStrokeColor_WARN = 'rgba(255, 193, 7, 1)';
    const innerStrokeColor_DANGER = 'rgba(220, 53, 69, 0.4)';
    const outerStrokeColor_DANGER = 'rgba(220, 53, 69, 1)';

    const extractPrice = (coingekoSummary) => {
        let price = coingekoSummary?.market_data?.current_price?.usd;
        if (!price) {
            return '-';
        }
        return Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumSignificantDigits: 4
        }).format(price);
    }

    const extractPriceUnformated = (coingekoSummary) => {
        let price = coingekoSummary?.market_data?.current_price?.usd;
        if (!price) {
            return '-';
        }
        return price
    }

    const extractBlockTime = (summary) => {
        return Intl.NumberFormat('en-US', {
            maximumFractionDigits: 2,
        }).format(summary?.chain.params.actual_block_time) + 's';
    }

    const extractInflation = (summary) => {
        let inflation = summary?.chain.params.base_inflation;
        if (!inflation) {
            return '-';
        }
        return displayPercent(inflation);
    }

    const displayPercent = (val) => {
        return Intl.NumberFormat('en-US', {
            maximumFractionDigits: 2,
            style: 'percent'
        }).format(val);
    }

    const extractBondedTokens = (chain, summary) => {
        let bondedTokens = summary?.chain.params.bonded_tokens / Math.pow(10, chain.denomPow);
        return bondedTokens
    }

    const extractTotalSupply = (chain, summary) => {
        let totalSupply = summary?.chain.params.total_supply;
        totalSupply = totalSupply / Math.pow(10, chain.denomPow);
        return compactNumber(totalSupply);
    }

    const extractBondedTokensRatio = (chain, summary) => {
        let bondedTokens = 0
        bondedTokens = summary?.chain.params.bonded_ratio * 100;
        return bondedTokens
    }

    const extractTotalVal = (validators) => {
        validators.validators.sort((a, b) => b.rank - a.rank)
        validators.validators.reverse()
        return validators.validators.length
    }

    const extractTotalActive = (validators) => {
        validators.validators.sort((a, b) => b.rank - a.rank)
        validators.validators.reverse()
        let TotalActive = 0;
        for (let i = 0; i < validators.validators.length; i++) {
            let validator = validators.validators[i];
            if (validator.active == true) {
                TotalActive++;
            }
        }
        return TotalActive
    }
    const extractTotalJailed = (validators) => {
        validators.validators.sort((a, b) => b.rank - a.rank)
        validators.validators.reverse()
        let TotalActive = 0;
        for (let i = 0; i < validators.validators.length; i++) {
            let validator = validators.validators[i];
            if (validator.jailed == true) {
                TotalActive++;
            }
        }
        return TotalActive
    }


    const extractAthPriceRatio = (coingekoSummary) => {
        let currentPrice = coingekoSummary?.market_data?.current_price?.usd;
        let athPrice = coingekoSummary?.market_data?.ath?.usd;
        if (!currentPrice || !athPrice) {
            return;
        }
        return +(currentPrice / athPrice * 100).toFixed(2);
    }

    const innerStokeColorForRatio = (ratio, limit1, limit2) => {
        return ratio <= limit1
            ? innerStrokeColor_DANGER
            : ratio <= limit2
                ? innerStrokeColor_WARN
                : innerStrokeColor_SUCCESS;
    }

    const outerStokeColorByRatio = (ratio, limit1, limit2) => {
        return ratio <= limit1
            ? outerStrokeColor_DANGER
            : ratio <= limit2
                ? outerStrokeColor_WARN
                : outerStrokeColor_SUCCESS;
    }


    const extractRank = (validators) => {
        validators.validators.sort((a, b) => b.rank - a.rank)
        validators.validators.reverse()
        let ranking = 0;
        for (let i = 0; i < validators.validators.length; i++) {
            let validator = validators.validators[i];
            if (validator.operator_address == chain?.Valoper) {
                ranking = validator.rank;
            }
        }
        return ranking
    }

    const extractClients = (validators) => {
        validators.validators.sort((a, b) => b.rank - a.rank)
        validators.validators.reverse()
        let clients = 0;
        for (let i = 0; i < validators.validators.length; i++) {
            let validator = validators.validators[i];
            if (validator.operator_address == chain?.Valoper) {
                clients = validator.delegations.total_count;
            }
        }
        return clients
    }

    const extractAssets = (chain, validators) => {
        validators.validators.sort((a, b) => b.rank - a.rank)
        validators.validators.reverse()
        let pow = Math.pow(10, chain.denomPow);
        let _price = priceUnformatted;
        let assets = 0;
        for (let i = 0; i < validators.validators.length; i++) {
            let validator = validators.validators[i];
            if (validator.operator_address == chain?.Valoper) {
                assets = Math.round(validator.tokens) * _price / pow;
            }
        }
        return Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumSignificantDigits: 4
        }).format(assets)
    }

    const extractTokensDistributionRatio = (validators) => {
        validators.validators.sort((a, b) => b.rank - a.rank)
        validators.validators.reverse()
        let totalVotingPower = 0;
        totalVotingPower = summary?.chain.params.bonded_tokens;
        let validatorsNum = 0;
        let tmpVotingPower = 0;
        let percentage = 0;
        let TotalActive = 0;
        for (let i = 0; i < validators.validators.length; i++) {
            let validator = validators.validators[i];
            if (validator.active === true) {
                TotalActive++;
            }
        }
        for (let i = 0; i < validators.validators.length && !percentage; i++) {
            let validator = validators.validators[i];
            if (validator.active === true) {
                tmpVotingPower = tmpVotingPower + Math.round(validator.delegations.total_tokens);
                validatorsNum++;
            }
            if (tmpVotingPower / totalVotingPower * 100 >= 50) {
                percentage = +(validatorsNum / TotalActive * 100).toFixed(2);
            }
        }
        percentage = percentage * 2;
        return percentage;
    }

    const [coinId, setCoinId] = useState(chain.coingekoCoinId || chain.id)


    const [price, setPrice] = useState(null)
    const [priceUnformatted, setPriceUnformatted] = useState(null)
    const [athPriceRatio, setAthPriceRatio] = useState(null)

    useEffect(() => {
        axios.get(`https://api.coingecko.com/api/v3/coins/${coinId}`).then((res) => {
            if (res.status === 200) {
                console.log(extractPrice(res.data))
                console.log(`https://api.coingecko.com/api/v3/coins/${coinId}`)
                setPrice(extractPrice(res.data))
                setPriceUnformatted(extractPriceUnformated(res.data));
                let ratio = extractAthPriceRatio(res.data);
                if (ratio) {
                    setAthPriceRatio({
                        ratio: ratio,
                        innerStrokeColor: innerStokeColorForRatio(ratio, 10, 40),
                        outerStrokeColor: outerStokeColorByRatio(ratio, 10, 40)
                    })
                }
            }
        }).catch((err) => { })
    }, [coinId])

    const [summary, setSummary] = useState()
    const [bondedTokenRatio, setBondedTokenRatio] = useState(null)

    let apiChainId = chain.apiChainId || chain.id;

    useEffect(() => {
        const handleSummary = (summary) => {
            let ratio = extractBondedTokensRatio(chain, summary);
            setBondedTokenRatio({
                ratio: ratio,
                innerStrokeColor: innerStokeColorForRatio(ratio, 10, 25),
                outerStrokeColor: outerStokeColorByRatio(ratio, 10, 25)
            })
            summary.blockTime = extractBlockTime(summary);
            summary.inflation = extractInflation(summary);
            summary.bondedTokens = extractBondedTokens(chain, summary);
            summary.totalSupply = extractTotalSupply(chain, summary);
            setSummary(summary)
        }
        if (coinId === "ixo") {
            axios.get(`https://api-ixo.cosmostation.io/v1/status`).then((res) => {
                if (res.status === 200) {
                    handleSummary(res.data)
                }
            }).catch((err) => { })
        }
        else {
            axios.get(`https://chains.cosmos.directory/${apiChainId}`).then((res) => {
                if (res.status === 200) {
                    handleSummary(res.data)
                }
            }).catch((err) => { })
        }
    }, [coinId, chain])

    const [tokensDistributionRatio, setTokenDistributionRatio] = useState(null);
    const [validatorSet, setValidatorSet] = useState([]);
    const [validatorsData, setValidatorsData] = useState([]);

    useEffect(() => {
        axios.get(`https://validators.cosmos.directory/chains/${apiChainId}`).then((res) => {
            if (res.status === 200) {
                const validators = res.data;
                setValidatorsData(validators);
                let ratio = extractTokensDistributionRatio(validators);
                setTokenDistributionRatio({
                    ratio: ratio,
                    innerStrokeColor: innerStokeColorForRatio(ratio, 10, 25),
                    outerStrokeColor: outerStokeColorByRatio(ratio, 10, 25)
                });
                let active = extractTotalActive(validators);
                let total = extractTotalVal(validators);
                let jailed = extractTotalJailed(validators);
                let rank = extractRank(validators);
                let clients = extractClients(validators);
                let assets = extractAssets(chain, validators);
                setValidatorSet({
                    active: active,
                    total: total,
                    jailed: jailed,
                    rank: rank,
                    clients: clients,
                    assets: assets,
                });
            }
        }).catch((err) => { })
    }, [apiChainId])


    const styles = {
        wrapper: '',
        container: '',
        tilesContainer: 'grid grid-cols-1 md:grid-cols-3 gap-[1rem]',
        tile: 'border-[1px] border-borderColor2 p-4 flex flex-col gap-[1rem] rounded-xl bg-[#fff] shadow-md tileBg',
        titleContainer: '',
        title: 'text-[1.25rem] text-[#fff] font-[500] summaryTileTitle',
        subtitleContainer: 'text-[1.35rem] text-[#fff] text-right font-[600]',
        subtitle: '',
        statusContainer: 'pt-[2rem]',
        statusTitle: 'text-[1.25rem] font-[600] text-center pb-[2rem]'
    }

    const commonList = [
        {
            name: 'Price',
            data: price ? price : '-',
        },
        {
            name: 'Height',
            data: summary?.chain?.height ? summary?.chain.height : '2',
        },
        {
            name: 'Calculated APY',
            data: summary?.chain?.params?.calculated_apr ? summary?.chain.params.calculated_apr.toFixed(2) + '%' : '0.2%',
        },
        {
            name: 'Bonded Tokens',
            data: summary?.chain?.params?.bonded_ratio ? summary?.chain.params.bonded_ratio.toFixed(2) + '%' : '0.2%',
        },
        {
            name: 'Inflation',
            data: summary?.inflation || '-',
        },
        {
            name: 'Block Time',
            data: summary?.blockTime || '-',
        },
        {
            name: 'Total Validators',
            data: validatorSet?.total || '-',
        },
        {
            name: 'Active Validators',
            data: validatorSet?.active || '-',
        },
        {
            name: 'Jailed Validators',
            data: validatorSet?.jailed || '-',
        },
    ]

    const validatorList = [
        {
            name: 'Rank',
            data: validatorSet?.rank && validatorSet?.active ? validatorSet?.rank + ' of ' + validatorSet?.active : '-',
        },
        {
            name: 'Delegators',
            data: validatorSet?.clients ? validatorSet?.clients + ' Clients' : '-',
        },
        {
            name: 'Assets Staked',
            data: validatorSet?.assets ? validatorSet?.assets : '-',
        },
    ]

    const [coingeckoData, setCoingeckoData] = useState([]);

    useEffect(() => {
        axios.get(`https://api.coingecko.com/api/v3/coins/${coinId}/market_chart?vs_currency=usd&days=14&interval=daily`).then((res) => {
            if (res.status === 200) {
                setCoingeckoData(res.data)
            }
        }).catch((err) => {})
    }, [coinId])

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={commonList.filter((e) => e?.data != null && e?.data !== '') ? styles.tilesContainer : 'hidden'}>
                        {
                            commonList.map((item, index) => {
                                return (
                                    <div className={styles.tile} key={index}>
                                        <div className={styles.titleContainer}>
                                            <h3 className={styles.title}>{item.name}</h3>
                                        </div>
                                        <div className={styles.subtitleContainer}>
                                            <p className={styles.subtitle}>{item.data}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <CircularProgress
                        bondedTokenRatio={bondedTokenRatio}
                        tokensDistributionRatio={tokensDistributionRatio}
                        athPriceRatio={athPriceRatio}
                    />
                    <div className={styles.statusContainer}>
                        <h3 className={styles.statusTitle}>Validator Stats</h3>
                        <div className={validatorList.filter((e) => e?.data != null && e?.data !== '') ? styles.tilesContainer : 'hidden'}>
                            {
                                validatorList.map((item, index) => {
                                    return (
                                        <div className={styles.tile} key={index}>
                                            <div className={styles.titleContainer}>
                                                <h3 className={styles.title}>{item.name}</h3>
                                            </div>
                                            <div className={styles.subtitleContainer}>
                                                <p className={styles.subtitle}>{item.data}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <PriceChart coingeckoData={coingeckoData} />
                    <VolumeChart coingeckoData={coingeckoData} />
                    <DecentralizationMap chain={chain} validatorsData={validatorsData} />
                    <NodesHostingGraph chain={chain} validatorsData={validatorsData} />
                    <NodesHostingBoard chain={chain} validatorsData={validatorsData} />
                    <NodesContinent chain={chain} validatorsData={validatorsData} />
                    <NodesCountry chain={chain} validatorsData={validatorsData} />
                    <VotingPowerChart validatorsData={validatorsData} chain={chain} />
                    <CommissionChart validatorsData={validatorsData} />
                    <MissedBlocksChart validatorsData={validatorsData} />
                    <LeaderBoard validatorsData={validatorsData} />
                </div>
            </div>
        </>
    )
}