import React from 'react'
import { HiOutlineArrowSmRight } from 'react-icons/hi'

export default function Banner() {

    const styles = {
        wrapper: 'bg-[#c9c9c9]',
        container: 'flex justify-center place-items-center text-center relative z-[1]',
        contentContainer: 'lg:absolute lg:m-4 z-[2]',
        titleContainer: 'pt-[4rem]',
        title: 'text-[3rem] font-[500] text-[#000] lg:text-[#fff]',
        subtitleContainer: '',
        subtitle: 'text-[#000] lg:text-[#fff]',
        buttonsContainer: 'py-[4rem] flex flex-col md:flex-row gap-[2rem] flex justify-center place-items-center',
        button: 'min-w-[15rem] min-h-[50px] rounded-full text-center flex justify-center place-items-center border-2',
        exploreButton: 'primaryButton duration-300',
        learnMoreButton: 'primaryButton duration-300 flex justify-center place-items-center gap-[0.5rem]',
        logoContainer: 'pt-[1rem] pb-[4rem] flex justify-center place-items-center',
        logo: 'w-[100px] h-[50px]',
        imageContainer: 'overflow-hidden',
        bgImage: 'min-w-[100vw] h-[100vh] hidden lg:block object-cover',
        buttonIcon: 'text-[#fff] text-[1.5rem]'
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.contentContainer}>
                    <div className={styles.titleContainer}>
                        <h1 className={styles.title}>NKB Blocks</h1>
                    </div>
                    <div className={styles.logoContainer}>
                        <img src="/images/logo.png" alt="" className={styles.logo} />
                    </div>
                    <div className={styles.subtitleContainer}>
                        <h5 className={styles.subtitle}>Open Source tool for cosmos based networks</h5>
                    </div>
                    <div className={styles.buttonsContainer}>
                        <a href="#chains" className={styles.button + ' ' + styles.exploreButton}>Explore</a>
                        <a href="#chains" className={styles.button + ' ' + styles.learnMoreButton}>
                            Learn More <HiOutlineArrowSmRight className={styles.buttonIcon}/>
                        </a>
                    </div>
                </div>
                <div className={styles.imageContainer}>
                    <img src="/images/hero-area.jpg" alt="" className={styles.bgImage} />
                </div>
            </div>
        </div>
    )
}
