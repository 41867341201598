import React, { useEffect } from 'react'
import { BsTwitter, BsGithub, BsMedium, BsDiscord } from 'react-icons/bs'
import { FaGlobeAsia, FaCubes } from 'react-icons/fa'
import SummaryHandler from './SummaryHandler'


function Summary({ chain }) {
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const styles = {
        wrapper: '',
        container: '',
        topExtra: 'hidden sm:flex place-items-center flex-col lg:flex-row justify-between pb-[2rem]',
        iconContainer: 'flex gap-[2rem]',
        icon: 'text-[2rem] text-[#3956de] cursor-pointer hover:text-[#999] duration-300',
        topButton: 'lg:pt-0 pt-[2rem]',
        topButtonAnchor: 'primaryButton duration-300 text-[1.25rem] py-4 px-8 cursor-pointer rounded-xl',
    }

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.topExtra}>
                        <div className={styles.iconContainer}>
                            {chain.twitter ?
                                <a className={styles.icon} href={chain.twitter} target='_blank' rel="noreferrer"><BsTwitter /></a>
                                : <></>}
                            {chain.github ?
                                <a className={styles.icon} href={chain.github} target='_blank' rel="noreferrer"><BsGithub /></a>
                                : <></>}
                            {chain.globe ?
                                <a className={styles.icon} href={chain.globe} target='_blank' rel="noreferrer"><FaGlobeAsia /></a>
                                : <></>}
                            {chain.medium ?
                                <a className={styles.icon} href={chain.medium} target='_blank' rel="noreferrer"><BsMedium /></a>
                                : <></>}
                            {chain.discord ?
                                <a className={styles.icon} href={chain.discord} target='_blank' rel="noreferrer"><BsDiscord /></a>
                                : <></>}
                            {chain.blockExplorer ?
                                <a className={styles.icon} href={chain.blockExplorer} target='_blank' rel="noreferrer"><FaCubes /></a>
                                : <></>}
                        </div>
                        <div className={styles.topButton}>
                            <a className={styles.topButtonAnchor} href={chain.validatorUrl} target='_blank' rel="noreferrer">Stake With Us!</a>
                        </div>
                    </div>
                    <SummaryHandler chain={chain}/>
                </div>
            </div>
        </>
    )
}

export default Summary