import React, { useEffect, useState } from 'react'
import "prismjs/plugins/toolbar/prism-toolbar.min.css";
import "prismjs/plugins/toolbar/prism-toolbar.min";
import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.min";
import SyntaxHighlighter from 'react-syntax-highlighter';
import Prism from "prismjs";
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';

function PublicApi({ chain }) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const styles = {
        wrapper: '',
        container: 'flex flex-col gap-[2rem]',
        title: 'mb-[0.5rem] text-[1.25rem] font-[600]',
        subtitle: '',
        anchor: 'text-[darkBlue] hover:text-[blue]',
        details: 'mb-[0.4rem]'
    }
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className="">
                    <h2 className={styles.title}>RPC Endpoint</h2>
                    <a className={styles.anchor} href={`${chain.rpcServer}`} target='_blank' rel='noreferrer'>{chain.rpcServer}</a>
                </div>
                <div className="">
                    <h2 className={styles.title}>LCD (Rest) API Endpoint</h2>
                    <p className={styles.details}>With Swagger enabled</p>
                    <a className={styles.anchor} href={`${chain.rpcServer + ':1317'}`} target='_blank' rel='noreferrer'>{chain.rpcServer + ':1317'}</a>
                </div>
            </div>
        </div>
    )
}

export default PublicApi