import React, { useEffect, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import Prism from "prismjs";
import "prismjs/plugins/toolbar/prism-toolbar.min.css";
import "prismjs/plugins/toolbar/prism-toolbar.min";
import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.min";

function CheatSheet({ chain }) {

    useEffect(() => {
        Prism.highlightAll();
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const styles = {
        wrapper: '',
        container: '',
        title: 'text-[1.8rem] font-[600]',
        details: 'text-[1rem] text-[#353535] w-[100px]',
        detailsNoWidth: 'text-[1rem] text-[#353535]',
        detailsExtraWidth: 'text-[1rem] text-[#353535] w-[300px]',
        detailsWidth: 'text-[1rem] text-[#353535] w-[200px]',
        section: 'mb-[2rem] relative',
        copyButton: '',
        monikerContainer: '',
        label: 'pt-[15px] pb-[10px] text-[1rem]',
        content: 'flex gap-[1rem] place-items-center',
        contentData: 'text-[1rem] font-[500]',
        contentContainer: 'bg-[#fff] flex flex-col gap-[1.5rem] rounded-md p-4 my-4 border-[1px] border-borderColor shadow-md',
        input: 'rounded-full text-[#353535] outline-none px-[1rem] py-[0.4rem] w-[300px] bg-[#fff] border-[1px] border-borderColor hover:border-[#000] focus:border-[#000] duration-300 text-[1rem]',
        inputSearch: 'rounded-full text-[#353535] outline-none px-[2rem] py-[0.8rem] w-full bg-[#fff] border-[1px] border-borderColor hover:border-[#000] focus:border-[#000] duration-300 text-[1rem] mt-[0.5rem] pl-[3rem]',
        searchIcon: 'absolute text-[1.5rem] top-[1.45rem] left-[1rem] cursor-pointer',
        utilityButtonList: 'utilityButtonList grid grid-cols-9 w-full justify-center place-items-center',
        utilityButton: 'border-[1px] border-borderColor px-[1rem] py-[0.5rem] w-[inherit] text-[1rem] bg-[#fff]',
        active: 'bg-[#151515] text-[#fff] border-[#252525]',
    }

    const [keyNameValue, setKeyNameValue] = useState('wallet')
    const [gasPriceValue, setGasPriceValue] = useState(chain.gas || 0.1)
    const [searchValue, setSearchValue] = useState('')


    const [monikerValue, setMonikerValue] = useState('Moniker');
    const [identityValue, setIdentityValue] = useState('DE08041434EBB322');
    const [detailsValue, setDetailsValue] = useState('The Best Validator in Town');
    const [commissionRate, setCommissionRate] = useState('0.10');
    const [commissionMaxRate, setCommissionMaxRate] = useState('0.20');
    const [commissionMaxChangeRate, setCommissionMaxChangeRate] = useState('0.01');


    const [valoperAddress, setValoperAddress] = useState('')
    const [walletAddress, setWalletAddress] = useState('')
    const [tokenAmount, setTokenAmount] = useState('1000000')

    const [proposalId, setProposalId] = useState('1')

    const [txId, setTxId] = useState('')

    const [portIncrement, setPortIncrement] = useState(0)

    const [indexer, setIndexer] = useState(0)

    const [pruning, setPruning] = useState('custom')
    const [pruningRecent, setPruningRecent] = useState('100')
    const [pruningEvery, setPruningEvery] = useState('0')
    const [pruningInterval, setPruningInterval] = useState('10')

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.section}>
                        <h2 className={styles.title}>Parameters</h2>
                        <div className={styles.contentContainer}>
                            <div className={styles.content}>
                                <p className={styles.details}>Binary:</p>
                                <p className={styles.contentData}>{chain.binaryName || chain.serviceName}</p>
                            </div>
                            <div className={styles.content}>
                                <p className={styles.details}>Chain ID:</p>
                                <p className={styles.contentData}>{chain.chainId}</p>
                            </div>
                            <div className={styles.content}>
                                <p className={styles.details}>Denom:</p>
                                <p className={styles.contentData}>{chain.denomName}</p>
                            </div>
                            <div className={styles.content}>
                                <p className={styles.details}>Key name:</p>
                                <input className={styles.input} type="text" placeholder='wallet' value={keyNameValue} onChange={(e) => { setKeyNameValue(e.target.value) }} />
                            </div>
                            <div className={styles.content}>
                                <p className={styles.details}>Gas prices:</p>
                                <input className={styles.input} type="text" placeholder='0.1' value={gasPriceValue} onChange={(e) => { setGasPriceValue(e.target.value) }} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.section + ' hidden'}>
                        <h2 className={styles.title}>Parameters</h2>
                        <div className={styles.section}>
                            <input className={styles.inputSearch} type="text" placeholder='unjail validator' value={searchValue} onChange={(e) => { setSearchValue(e.target.value) }} />
                            <AiOutlineSearch className={styles.searchIcon} />
                        </div>
                    </div>
                    <div id="keys">
                        <h2 className={styles.title + ' mb-[1rem]'}>Keys</h2>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Add New Key</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} keys add ${keyNameValue || '<YOUR_KEY_NAME>'}`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Recover Existing Key</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} keys add ${keyNameValue || '<YOUR_KEY_NAME>'} --recover`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>List All Keys</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} keys list`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Delete Key</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} keys delete ${keyNameValue || '<YOUR_KEY_NAME>'}`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Export Key (save to {keyNameValue || '<YOUR_KEY_NAME>'}.backup)</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} keys export ${keyNameValue || '<YOUR_KEY_NAME>'}`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Import Key</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} keys import ${keyNameValue || '<YOUR_KEY_NAME>'} ${keyNameValue || '<YOUR_KEY_NAME>'}.backup`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Query Wallet Balance</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} q bank balances $(${chain.binaryName || chain.serviceName} keys show ${keyNameValue || '<YOUR_KEY_NAME>'} -a)`}
                                </code>
                            </pre>
                        </div>
                    </div>
                    <div id="validator">
                        <h2 className={styles.title + ' mb-[1rem]'}>Validator</h2>
                        <div className={styles.section}>
                            <div className={styles.contentContainer}>
                                <div className={styles.content}>
                                    <p className={styles.detailsExtraWidth}>Moniker:</p>
                                    <input className={styles.input} type="text" placeholder='ex. NKB Blocks' value={monikerValue} onChange={(e) => { setMonikerValue(e.target.value) }} />
                                </div>
                                <div className={styles.content}>
                                    <p className={styles.detailsExtraWidth}>Identity:</p>
                                    <input className={styles.input} type="text" placeholder='FFB0AA51A2DF5954' value={identityValue} onChange={(e) => { setIdentityValue(e.target.value) }} />
                                </div>
                                <div className={styles.content}>
                                    <p className={styles.detailsExtraWidth}>Details:</p>
                                    <input className={styles.input} type="text" placeholder="I'm sexy and I know it &#x1F609;" value={detailsValue} onChange={(e) => { setDetailsValue(e.target.value) }} />
                                </div>
                                <div className={styles.content}>
                                    <p className={styles.detailsExtraWidth}>Commission rate:</p>
                                    <input className={styles.input} type="text" placeholder="0.10" value={commissionRate} onChange={(e) => { setCommissionRate(e.target.value) }} />
                                </div>
                                <div className={styles.content}>
                                    <p className={styles.detailsExtraWidth}>Commission max rate:</p>
                                    <input className={styles.input} type="text" placeholder="0.20" value={commissionMaxRate} onChange={(e) => { setCommissionMaxRate(e.target.value) }} />
                                </div>
                                <div className={styles.content}>
                                    <p className={styles.detailsExtraWidth}>Commission max change rate:</p>
                                    <input className={styles.input} type="text" placeholder="0.01" value={commissionMaxChangeRate} onChange={(e) => { setCommissionMaxChangeRate(e.target.value) }} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Create New Validator</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} tx staking create-validator \\
--amount=1000000${chain.denomName} \\
--pubkey=$(${chain.binaryName || chain.serviceName} tendermint show-validator) \\
--moniker="${monikerValue || '<YOUR_MONIKER>'}" \\
--identity=${identityValue || '<YOUR_IDENTITY>'} \\
--details="${detailsValue || '<YOUR_DETAILS>'}" \\
--chain-id=${chain.chainId} \\
--commission-rate=${commissionRate || '<COMMISION_RATE>'} \\
--commission-max-rate=${commissionMaxRate || '<COMMISION_MAX_RATE>'} \\
--commission-max-change-rate=${commissionMaxChangeRate || '<COMMISION_MAX_CHANGE_RATE>'} \\
--min-self-delegation=1 \\
--from=${keyNameValue || '<YOUR_KEY_NAME>'} \\
--gas-prices=${(gasPriceValue || '<GAS>') + chain.denomName} \\
--gas-adjustment=1.2 \\
--gas=auto \\
-y`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Edit Existing Validator</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} tx staking edit-validator \\
--moniker="${monikerValue || '<YOUR_MONIKER>'}" \\
--identity=${identityValue || '<YOUR_IDENTITY>'} \\
--details="${detailsValue || '<YOUR_DETAILS>'}" \\
--chain-id=${chain.chainId} \\
--commission-rate=0.1 \\
--from=${keyNameValue || '<YOUR_KEY_NAME>'} \\
--gas-prices=${(gasPriceValue || '<GAS>') + chain.denomName} \\
--gas-adjustment=1.2 \\
--gas=auto \\
-y`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Unjail Validator</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} tx slashing unjail --from ${keyNameValue || '<YOUR_KEY_NAME>'} --chain-id ${chain.chainId} --gas-prices ${(gasPriceValue || '<GAS>') + chain.denomName} --gas-adjustment 1.2 --gas auto -y`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Jail Reason</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} query slashing signing-info $(${chain.binaryName || chain.serviceName} tendermint show-validator)`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>List All Active Validators</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} q staking validators -oj --limit=3000 | jq '.validators[] | select(.status=="BOND_STATUS_BONDED")' | jq -r '(.tokens|tonumber/pow(10; 6)|floor|tostring) + " \\t " + .description.moniker' | sort -gr | nl`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>List All Inactive Validators</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} q staking validators -oj --limit=3000 | jq '.validators[] | select(.status=="BOND_STATUS_UNBONDED")' | jq -r '(.tokens|tonumber/pow(10; 6)|floor|tostring) + " \\t " + .description.moniker' | sort -gr | nl`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>View Validator Details</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} q staking validator $(${chain.binaryName || chain.serviceName} keys show ${keyNameValue || '<YOUR_KEY_NAME>'} --bech val -a)`}
                                </code>
                            </pre>
                        </div>
                    </div>
                    <div id="tokens">
                        <h2 className={styles.title + ' mb-[1rem]'}>Tokens</h2>
                        <div className={styles.section}>
                            <div className={styles.contentContainer}>
                                <div className={styles.content}>
                                    <p className={styles.detailsWidth}>To valoper address:</p>
                                    <input className={styles.input} type="text" placeholder='validator operator address' value={valoperAddress} onChange={(e) => { setValoperAddress(e.target.value) }} />
                                </div>
                                <div className={styles.content}>
                                    <p className={styles.detailsWidth}>To wallet address:</p>
                                    <input className={styles.input} type="text" placeholder='wallet address' value={walletAddress} onChange={(e) => { setWalletAddress(e.target.value) }} />
                                </div>
                                <div className={styles.content}>
                                    <p className={styles.detailsWidth}>Amount, ({chain.denomName}):</p>
                                    <input className={styles.input} type="text" placeholder='amount' value={tokenAmount} onChange={(e) => { setTokenAmount(e.target.value) }} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Withdraw Rewards From All Validators</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} tx distribution withdraw-all-rewards --from ${keyNameValue || '<YOUR_KEY_NAME>'} --chain-id ${chain.chainId} --gas-prices ${(gasPriceValue || '<GAS>') + chain.denomName} --gas-adjustment 1.2 --gas auto -y`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Withdraw Commission And Rewards From Your Validator</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} tx distribution withdraw-rewards $(${chain.binaryName || chain.serviceName} keys show ${keyNameValue || '<YOUR_KEY_NAME>'} --bech val -a) --commission --from ${keyNameValue || '<YOUR_KEY_NAME>'} --chain-id ${chain.chainId} --gas-prices ${(gasPriceValue || '<GAS>') + chain.denomName} --gas-adjustment 1.2 --gas auto -y`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Delegate to yourself</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} tx staking delegate $(${chain.binaryName || chain.serviceName} keys show ${keyNameValue || '<YOUR_KEY_NAME>'} --bech val -a) ${tokenAmount || '<YOUR_AMOUNT>'}${chain.denomName} --from ${keyNameValue || '<YOUR_KEY_NAME>'} --chain-id ${chain.chainId} --gas-prices ${(gasPriceValue || '<GAS>') + chain.denomName} --gas-adjustment 1.2 --gas auto -y`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Delegate</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} tx staking delegate ${valoperAddress || '<TO_VALOPER_ADDRESS>'} ${tokenAmount || '<YOUR_AMOUNT>'}${chain.denomName} --from ${keyNameValue || '<YOUR_KEY_NAME>'} --chain-id ${chain.chainId} --gas-prices ${(gasPriceValue || '<GAS>') + chain.denomName} --gas-adjustment 1.2 --gas auto -y`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Redelegate</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} tx staking redelegate $(${chain.binaryName || chain.serviceName} keys show ${keyNameValue || '<YOUR_KEY_NAME>'} --bech val -a) ${valoperAddress || '<TO_VALOPER_ADDRESS>'} ${tokenAmount || '<YOUR_AMOUNT>'}${chain.denomName} --from ${keyNameValue || '<YOUR_KEY_NAME>'} --chain-id ${chain.chainId} --gas-prices ${(gasPriceValue || '<GAS>') + chain.denomName} --gas-adjustment 1.2 --gas auto -y`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Unbond</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} tx staking unbond $(${chain.binaryName || chain.serviceName} keys show ${keyNameValue || '<YOUR_KEY_NAME>'} --bech val -a) ${tokenAmount || '<YOUR_AMOUNT>'}${chain.denomName} --from ${keyNameValue || '<YOUR_KEY_NAME>'} --chain-id ${chain.chainId} --gas-prices ${(gasPriceValue || '<GAS>') + chain.denomName} --gas-adjustment 1.2 --gas auto -y`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Send</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} tx bank send ${keyNameValue || '<YOUR_KEY_NAME>'} ${walletAddress || '<TO_WALLET_ADDRESS>'}} ${tokenAmount || '<YOUR_AMOUNT>'}${chain.denomName} --from ${keyNameValue || '<YOUR_KEY_NAME>'}--chain-id ${chain.chainId} --gas-prices ${(gasPriceValue || '<GAS>') + chain.denomName} --gas-adjustment 1.2 --gas auto -y`}
                                </code>
                            </pre>
                        </div>
                    </div>
                    <div id="governance">
                        <h2 className={styles.title + ' mb-[1rem]'}>Governance</h2>
                        <div className={styles.section}>
                            <div className={styles.contentContainer}>
                                <div className={styles.content}>
                                    <p className={styles.detailsNoWidth}>Proposal id:</p>
                                    <input className={styles.input} type="text" placeholder='proposal id' value={proposalId} onChange={(e) => { setProposalId(e.target.value) }} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>List All Proposals:</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} query gov proposals`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>View Proposal By ID:</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} query gov proposal ${proposalId || '<PROPOSAL_ID>'}`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Vote YES:</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} tx gov vote ${proposalId || '<PROPOSAL_ID>'} yes --from ${keyNameValue || '<YOUR_KEY_NAME>'} --chain-id ${chain.chainId} --gas-prices ${(gasPriceValue || '<GAS>') + chain.denomName} --gas-adjustment 1.2 --gas auto -y`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Vote No:</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} tx gov vote ${proposalId || '<PROPOSAL_ID>'} no --from ${keyNameValue || '<YOUR_KEY_NAME>'} --chain-id ${chain.chainId} --gas-prices ${(gasPriceValue || '<GAS>') + chain.denomName} --gas-adjustment 1.2 --gas auto -y`}
                                </code>
                            </pre>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Vote ABSTAIN:</p>
                            <pre className="language-bash customPre">
                                <code className="language-bash">
                                    {`${chain.binaryName || chain.serviceName} tx gov vote ${proposalId || '<PROPOSAL_ID>'} abstain --from ${keyNameValue || '<YOUR_KEY_NAME>'} --chain-id ${chain.chainId} --gas-prices ${(gasPriceValue || '<GAS>') + chain.denomName} --gas-adjustment 1.2 --gas auto -y`}
                                </code>
                            </pre>
                        </div>
                    </div>
                    <div id="query">
                        <h2 className={styles.title + ' mb-[1rem]'}>Query</h2>
                        <div className={styles.section}>
                            <div className={styles.contentContainer}>
                                <div className={styles.content}>
                                    <p className={styles.detailsWidth}>Query Transaction</p>
                                </div>
                                <div className={styles.content}>
                                    <p className={styles.detailsNoWidth}>Transaction id:</p>
                                    <input className={styles.input} type="text" placeholder='transaction id' value={txId} onChange={(e) => { setTxId(e.target.value) }} />
                                </div>
                                <div className="">
                                    <pre className="language-bash customPre">
                                        <code className="language-bash">
                                            {`${chain.binaryName || chain.serviceName} query tx ${txId || '<YOUR_TX_ID>'}`}
                                        </code>
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="utility">
                        <h2 className={styles.title + ' mb-[1rem]'}>Utility</h2>
                        <div className={styles.section}>
                            <div className={styles.content}>
                                <p className={styles.detailsWidth}>Change Default Ports</p>
                            </div>
                        </div>
                        <div className={styles.section + ' flex justify-center place-items-center'}>
                            <div className={styles.utilityButtonList}>
                                {
                                    [0, 1, 2, 3, 4, 5, 6, 7, 8].map((value, index) => {
                                        return (
                                            <button
                                                className={value === portIncrement ? styles.active + ' ' + styles.utilityButton : '' + styles.utilityButton}
                                                key={index}
                                                onClick={() => { setPortIncrement(value) }}
                                            >
                                                {value === 0 ? 'Default' : value}
                                            </button>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={styles.contentContainer}>
                            {
                                [9090, 9091, 26658, 26657, 26656, 6060, 26660, 1317].map((value, index) => {
                                    return (
                                        <div className={styles.content} key={index}>
                                            <p className={styles.detailsWidth}>
                                                {
                                                    value === 9090 ? 'gRPC' : '' ||
                                                        value === 9091 ? 'gRPC(web)' : '' ||
                                                            value === 26658 ? 'proxy_app' : '' ||
                                                                value === 26657 ? 'laddr(rpc)' : '' ||
                                                                    value === 26656 ? 'pprof_laddr' : '' ||
                                                                        value === 6060 ? 'laddr(p2p)' : '' ||
                                                                            value === 26660 ? 'prometheus' : '' ||
                                                                                value === 1317 ? 'api' : ''
                                                }:
                                            </p>
                                            <p className={styles.contentData}>
                                                {
                                                    value === 9090 ||
                                                        value === 9091 ||
                                                        value === 6060 ||
                                                        value === 1317
                                                        ? value + portIncrement * 100
                                                        : value + portIncrement * 1000
                                                }
                                            </p>
                                        </div>
                                    )
                                })
                            }
                            <div className="">
                                <pre className="language-bash customPre">
                                    <code className="language-bash">
                                        {`sed -i.bak -e "s%^proxy_app = \\"tcp://127.0.0.1:26658\\"%proxy_app = \\"tcp://127.0.0.1:${26658 + portIncrement * 1000}\\"%; s%^laddr = \\"tcp://127.0.0.1:26657\\"%laddr = \\"tcp://127.0.0.1:${26657 + portIncrement * 1000}\\"%; s%^pprof_laddr = \\"localhost:6060\\"%pprof_laddr = \\"localhost:${6060 + portIncrement * 100}\\"%; s%^laddr = \\"tcp://0.0.0.0:26656\\"%laddr = \\"tcp://0.0.0.0:${26656 + portIncrement * 1000}\\"%; s%^prometheus_listen_addr = \\":26660\\"%prometheus_listen_addr = \\":${26660 + portIncrement * 1000}\\"%" $HOME/${chain.homeDirectoryName}/config/config.toml && sed -i.bak -e "s%^address = \\"0.0.0.0:9090\\"%address = \\"0.0.0.0:${9090 + portIncrement * 100}\\"%; s%^address = \\"0.0.0.0:9091\\"%address = \\"0.0.0.0:${9091 + portIncrement * 100}\\"%; s%^address = \\"tcp://0.0.0.0:1317\\"%address = \\"tcp://0.0.0.0:${1317 + portIncrement * 100}\\"%" $HOME/${chain.homeDirectoryName}/config/app.toml && sed -i.bak -e "s%^node = \\"tcp://localhost:26657\\"%node = \\"tcp://localhost:${26657 + portIncrement * 1000}\\"%" $HOME/${chain.homeDirectoryName}/config/client.toml`}
                                    </code>
                                </pre>
                            </div>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Update Indexer:</p>
                            <div className={styles.utilityButtonList + ' pt-4'}>
                                {
                                    [0, 1,].map((value, index) => {
                                        return (
                                            <button
                                                className={value === indexer ? styles.active + ' ' + styles.utilityButton : '' + styles.utilityButton}
                                                key={index}
                                                onClick={() => { setIndexer(value) }}
                                            >
                                                {value === 0 ? 'Default' : 'Null'}
                                            </button>
                                        )
                                    })
                                }
                            </div>
                            <div className="">
                                <pre className="language-bash customPre">
                                    <code className="language-bash">
                                        {`sed -i 's|^indexer *=.*|indexer = "${indexer === 0 ? 'kv' : 'null'}"|' $HOME/${chain.homeDirectoryName}/config/config.toml`}
                                    </code>
                                </pre>
                            </div>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.detailsNoWidth}>Update Pruning:</p>
                            <div className={styles.contentContainer}>
                                <div className={styles.content}>
                                    <p className={styles.detailsWidth}>Pruning:</p>
                                    <input className={styles.input} type="text" placeholder='custom' value={pruning} onChange={(e) => { setPruning(e.target.value) }} />
                                </div>
                                <div className={styles.content}>
                                    <p className={styles.detailsWidth}>Pruning keep recent:</p>
                                    <input className={styles.input} type="text" placeholder='custom' value={pruningRecent} onChange={(e) => { setPruningRecent(e.target.value) }} />
                                </div>
                                <div className={styles.content}>
                                    <p className={styles.detailsWidth}>Pruning keep every:</p>
                                    <input className={styles.input} type="text" placeholder='custom' value={pruningEvery} onChange={(e) => { setPruningEvery(e.target.value) }} />
                                </div>
                                <div className={styles.content}>
                                    <p className={styles.detailsWidth}>Pruning interval:</p>
                                    <input className={styles.input} type="text" placeholder='custom' value={pruningInterval} onChange={(e) => { setPruningInterval(e.target.value) }} />
                                </div>
                                <div className="">
                                    <pre className="language-bash customPre">
                                        <code className="language-bash">
                                            {`sed -i.bak -e 's|^pruning *=.*|pruning = "${pruning}"|; s|^pruning-keep-recent *=.*|pruning-keep-recent = "${pruningRecent}"|; s|^pruning-keep-every *=.*|pruning-keep-every = "${pruningEvery}"|; s|^pruning-interval *=.*|pruning-interval = "${pruningInterval}"|' $HOME/${chain.homeDirectoryName}/config/app.toml`}
                                        </code>
                                    </pre>
                                </div>
                            </div>
                            <div className={styles.section}>
                                <p className={styles.detailsNoWidth}>Get Validator Info:</p>
                                <pre className="language-bash customPre">
                                    <code className="language-bash">
                                        {`${chain.binaryName || chain.serviceName} status 2>&1 | jq .ValidatorInfo`}
                                    </code>
                                </pre>
                            </div>
                            <div className={styles.section}>
                                <p className={styles.detailsNoWidth}>Get Catching Up:</p>
                                <pre className="language-bash customPre">
                                    <code className="language-bash">
                                        {`${chain.binaryName || chain.serviceName} status 2>&1 | jq .SyncInfo.catching_up`}
                                    </code>
                                </pre>
                            </div>
                            <div className={styles.section}>
                                <p className={styles.detailsNoWidth}>Get Latest Height:</p>
                                <pre className="language-bash customPre">
                                    <code className="language-bash">
                                        {`${chain.binaryName || chain.serviceName} status 2>&1 | jq .SyncInfo.latest_block_height`}
                                    </code>
                                </pre>
                            </div>
                            <div className={styles.section}>
                                <p className={styles.detailsNoWidth}>Get Peer:</p>
                                <pre className="language-bash customPre">
                                    <code className="language-bash">
                                        {`${chain.binaryName || chain.serviceName} tendermint show-node-id)'@'$(curl -s ifconfig.me)':'$(cat $HOME/${chain.homeDirectoryName}/config/config.toml | sed -n '/Address to listen for incoming connection/&#123;n;p;&#125;' | sed 's/.*://; s/".*//')`}
                                    </code>
                                </pre>
                            </div>
                            <div className={styles.section}>
                                <p className={styles.detailsNoWidth}>Remove Node:</p>
                                <pre className="language-bash customPre">
                                    <code className="language-bash">
                                        {`sudo systemctl stop ${chain.serviceName} && sudo systemctl disable ${chain.serviceName} && sudo rm /etc/systemd/system/${chain.serviceName}.service && sudo systemctl daemon-reload && rm -rf $HOME/${chain.homeDirectoryName} ${chain.githubRepoName ? ('&& rm -rf $HOME/' + chain.githubRepoName) : ''}} && rm $(which ${chain.binaryName || chain.serviceName})`}
                                    </code>
                                </pre>
                            </div>
                        </div>
                    </div>
                    <div id="service-management">
                        <div id="query">
                            <h2 className={styles.title + ' mb-[1rem]'}>Service Management</h2>
                            <div className={styles.section}>
                                <p className={styles.detailsNoWidth}>Reload Services</p>
                                <pre className="language-bash customPre">
                                    <code className="language-bash">
                                        {`sudo systemctl daemon-reload`}
                                    </code>
                                </pre>
                            </div>
                            <div className={styles.section}>
                                <p className={styles.detailsNoWidth}>Enable Service</p>
                                <pre className="language-bash customPre">
                                    <code className="language-bash">
                                        {`sudo systemctl enable ${chain.binaryName || chain.serviceName}`}
                                    </code>
                                </pre>
                            </div>
                            <div className={styles.section}>
                                <p className={styles.detailsNoWidth}>Disable Service</p>
                                <pre className="language-bash customPre">
                                    <code className="language-bash">
                                        {`sudo systemctl disable ${chain.binaryName || chain.serviceName}`}
                                    </code>
                                </pre>
                            </div>
                            <div className={styles.section}>
                                <p className={styles.detailsNoWidth}>Run Service</p>
                                <pre className="language-bash customPre">
                                    <code className="language-bash">
                                        {`sudo systemctl start ${chain.binaryName || chain.serviceName}`}
                                    </code>
                                </pre>
                            </div>
                            <div className={styles.section}>
                                <p className={styles.detailsNoWidth}>Stop Service</p>
                                <pre className="language-bash customPre">
                                    <code className="language-bash">
                                        {`sudo systemctl stop ${chain.binaryName || chain.serviceName}`}
                                    </code>
                                </pre>
                            </div>
                            <div className={styles.section}>
                                <p className={styles.detailsNoWidth}>Restart Service</p>
                                <pre className="language-bash customPre">
                                    <code className="language-bash">
                                        {`sudo systemctl restart ${chain.binaryName || chain.serviceName}`}
                                    </code>
                                </pre>
                            </div>
                            <div className={styles.section}>
                                <p className={styles.detailsNoWidth}>Check Service Status</p>
                                <pre className="language-bash customPre">
                                    <code className="language-bash">
                                        {`sudo systemctl status ${chain.binaryName || chain.serviceName}`}
                                    </code>
                                </pre>
                            </div>
                            <div className={styles.section}>
                                <p className={styles.detailsNoWidth}>Check Service Logs</p>
                                <pre className="language-bash customPre">
                                    <code className="language-bash">
                                        {`sudo journalctl -u ${chain.binaryName || chain.serviceName} -f --no-hostname -o cat`}
                                    </code>
                                </pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CheatSheet