import Pages from "./pages/Pages";
import "react-tooltip/dist/react-tooltip.css";
import "react-circular-progressbar/dist/styles.css";
import './global.css';

function App() {
  return (
    <>
      <Pages />
    </>
  );
}

export default App;
