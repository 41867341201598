import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Prism from "prismjs";
import "prismjs/plugins/toolbar/prism-toolbar.min.css";
import "prismjs/plugins/toolbar/prism-toolbar.min";
import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.min";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';

function Upgrade({ chain }) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const [upgradeDataArray, setUpgradeDataArray] = useState(null);

    const fetchManualUpgradeScriptContent = (upgradeScript) => {
        const salt = (new Date()).getTime();
        const chainName = chain?.chainName.toLowerCase();
        const url = `https://raw.githubusercontent.com/nodejumper-org/cosmos-scripts/master/${chainName}/upgrade/${upgradeScript.version}/upgrade_manual.sh?${salt}`;
        axios.get(url).then((res) => {
            if (res.status === 200) {
                const data = res.data;
                upgradeScript.manualScriptContent = data.trim();
                upgradeScript.manualScriptContent += `\nsudo journalctl -u ${chain?.serviceName} -f --no-hostname -o cat`;
            }
        }).catch((err) => {});
    }

    useEffect(() => {
        const salt = (new Date()).getTime();
        const chainName = chain.chainName.toLowerCase();
        const url = `https://raw.githubusercontent.com/nodejumper-org/cosmos-scripts/master/${chainName}/upgrade/upgrades.json?${salt}`;
        axios.get(url).then((res) => {
            if (res.status === 200) {
                const data = res.data;
                data.sort((a, b) => { return b.height - a.height });
                data.forEach((upgradeScript) => {
                    fetchManualUpgradeScriptContent(upgradeScript);
                })
                setUpgradeDataArray(data)
            }
        }).catch((err) => {});
    });


    const styles = {
        wrapper: '',
        container: '',
        title: 'text-[1.8rem] font-[600]',
        details: 'text-[1rem] text-[#353535]',
        section: 'mb-[2rem]',
        copyButton: '',
        monikerContainer: '',
        label: 'pt-[15px] pb-[10px] text-[1rem]',
        monikerInput: 'rounded-full outline-none px-[1rem] py-[0.6rem] w-[300px] bg-[#fff] border-[1px] border-borderColor hover:border-[#000] focus:border-[#000] duration-300 text-[1rem] pb-[1rem]'
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                {
                    upgradeDataArray ? (
                        <>
                            {
                                upgradeDataArray.map((upgradeData, index) => {
                                    return (
                                        <div className="" key={index}>
                                            <div className={styles.section}>
                                                <h2 className={styles.title}>Upgrade to {upgradeData?.version}</h2>
                                                <p className={styles.details}>height: <strong>{upgradeData?.height}</strong></p>
                                            </div>
                                            <div className={styles.section}>
                                                <h2 className={styles.title}>Manual Upgrade</h2>
                                                <SyntaxHighlighter language="bash" style={okaidia}>
                                                    {upgradeData.manualScriptContent}
                                                </SyntaxHighlighter>
                                            </div>
                                            {
                                                !upgradeData.automaticUpgradeDisabled ? (
                                                    <div className={styles.section}>
                                                        <h2 className={styles.title}>Automatic Upgrade</h2>
                                                        <p className={styles.details}>upgrade will be done automatically, when halt height is reached</p>
                                                        <SyntaxHighlighter language="bash" style={okaidia}>
                                                            {`bash <(curl -s https://raw.githubusercontent.com/nodejumper-org/cosmos-scripts/master/${chain.chainName.toLowerCase()}/upgrade/${upgradeData.version}/upgrade_automatic.sh)`}
                                                        </SyntaxHighlighter>
                                                    </div>
                                                ) : <></>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </>
                    ) : (
                        <div className={styles.section}>
                            <h2 className={styles.title}>No chain upgrades scheduled yet.</h2>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default Upgrade