import React from 'react'
import {RiInstallLine} from 'react-icons/ri'
import {MdDescription} from 'react-icons/md'
import {BiBookContent} from 'react-icons/bi'
import {BsTools} from 'react-icons/bs'
import {IoMdAnalytics} from 'react-icons/io'


export default function AboutUs() {

    const styles = {
        wrapper: 'p-[1rem] py-[2rem] sm:p-[2rem] bg-[#fbfbfd]',
        container: 'text-center flex justify-center place-items-center flex-col',
        titleContainer: '',
        title: 'text-[2rem] font-[500] text-[#333]',
        description: 'text-[1rem] text-[#353535] py-[2rem] md:px-[4rem]',
        contentContainer: 'px-[2rem] md:px-[2rem] gap-[1rem] grid lg:grid-cols-3 place-items-center',
        contentContainer2: 'mt-[1rem] px-[2rem] md:px-[2rem] gap-[1rem] grid lg:grid-cols-2 place-items-center',
        list: 'aboutList flex duration-300 text-[#858585] hover:text-[#fff] hover:bg-[#3956de] cursor-grab hover:shadow-xl flex-col justify-start px-4 py-4 md:py-8 border-[1px] border-borderColor2 rounded-xl place-items-center gap-[1rem] text-[1rem] bg-[#fff] max-w-[320px] min-w-[320px] min-h-[320px] max-h-[320px]',
        listIcon: 'text-[#3956de] text-[3rem] aboutIcon mt-[2rem]',
        listText: '',
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.titleContainer}>
                    <h5 className={styles.title}>About Us</h5>
                </div>
                <p className={styles.description}>
                    NKB Blocks web app is a multi tool for cosmos based networks. It provides a set of helpful support tools for whole validator and delegator community.We support more than 20 chains and growing
                </p>
                <p className={styles.description}>For each of our supported networks we provide professional validation services and advanced on-chain analytics tools such as:</p>
                <div className={styles.contentContainer}>
                    {
                        [
                            {
                                desc: "Node Installation scripts with manual & automatic options.",
                                icon: <RiInstallLine className={styles.listIcon}/>,
                            },
                            {
                                desc: "Node synchronization scripts.",
                                icon: <MdDescription className={styles.listIcon}/>,
                            },
                            {
                                desc: "Cheat sheet for most popular blockchain commands (validator profile, wallet, token management and etc.).",
                                icon: <BiBookContent className={styles.listIcon}/>,
                            },
                        ].map((item, index) => {
                            return (
                                <div className={styles.list} key={index}>
                                    <div className="">
                                        {item.icon}
                                    </div>
                                    <p className={styles.listText}>{item.desc}</p>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={styles.contentContainer2}>
                    {
                        [
                            {
                                desc: "Blockchain ecosystem/tools overview with user-friendly interface (both web & mobile).",
                                icon: <BsTools className={styles.listIcon}/>,
                            },
                            {
                                desc: "Blockchain analytics for community: tokenomics, price/trading stats, comission & voting power distribution. Soon we ll launch our dedicated RPC servers along with snapshots for every network we participate.",
                                icon: <IoMdAnalytics className={styles.listIcon}/>,
                            },
                        ].map((item, index) => {
                            return (
                                <div className={styles.list} key={index}>
                                    <div className="">
                                        {item.icon}
                                    </div>
                                    <p className={styles.listText}>{item.desc}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
