import React, { useEffect, useState } from 'react'
import { AiFillCheckCircle, AiFillExclamationCircle } from 'react-icons/ai'
import { MdArrowDropUp, MdArrowDropDown } from 'react-icons/md'

export default function LeaderBoard({ validatorsData }) {

    const [totalData, setTotalData] = useState('')
    const [rankFilter, setRankFilter] = useState(10)
    const [filterData, setFilterData] = useState('')

    useEffect(() => {
        if (!validatorsData.validators) {
            return;
        }
        validatorsData?.validators.sort((a, b) => b.delegations.total_tokens_display - a.delegations.total_tokens_display)
        setTotalData(validatorsData.validators)
        setFilterData(validatorsData.validators.filter((e) => e.rank <= rankFilter))
    }, [validatorsData])

    const handleClick = () => {
        const rank = rankFilter + 10;
        setFilterData(validatorsData.validators.filter((e) => e.rank <= rank))
        setRankFilter(rank)
    }

    const styles = {
        wrapper: 'my-[2rem] bg-[#fff] sm:p-4 rounded-md border-[1px] border-borderColor',
        container: 'w-[100%] h-[100%] flex flex-col justify-center place-items-start sm:place-items-center relative',
        title: 'text-center text-[1.5rem] font-[500] text-[#353535] pb-4 pl-[1rem] sm:pl-0 ',
        leaderboardContainer: 'lg:w-[80%] flex flex-col justify-center place-items-center',
        header: 'w-full font-[700]',
        contentContainer: 'w-full',
        content: 'grid grid-cols-3 sm:grid-cols-4 text-center py-4 border-b-[1px] border-b-borderColor',
        contentNormal: 'text-[1rem]',
        contentRank: 'text-[1rem] hidden sm:block',
        contentLink: 'text-[1rem] font-[500] font-[italic]',
        contentIcon: 'text-center flex justify-center place-items-center',
        button: 'primaryButton duration-300 my-[1rem] bg-[#000] text-[#fff] hover:bg-[#252525] text-[1rem] px-[4rem] py-[0.5rem] rounded-full',
        activeIcon: 'text-[1.5rem] text-[green] text-center',
        inactiveIcon: 'text-[1.5rem] text-[red] ',
        divider: 'min-w-full border-t-[1px] border-t-borderColor',
        expandContainer: '',
        expandText: 'absolute top-[0.5  rem] flex justify-center place-items-center gap-[0.5rem] right-[1rem] text-[0.8rem] text-[#111] hover:text-[#333] cursor-pointer duration-300',
        expandIcon: 'text-[1.5rem]',
        searchInput: 'absolute top-[0.5rem] left-[1rem] rounded-full block w-[100px] hidden sm:block lg:w-[250px] h-[30px] text-[1rem] px-[1rem] border-[1px] border-borderColor focus:outline-none focus:border-[#000] hover:border-[#000]'
    }

    const [isExpanded, setIsExpanded] = useState(false)

    const expandLeaderboard = () => {
        setIsExpanded(true)
        setFilterData(totalData)
    }

    const collapseLeaderboard = () => {
        setIsExpanded(false)
        validatorsData?.validators.sort((a, b) => b.delegations.total_tokens_display - a.delegations.total_tokens_display)
        setTotalData(validatorsData.validators)
        setFilterData(validatorsData.validators.filter((e) => e.rank <= 10))
        setRankFilter(10)
    }

    const [onlyActive, setOnlyActive] = useState(false);

    const [searchValue, setSearchValue] = useState('');

    const handleSearch = (keyword) => {
        setSearchValue(keyword)
        validatorsData?.validators.sort((a, b) => b.delegations.total_tokens_display - a.delegations.total_tokens_display)
        if(keyword === "") {
            setTotalData(validatorsData.validators)
            setFilterData(validatorsData.validators.filter((e) => e.rank <= 10))
            setRankFilter(10)
            return;
        }
        const newData = validatorsData.validators.filter((e) => e?.moniker.toLowerCase().includes(keyword.toLowerCase()) || e?.delegations?.total_tokens.includes(keyword) || e?.rank === keyword)
        newData.sort((a, b) => b.rank - a.rank).reverse()
        setFilterData(newData)
    }

    return (
        <>
            {
                totalData ? (
                    <div className={styles.wrapper}>
                        <div className={styles.container}>
                            <div className={styles.searchContainer}>
                                <input type="text" placeholder='Moniker, Delegation..' value={searchValue} className={styles.searchInput} onChange={(e) => {handleSearch(e.target.value)}}/>
                            </div>
                            <div className={styles.expandContainer}>
                                {
                                    !isExpanded ? (
                                        <p onClick={() => { expandLeaderboard() }} className={styles.expandText}>Expand All <MdArrowDropDown className={styles.expandIcon} /></p>
                                    ) : (
                                        <p onClick={() => { collapseLeaderboard() }} className={styles.expandText}>Collapse All <MdArrowDropUp className={styles.expandIcon} /></p>
                                    )
                                }
                            </div>
                            <h2 className={styles.title}>Leaderboard</h2>
                            <div className={styles.leaderboardContainer}>
                                <div className={styles.header}>
                                    <div className={styles.content}>
                                        <p className={styles.contentRank}>Rank</p>
                                        <p className={styles.contentNormal}>Moniker</p>
                                        <p className={styles.contentNormal}>Delegation</p>
                                        <p className={styles.contentNormal}>
                                            Active
                                            <input type="checkbox" name="onlyActive" checked={onlyActive} onClick={() => {onlyActive ? setOnlyActive(false) : setOnlyActive(true)}} />
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.contentContainer}>
                                    {
                                        filterData && filterData.filter(e => onlyActive ? e.active : e).map((item, index) => {
                                            return (
                                                <div className={styles.content} key={index}>
                                                    <p className={styles.contentRank}>{item.rank}</p>
                                                    <p className={styles.contentLink}>{item?.moniker}</p>
                                                    <p className={styles.contentNormal}>{item?.delegations?.total_tokens}</p>
                                                    <p className={styles.contentIcon}>
                                                        {
                                                            item.active ? (
                                                                <>
                                                                    <AiFillCheckCircle className={styles.activeIcon} />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <AiFillExclamationCircle className={styles.inactiveIcon} />
                                                                </>
                                                            )
                                                        }
                                                    </p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    filterData[filterData.length - 1] !== totalData[totalData.length - 1] && !searchValue ? (
                                        <div className={styles.buttonContainer}>
                                            <button className={styles.button} onClick={() => { handleClick() }}>Load More</button>
                                        </div>
                                    ) : <></>
                                }
                            </div>
                        </div>
                    </div>
                ) : <></>
            }
        </>

    )
}
